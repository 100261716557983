import { Card } from 'primereact/card';
import styles from "./create-an-account.module.scss"
import Markdown from 'react-markdown';
import { SupportedLanguage } from '../../../../language/supported-languages';
import { HEADINGS_CLASS_NAMES } from '../../../../ui/headings';
import { useCurrentLanguage } from '../../../../language/current-language';
import invitation_email from "./images/invitation-email.png"
import create_account from "./images/create-account.png"
import account_success from "./images/account-success.png"
import { CONNECT_BANK_ACCOUNT } from '../connect-bank-account/connect-bank-account.route';

const GERMAN_TUTORIAL = `
# Tutorial zur Erstellung eines Mandantenkontos

Dieses Tutorial erklärt Ihnen als Mandant, wie Sie Ihr Mandantenkonto bei Kanzlei21 erstellen.

1. **Einladung erhalten:** Sie erhalten eine Einladung per E-Mail an die Adresse, die Sie Ihrer Steuerkanzlei mitgeteilt haben. Klicken Sie auf den Link in der Einladung. Sind Sie unsicher, welche E-Mail-Adresse Sie angegeben haben? Kontaktieren Sie uns ***[hier]***. ![](${invitation_email})
2. **Mandantenkonto erstellen:** Der Bildschirm **Einladung annehmen** wird geöffnet. Hier müssen Sie Ihr Passwort festlegen. Ihr Passwort muss zwischen 8 und 32 Zeichen lang sein. Lesen Sie bitte anschließend die **Datenschutzbestimmungen**, **Nutzungsbedingungen** von Kanzlei21 und die **Vereinbarung zur Informationsweitergabe Ihrer Kanzlei**. Akzeptieren Sie diese Richtlinien, indem Sie die entsprechenden Kästchen anklicken, und klicken Sie auf **Bestätigen und Konto erstellen**.![](${create_account})  
3. **Konto erfolgreich erstellt:** Herzlichen Glückwunsch, Ihr Konto wurde erfolgreich erstellt! Auf dem nächsten Bildschirm erscheint eine Erfolgsmeldung. Sie können jetzt auf **Login** klicken.![](${account_success}) 
4. **Einloggen und Profil ansehen:** Nach dem Login können Sie Ihr Mandantenprofil einsehen.

Relevante Links:  
***[Tutorial zur Verbindung eines Bankkontos mit FinAPI](${CONNECT_BANK_ACCOUNT.getHref()})*** 

`;

const ENGLISH_TUTORIAL = `
# Tutorial for a client to create an account

This tutorial will guide you as a client through the process of creating you Kanzlei21 client account

1. **Receive an invitation email:** You will receive an invitation email at the address you provided to your accounting firm. Click on the link in the invitation email. Not sure which email address you provided? Contact us  ***here***.

![](${invitation_email})

2. **Create account:** The **Accept Invite** screen will open. Here, you need to set your password. Your password must be between 8 and 32 characters long. Then, please read Kanzlei21’s ***Datenschutzbestimmungen***, ***Nutzungsbedingungen*** and the ***Accounting firm’s information sharing agreement***.  Accept these policies by checking the corresponding boxes, and click **Bestätigen und Konto erstellen**.

![](${create_account})

3. **Account successfully created:** Congratulations, your account has been successfully created.  On the next screen, a success message will appear. You can now click on **Login**.

![](${account_success})

4. **Login and profile view:** After logging in, you will be able to see your client profile.


Relevant links:  
***[Tutorial for a client to connect a bank account with FinAPI](${CONNECT_BANK_ACCOUNT.getHref()})***
`;

function CreateAnAccount() {
    const language = useCurrentLanguage();

    return (
        <Card className={styles.tutorialCard}>
            <Markdown
                components={{
                    h1: (o) => (
                        <h1 className={`${HEADINGS_CLASS_NAMES.h1} mb-4`}>{o.children}</h1>
                    ),
                    h2: (o) => (
                        <h2 className={`${HEADINGS_CLASS_NAMES.h2} mb-4 mt-4`}>
                            {o.children}
                        </h2>
                    ),
                    h3: (o) => (
                        <h3 className={`${HEADINGS_CLASS_NAMES.h3} mb-4 mt-4`}>
                            {o.children}
                        </h3>
                    ),
                    h4: (o) => (
                        <h4 className={`${HEADINGS_CLASS_NAMES.h4} mb-4 mt-4`}>
                            {o.children}
                        </h4>
                    ),
                    ul: (o) => <ul className="list-disc list-inside">{o.children}</ul>,
                    ol: (o) => <ol className="list-decimal list-inside">{o.children}</ol>,
                    li: (o) => <li className="list-decimal list-inside mb-2">{o.children}</li>,
                    a: (o) => (
                        <a href={o.href} target="_blank" rel="noopener noreferrer" >
                            {o.children}
                        </a>
                    ),
                    img: (o) => <img src={o.src} alt="" className="mb-10 mt-2" />,
                    p: (o) => <p className="mb-6">{o.children}</p>,
                }}
            >
                {language == SupportedLanguage.German
                    ? GERMAN_TUTORIAL
                    : ENGLISH_TUTORIAL}
            </Markdown>
        </Card>
    );
}

export default CreateAnAccount