import { useState } from "react";
import { AcceptInviteForm } from "./steps/form/form";
import { AcceptInviteEnd } from "./steps/end/end";
import styles from "./page.module.scss";
import { useCurrentLanguage } from "../../../language/current-language";
import { SupportedLanguage } from "../../../language/supported-languages";
import { Helmet } from "react-helmet-async";

const GERMAN_TRANSLATIONS = {
  acceptInvite: "Einladung annehmen",
};

const ENGLISH_TRANSLATIONS = {
  acceptInvite: "Accept Invitation",
};

export function AcceptInvitePage() {
  const currentLanguage = useCurrentLanguage();

  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const [step, setStep] = useState<"form" | "end">("form");

  return (
    <>
      <Helmet>
        <title>{`${translations.acceptInvite} | Kanzlei21`}</title>
      </Helmet>

      <div className={styles.page}>
        <div className={styles.container}>
          {(() => {
            if (step == "form") {
              return <AcceptInviteForm onFinish={() => setStep("end")} />;
            } else if (step == "end") {
              return <AcceptInviteEnd />;
            } else {
              throw new Error();
            }
          })()}
        </div>
      </div>
    </>
  );
}
