import { Card } from 'primereact/card';
import styles from "./onboard-client.module.scss"
import Markdown from 'react-markdown';
import { SupportedLanguage } from '../../../../language/supported-languages';
import { HEADINGS_CLASS_NAMES } from '../../../../ui/headings';
import { useCurrentLanguage } from '../../../../language/current-language';
import neuen_mandanten from "./images/neuen_mandanten.png"
import einladungsmail_senden from "./images/einladungsmail_senden.png"
import wait_for_acceptance from "./images/wait-for-acceptance.png"
import gdpdu_datei_hochladen from "./images/gdpdu-datei-hochladen.png"
import confirm_client_information from "./images/confirm-client-information.png"
import email_senden from "./images/email-senden.png"
import bankkonto_einrichten from "./images/bankkonto-einrichten.png"
import assign_nominal_account from "./images/assign-nominal-account.png"
import { EXPORT_GDPDU_FROM_EDREWE } from '../export-gdpdu-from-edrewe/export-gdpdu-from-edrewe.route';


const GERMAN_TUTORIAL = `
# Tutorial: Onboarding eines neuen Mandanten

In diesem Tutorial erfahren Sie, wie der Onboarding-Prozess eines neuen Mandanten in Kanzlei21 abläuft.

1. **Anmeldung:** Melden Sie sich in der Kanzlei21-Webanwendung an.  
2. **Mandanten initialisieren:** Aus dem Dashboard, klicken Sie auf **Neuen Mandanten erstellen** (2.1), um den **Mandanteninitialisierungsassistenten** zu starten.![](${neuen_mandanten}) Im ersten Schritt, **1. Mandanten initialisieren**, klicken Sie auf **Neuen Mandanten initialisieren** (2.2). Eine Eingabemaske erscheint am unteren Bildschirmrand. Füllen Sie den Namen und die Kontakt-E-Mail-Adresse des Mandanten aus. Klicken Sie auf **Einladungsmail senden** (2.3). ![](${einladungsmail_senden})Ein Bestätigungs-Popup erscheint, das Sie darüber informiert, dass eine Einladung gesendet wird. Bestätigen Sie mit **Ja**.  
3. **Warten auf die Annahme der Einladung:** Nun müssen Sie warten, bis der Mandant die Einladung angenommen hat. Schließen Sie den Assistenten, indem Sie auf **Zurück zum Dashboard** klicken. Sobald der Mandant die Einladung annimmt, erhalten Sie eine Benachrichtigung. Öffnen Sie die Benachrichtigung und klicken Sie auf **Mandanteninitialisierung fortfahren**, um zum Schritt **2. GDPDU-Datei hochladen** zu gelangen.![](${wait_for_acceptance})
4. **GDPDU-Datei hochladen:** Im Schritt **2. GDPDU-Datei hochladen** klicken Sie auf **Mandateneinrichtung mit GDPdU**. Beachten Sie, dass Sie für diesen Schritt eine GDPDU-Datei bereit haben müssen. (siehe ***[Tutorial zum Exportieren einer GDPDU-Datei aus Edrewe](${EXPORT_GDPDU_FROM_EDREWE.getHref()})***). Wählen Sie die entsprechende Datei und klicken Sie auf **Öffnen**. ![](${gdpdu_datei_hochladen}) 
5. **Mandanteninformationen bestätigen:** Nach dem Upload der Datei gelangen Sie automatisch zum Schritt **3. Mandateninformationen bestätigen**. Überprüfen Sie die angezeigten Mandantendaten. Wenn alles korrekt ist, klicken Sie auf **Weiter**. Ein Bestätigungs-Popup erscheint. Überprüfen Sie die Details sorgfältig, da dieser Schritt nicht rückgängig gemacht werden kann. Sollte die hochgeladene GDPDU-Datei falsch sein, klicken Sie auf Zurück und laden Sie die korrekte Datei hoch.![](${confirm_client_information}) 
6. **Bankkonto verbinden:** Im Schritt **4. Bankkonto verbinden, klicken** Sie auf **Email senden** (6.1), um den Mandanten zur Verbindung seines Bankkontos mit FinAPI einzuladen.!![](${email_senden}) Solang der Mandant sein Bankkonto noch nicht verbunden hat, müssen Sie warten, bis er die Einladung annimmt und die Verbindung zu FinAPI herstellt. Schließen Sie den Assistenten mit einem Klick auf **Zurück zum Dashboard**. Sobald der Mandant die Verbindung abgeschlossen hat, erhalten Sie eine Benachrichtigung. Öffnen Sie die Benachrichtigung und klicken Sie auf **Bankkonto einrichten** (6.2), um zum Tab **4. Bankkonto verbinden** zurückzukehren.![](${bankkonto_einrichten}) 
7. **Sachkonto dem IBAN zuweisen:** Nach der Verbindung des Bankkontos sehen Sie im Bereich **Sachkonto dem Bankkonto zuweisen** die verknüpften IBANs. Wählen Sie das passende Konto aus und klicken Sie auf **Speichern**. Gibt es mehr als ein Konto, dann führen Sie diesen Schritt für alle Konten aus. Anschließend klicken Sie auf **Weiter**. Im Schritt **5. Kontenübersicht** können Sie alle **Sach-**, **Bank-** und **Personenkonten** sehen.![](${assign_nominal_account}) 
   

Herzlichen Glückwunsch!  
Sie haben erfolgreich einen neuen Mandanten erstellt. Klicken Sie anschließend auf **Finish**. Der Mandant wird nun in der Mandantenliste angezeigt. Beachten Sie, dass Banktransaktionen für den neuen Mandanten bis zu 24 Stunden benötigen können, um sichtbar zu werden.

`;

const ENGLISH_TUTORIAL = `
# Tutorial for onboarding a new client

This tutorial explains how to onboard a new client in Kanzlei21.

	

1. **Login:** Log into the Kanzlei21 web application.  
2. **Client initialization:** Starting at the **Dashboard** screen, click on the **Neuen Mandanten erstellen** button (2.1) to start the **Client Initialization** wizard.
![](${neuen_mandanten})
In the first step, **1. Mandanten initialisieren**, click on **Neuen Mandanten initialisieren** (2.2). A mask will appear at the bottom of the screen. Fill in the client's name and contact email address. Click on **Einladungsmail senden** (2.3).
![](${einladungsmail_senden})
A confirmation popup will appear notifying you that an invitation email will be sent to the client. Confirm by clicking on **Ja**.  
3. **Wait for client acceptance:** Now, you need to wait until the client has accepted the invitation. Close the wizard by clicking on **Zurück zum Dashboard**. You will receive a notification once the client has accepted the invitation. Open the notification, and click on **Mandanteninitialisierung fortfahren**. The Client Initialization wizard will open on the second step called **2. GDPDU-Datei hochladen**.  
![](${wait_for_acceptance})
4. **GDPDU-Datei hochladen:** In the **2. GDPDU-Datei hochladen**  step, click on the **Mandateneinrichtung mit GDPdU**  button. Note, that you need to have a GDPDU file for this (see the **[Tutorial for exporting a GDPDU file from Edrewe](${EXPORT_GDPDU_FROM_EDREWE.getHref()})**). Select the corresponding client’s GDPDU file and click on **Open**.   
![](${gdpdu_datei_hochladen})
5. **Confirm Client Information:** Once the file has been uploaded, you will be automatically taken to the next step **3. Mandateninformationen bestätigen.** Review the client data displayed on the screen. If everything is correct, click on the **Weiter** button. A confirmation popup will appear. Verify all details carefully, as this step cannot be undone. If the GDPDU file is incorrect, click on **Zurück** and upload the correct file.  
![](${confirm_client_information})
6. **Connect Bank Account:** In the **4. Bankkonto verbinden** step**,** click on the **Email senden** button (6.1).Once the mail has been sent, you need to wait until the client has accepted the invitation.
![](${email_senden})
Close the wizard for now by clicking on **Back to Dashboard**. You will receive a notification once the client has connected their bank account to FinAPI. Open the notification, and click on **Bankkonto einrichten** (6.2). You will be taken back to the **4. Bankkonto verbinden** tab.   
![](${bankkonto_einrichten})
7. **Assign nominal accounts to bank accounts:** After the user has connected their bank account to FinAPI,you can see on the Bank accounts screen a section called **Sachkonto dem Bankkonto zuweisens**. Select the nominal account you will connect to the IBAN that was connected to FinAPI. If there is more than one account, do this for all of them. Click on **Speichern** and then click on **Weiter**. In the **5. Kontenübersicht** step, you can see all **Sach-**, **Bank-** and **Personenkonten**.   
![](${assign_nominal_account})
Congratulations! You have successfully onboarded a new client! Click on Finish to go back to the dashboard. Your new client should appear in the clients list. Note that bank transactions for the new client may take up to 24 hours to appear.  
`;

function OnboardClientToK21() {
    const language = useCurrentLanguage();

    return (
        <Card className={styles.tutorialCard}>
            <Markdown
                components={{
                    h1: (o) => (
                        <h1 className={`${HEADINGS_CLASS_NAMES.h1} mb-4`}>{o.children}</h1>
                    ),
                    h2: (o) => (
                        <h2 className={`${HEADINGS_CLASS_NAMES.h2} mb-4 mt-4`}>
                            {o.children}
                        </h2>
                    ),
                    h3: (o) => (
                        <h3 className={`${HEADINGS_CLASS_NAMES.h3} mb-4 mt-4`}>
                            {o.children}
                        </h3>
                    ),
                    h4: (o) => (
                        <h4 className={`${HEADINGS_CLASS_NAMES.h4} mb-4 mt-4`}>
                            {o.children}
                        </h4>
                    ),
                    ul: (o) => <ul className="list-disc list-inside">{o.children}</ul>,
                    ol: (o) => <ol className="list-decimal list-inside">{o.children}</ol>,
                    li: (o) => <li className="list-decimal list-inside mb-2">{o.children}</li>,
                    a: (o) => (
                        <a href={o.href} target="_blank" rel="noopener noreferrer" >
                            {o.children}
                        </a>
                    ),
                    img: (o) => <img src={o.src} alt="" className="mb-10 mt-2" />,
                    p: (o) => <p className="mb-6">{o.children}</p>,
                }}
            >
                {language == SupportedLanguage.German
                    ? GERMAN_TUTORIAL
                    : ENGLISH_TUTORIAL}
            </Markdown>
        </Card>
    );
}

export default OnboardClientToK21