import { Button } from "primereact/button";
import { useCallback, useContext, useState } from "react";
import { SupportedLanguage } from "../../../../../../language/supported-languages";
import { useMainApi } from "../../../../../../main-api";
import { useCurrentLanguage } from "../../../../../../language/current-language";
import { BankConnectionSchema, ResultsContext } from "../results-context";
import { throwError } from "../../../../../../throw-error";
import { z } from "zod";
import {
  getCommunicationErrorMessage,
  getCommunicationErrorTitle,
} from "../../../../../../communication-errors/communication-error-messages";
import { Dialog } from "primereact/dialog";
import { ProgressSpinner } from "primereact/progressspinner";
import { ConfirmDialog } from "primereact/confirmdialog";

const GERMAN_TRANSLATIONS = {
  accountsConnected: "Konten erfolgreich verbunden",
  addNewBank: "Neue Bank hinzufügen",
  addNewBankMessage: "Klicken Sie auf Neue Bank Hinzufügen um fortzufahren.",
  cancel: "Abbrechen",
  addBank: "Neue Bank Hinzufügen",
  bankAlreadyConnectedSummary: "Bank ist bereits verbunden",
  doNotCloseTabWarning: "Schließen Sie diese Registerkarte nicht",
};

const ENGLISH_TRANSLATIONS = {
  accountsConnected: "Accounts successfully connected",
  addNewBank: "Add New Bank",
  addNewBankMessage: `Click on "Add New Bank" to continue.`,
  cancel: "Cancel",
  addBank: "Add new bank",
  bankAlreadyConnectedSummary: "Bank is already connected",
  doNotCloseTabWarning: "Do not close this tab while connecting your bank.",
};

export function AddBankConnection() {
  const mainApi = useMainApi();
  const { results, setResults, toastRef } =
    useContext(ResultsContext) || throwError();
  const [dialogVisible, setDialogVisible] = useState(false);

  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const [state, setState] = useState<{
    loading?: boolean;
    data?: {
      webformId?: string;
      webformUrl?: string;
    };
  }>({});

  const pollWebFormStatus = useCallback(() => {
    if (!state.data) {
      return;
    }

    setDialogVisible(true);

    const intervalId = setInterval(async () => {
      const res = await mainApi.fetchJSON({
        path: `/bank-accounts/finapi/web_forms/${state.data?.webformId}`,
        method: "GET",
        schema: z.union([
          z.object({
            status: z.literal(200),
            body: z.object({
              status: z.literal("COMPLETED"),
              bank_connection: BankConnectionSchema,
            }),
          }),
          z.object({
            status: z.literal(200),
            body: z.object({
              status: z.literal("IN_PROGRESS"),
            }),
          }),
          z.object({
            status: z.literal(200),
            body: z.object({
              status: z.literal("ENTITY_EXISTS"),
            }),
          }),
        ]),
      });

      if (res.error) {
        toastRef.current?.show({
          severity: "error",
          summary: getCommunicationErrorTitle(res.error),
          detail: getCommunicationErrorMessage(res.error),
        });
        clearInterval(intervalId);
        setDialogVisible(false);
        setState({});
        return;
      }

      const responseBody = res.response.body;
      if (responseBody.status === "IN_PROGRESS") {
        return; // Continue polling
      }

      clearInterval(intervalId);
      setDialogVisible(false);
      setState({});

      if (responseBody.status === "ENTITY_EXISTS") {
        toastRef.current?.show({
          severity: "warn",
          summary: translations.bankAlreadyConnectedSummary,
          life: 30 * 1000,
        });
        return;
      }

      if (responseBody.status === "COMPLETED") {
        setResults((prev) => ({
          ...prev,
          data: [...(prev.data ?? []), responseBody.bank_connection],
        }));

        toastRef.current?.show({
          severity: "success",
          summary: translations.accountsConnected,
        });
        return;
      }
    }, 3000);
  }, [mainApi, setResults, state.data, toastRef, translations]);

  return (
    <>
      <div className={"flex items-center justify-center"}>
        {(() => {
          if (state.data) {
            return (
              <ConfirmDialog
                group="declarative"
                visible
                header={translations.addNewBank}
                message={translations.addNewBankMessage}
                accept={() => {
                  window.open(`${state.data?.webformUrl}`, "_blank");
                  pollWebFormStatus();
                }}
                acceptLabel={translations.addBank}
                rejectLabel={translations.cancel}
              />
            );
          }
        })()}

        <Button
          outlined={true}
          icon={"pi pi-link"}
          label={`${translations.addNewBank}`}
          disabled={state.loading || !results.data}
          loading={state.loading}
          onClick={async () => {
            setState({ loading: true });

            const res = await mainApi.fetchJSON({
              method: "POST",
              path: "/bank-accounts/finapi/connect-new-bank",
              schema: z.object({
                status: z.literal(200),
                body: z.object({
                  web_form_url: z.string(),
                  web_form_id: z.string(),
                }),
              }),
            });

            setState({ loading: false });

            if (res.error) {
              toastRef.current?.show({
                severity: "error",
                summary: getCommunicationErrorTitle(res.error),
                detail: getCommunicationErrorMessage(res.error),
              });
              return;
            }

            setState({
              data: {
                webformId: res.response.body.web_form_id,
                webformUrl: res.response.body.web_form_url,
              },
            });
          }}
        />
      </div>

      <Dialog modal visible={dialogVisible} onHide={() => {}} closable={false}>
        <div className={`flex items-center justify-center w-100`}>
          <ProgressSpinner />
          <b>{translations.doNotCloseTabWarning}</b>
        </div>
      </Dialog>
    </>
  );
}
