import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from "react";
import { z } from "zod";
import { useMainApi } from "../../../../../../../main-api";
import { CommunicationError } from "../../../../../../../communication-errors/communication-errors";
import { useAccountingClientId } from "../../../accounting-client-id";
import { BookingsPageBankAccountsContext } from "./quick-filters/bank-accounts-context";
import { throwError } from "../../../../../../../throw-error";

export const CounterAccountSchema = z.object({
  id: z.string(),
  name: z.string(),
  number: z.number(),
});

export type CounterAccountContextValue = {
  data?: CounterAccount[];
  loading?: boolean;
  error?: CommunicationError;
  refresh: () => void;
};

export type CounterAccount = z.TypeOf<typeof CounterAccountSchema>;

export const RelatedAccountsContext = createContext<
  undefined | CounterAccountContextValue
>(undefined);

export function RelatedAccountsProvider({ children }: { children: ReactNode }) {
  const mainApi = useMainApi();

  const accountingClientId = useAccountingClientId();

  const { selectedBankAccount } =
    useContext(BookingsPageBankAccountsContext) || throwError();

  const [results, setResults] = useState<
    Readonly<{
      data?: CounterAccount[];
      loading?: boolean;
      error?: CommunicationError;
    }>
  >({});

  const refresh = useCallback(async () => {
    setResults((results) => ({
      ...results,
      loading: true,
    }));

    if (!selectedBankAccount) {
      setResults(() => ({
        results: [],
        loading: false,
        error: undefined,
      }));
      return;
    }

    const urlSearchParams = new URLSearchParams();
    urlSearchParams.append(
      "selected_account_id",
      selectedBankAccount.account_id
    );

    const res = await mainApi.fetchJSON({
      method: "GET",
      path: `/accounting_clients/${accountingClientId}/bank_bookings/get_related_accounts?${urlSearchParams.toString()}`,
      schema: z.object({
        status: z.literal(200),
        body: z.array(CounterAccountSchema),
      }),
    });

    if (res.error) {
      setResults((results) => ({
        ...results,
        loading: false,
        error: res.error,
      }));
      return;
    }

    setResults(() => ({
      error: undefined,
      loading: false,
      data: res.response.body,
    }));
  }, [accountingClientId, mainApi, selectedBankAccount]);

  useEffect(() => {
    (async () => {
      refresh();
    })();
  }, [refresh]);

  const value = useMemo<CounterAccountContextValue>(
    () => ({
      ...results,
      refresh,
    }),
    [results, refresh]
  );

  return (
    <RelatedAccountsContext.Provider value={value}>
      {children}
    </RelatedAccountsContext.Provider>
  );
}
