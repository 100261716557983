import { Card } from 'primereact/card';
import styles from "./export-gdpdu-from-edrewe.module.scss"
import Markdown from 'react-markdown';
import { SupportedLanguage } from '../../../../language/supported-languages';
import { HEADINGS_CLASS_NAMES } from '../../../../ui/headings';
import { useCurrentLanguage } from '../../../../language/current-language';
import buchführung from "./images/buchführung.png"
import startseite from "./images/startseite.png"
import select_export_gdpdu from "./images/select-export-gdpdu.png"
import select_export_settings from "./images/select-export-settings.png"
import { IMPORT_GDPDU_TO_K21 } from '../import-gdpdu-to-k21/import-gdpdu-to-k21.route';
import { EXPORT_BOOKINGS_TO_EDREWE } from '../export-bookings-to-edrewe/export-bookings-to-edrewe.route';

const GERMAN_TUTORIAL = `
# Tutorial zum Exportieren einer GDPDU-Datei aus Edrewe

Dieses Tutorial erklärt, wie man eine GDPDU-Datei aus Edrewe exportiert. Um zu erfahren, wie man eine GDPDU-Datei in Kanzlei21 importiert, ***klicken Sie hier*** **(link placeholder URL tutorial 5)**. Beachten Sie, dass Sie jeweils nur eine GDPDU-Datei für ein Jahr und einen Mandanten exportieren können. Wenn Sie Daten für zwei Jahre (z. B. 2023, 2024) exportieren möchten, müssen Sie diesen Prozess zweimal durchführen.

1. **Login:** Loggen Sie sich in die Edrewe-App ein.  
2. **Gehen Sie zum Import-Bereich:** Wählen Sie im Seitenmenü unter dem Tab **Buchführung** einen Mandanten aus. Doppelklicken Sie dann auf das Jahr (z. B. 2024). ![](${buchführung})]Dies sind der Mandant und das Jahr, die in der GDPDU-Datei exportiert werden. Nach dem Doppelklick auf das Jahr öffnet sich die **Startseite** des Mandanten.![](${startseite}) 
3. **Wählen Sie Export GDPDU aus:** Gehen Sie im Seitenmenü zum **Arbeitsplatz** (3.1). Wählen Sie aus den Optionen **Export** aus (3.2). Klicken Sie dann auf **GDPDU** als Exporttyp (3.3). Klicken Sie anschließend auf **Exportieren** in der oberen rechten Ecke (3.4).![](${select_export_gdpdu}) 
4. **Wählen Sie die Export-Einstellungen aus:** Es erscheint ein Popup mit den Exporteinstellungen. Stellen Sie sicher, dass alle Monate des Jahres, das Sie möchten, ausgewählt sind. Wählen Sie einen Speicherort, an dem Ihre Datei gespeichert werden soll. Lassen Sie alle anderen Optionen unverändert.![](${select_export_settings})  
5. **Export abschließen:** Klicken Sie auf **Fertigstellen**. Eine komprimierte .zip-Datei mit Ihrer GDPDU-Datei wurde an den Speicherort exportiert, den Sie im vorherigen Schritt ausgewählt haben.

Relevante Links:  
***[Tutorial zum Import einer GDPDU-Datei in Kanzlei21](${IMPORT_GDPDU_TO_K21.getHref()})***.

***[Tutorial: Export von Buchungen aus Kanzlei21 und Import in Edrewe](${EXPORT_BOOKINGS_TO_EDREWE.getHref()})*** 

`;

const ENGLISH_TUTORIAL = `
# Tutorial for exporting a GDPDU file from Edrewe

This tutorial explains how to export a GDPDU file from Edrewe. To learn how to import a GDPDU file on Kanzlei21, ***click here*** **(link placeholder URL tutorial 5)**. Note that you can only export a GDPDU file for one year for one client each time. For example, if you need to export data for 2 years (2023, 2024), you will need to repeat this process twice. 

	

1. **Login:** Log into the Erdewe app.   
2. **Go to the import section:** Starting from the **Buchführung** tab on the sidebar panel, select a **Mandant** from the main screen. Then, double click on the year (e.g. **2024**).
![](${buchführung})
These are the Mandant and the Year that will be exported in the GDPDU file.After double clicking on the Year, the client’s **Sartseite** will open.  
![](${startseite})
3. **Select Export GDPDU**: On the sidebar, now go to **Arbeitsplatz** (3.1). From the options, choose **Export** (3.2). Now click on **GDPDU** as export type(3.3). Afterward, click on **Exportieren** on the top-right corner(3.4).  
![](${select_export_gdpdu})
4. **Select GDPDU export settings:** A popup with export settings appears. Ensure that all months from the year you want are selected. Select a **Speicherort** where your file will be saved.You can leave all other options as they are.   
![](${select_export_settings})
5. **Finish export:** Click on **Fertigstellen**. A compressed .zip file with your GDPDU file has been exported to the destination location you chose on the previous step.   
   

Relevant links:  
***[Tutorial for importing a GDPDU file on Kanzlei21](${IMPORT_GDPDU_TO_K21.getHref()})***

***[Tutorial for exporting bookings from Kanzlei21 and reimporting them into Edrewe](${EXPORT_BOOKINGS_TO_EDREWE.getHref()})***

`;

function ExportGDPDUFromEdrewe() {
    const language = useCurrentLanguage();

    return (
        <Card className={styles.tutorialCard}>
            <Markdown
                components={{
                    h1: (o) => (
                        <h1 className={`${HEADINGS_CLASS_NAMES.h1} mb-4`}>{o.children}</h1>
                    ),
                    h2: (o) => (
                        <h2 className={`${HEADINGS_CLASS_NAMES.h2} mb-4 mt-4`}>
                            {o.children}
                        </h2>
                    ),
                    h3: (o) => (
                        <h3 className={`${HEADINGS_CLASS_NAMES.h3} mb-4 mt-4`}>
                            {o.children}
                        </h3>
                    ),
                    h4: (o) => (
                        <h4 className={`${HEADINGS_CLASS_NAMES.h4} mb-4 mt-4`}>
                            {o.children}
                        </h4>
                    ),
                    ul: (o) => <ul className="list-disc list-inside">{o.children}</ul>,
                    ol: (o) => <ol className="list-decimal list-inside">{o.children}</ol>,
                    li: (o) => <li className="list-decimal list-inside mb-2">{o.children}</li>,
                    a: (o) => (
                        <a href={o.href} target="_blank" rel="noopener noreferrer" >
                            {o.children}
                        </a>
                    ),
                    img: (o) => <img src={o.src} alt="" className="mb-10 mt-2" />,
                    p: (o) => <p className="mb-6">{o.children}</p>,
                }}
            >
                {language == SupportedLanguage.German
                    ? GERMAN_TUTORIAL
                    : ENGLISH_TUTORIAL}
            </Markdown>
        </Card>
    );
}

export default ExportGDPDUFromEdrewe