import { useContext } from "react";
import { Button } from "primereact/button";
import { QuickFiltersContext } from "./quick-filters-context";
import { throwError } from "../../../../../../throw-error";

export function ResetFiltersButton() {
    const { setQuickFilters } = useContext(QuickFiltersContext) || throwError();
    return (
        <>
            <Button
                outlined={true}
                severity={"danger"}
                icon="pi pi-filter-slash"
                onClick={() => {
                    setQuickFilters({
                        search: null,
                    });
                }}
            />
        </>
    );
}
