import { DetailsPanel } from "./details-panel/details-panel";
import { Results } from "./results/results";
import { ResultsContextProvider } from "./results/results-context";
import { FiltersContextProvider } from "./top-section/navbar/filters/filters-context";
import { QuickFiltersContextProvider } from "./top-section/navbar/quick-filters/quick-filters-context";
import { TopSection } from "./top-section/top-section";
import styles from "./page.module.scss";
import { ReactNode, useContext } from "react";
import { SortingContextProvider } from "./sorting/sorting-context";
import { BookingsPageBankAccountsProvider } from "./top-section/navbar/quick-filters/bank-accounts-context";
import { BookingsPageResourcesLoader } from "./page-resources";
import { RelatedAccountsProvider } from "./top-section/navbar/related-accounts";
import { useCurrentLanguage } from "../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../language/supported-languages";
import { AccountingClientsContext } from "../../../menu/accounting-clients-context";
import { throwError } from "../../../../../throw-error";
import { Title } from "../../title";

const GERMAN_TRANSLATIONS = {
  bankBookings: "Bankbuchungen",
};

const ENGLISH_TRANSLATIONS = {
  bankBookings: "Bank Bookings",
};

function Page(props: { children: ReactNode }) {
  return <div className={styles.page}>{props.children}</div>;
}

export function BookingsPage() {
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const { selectedAccountingClient } =
    useContext(AccountingClientsContext) || throwError();
  return (
    <>
      {selectedAccountingClient && (
        <Title
          accountingClientName={selectedAccountingClient.name}
          page={translations.bankBookings}
        />
      )}
      <BookingsPageResourcesLoader>
        <BookingsPageBankAccountsProvider>
          <RelatedAccountsProvider>
            <QuickFiltersContextProvider>
              <FiltersContextProvider>
                <SortingContextProvider>
                  <ResultsContextProvider>
                    <Page>
                      <TopSection className={""} />
                      <Results className={"flex-1"} />
                      <DetailsPanel className={""} />
                    </Page>
                  </ResultsContextProvider>
                </SortingContextProvider>
              </FiltersContextProvider>
            </QuickFiltersContextProvider>
          </RelatedAccountsProvider>
        </BookingsPageBankAccountsProvider>
      </BookingsPageResourcesLoader>
    </>
  );
}
