import { Button } from "primereact/button";
import { useState, useRef, useContext } from "react";
import { useMainApi } from "../../../../../../../../main-api";
import { throwError } from "../../../../../../../../throw-error";
import { z } from "zod";
import { GdpduContext } from "../../../gdpdu-preview-context";
import { AccountingClient } from "../../../invited-accounting-client-loader";
import { AccountingClientFormValue } from "../../basic-information/basic-information";
import { Toast } from "primereact/toast";
import {
  getCommunicationErrorMessage,
  getCommunicationErrorTitle,
} from "../../../../../../../../communication-errors/communication-error-messages";

const GdpduPreviewResponseSchema = z.object({
  client_number: z.string(),
  name: z.string(),
  system_of_accounts: z.string(),
  vat_id: z.string().nullable(),
});

function UploadToPreviewGdpduButton({
  onNextStep,
  label,
  className,
}: {
  accountingClient: AccountingClient;
  onNextStep: () => void;
  label: string;
  className?: string;
}) {
  const mainApi = useMainApi();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const toastRef = useRef<Toast | null>(null);
  const [state, setState] = useState<{
    loading?: boolean;
    previewData?: AccountingClientFormValue;
    gdpuFile?: File;
  }>({});

  const { setGdpduPreviewState } = useContext(GdpduContext) || throwError();

  const handleFileUpload = async (file: File) => {
    setState({ loading: true });

    const formData = new FormData();
    formData.append("file", file);

    const res = await mainApi.fetch(
      `/accounting_clients/onboarding/gdpdu/preview`,
      [200],
      {
        method: "POST",
        body: formData,
      }
    );

    inputRef.current!.value = "";
    setState({ loading: false });

    if (res.error) {
      toastRef.current!.show({
        severity: "error",
        summary: getCommunicationErrorTitle(res.error),
        detail: getCommunicationErrorMessage(res.error),
      });
      return;
    }

    const previewData = GdpduPreviewResponseSchema.parse(
      await res.response.json()
    );

    setGdpduPreviewState({
      file,
      data: {
        client_number: previewData.client_number,
        name: previewData.name,
        system_of_accounts: previewData.system_of_accounts,
        vat_id: previewData.vat_id,
      },
    });

    onNextStep();
  };

  return (
    <>
      <Toast ref={toastRef} />
      <input
        type="file"
        ref={inputRef}
        style={{ display: "none" }}
        onChange={(e) => {
          const file = e.target.files?.[0] ?? throwError();
          handleFileUpload(file);
        }}
      />
      <Button
        icon="pi pi-file-import"
        label={label}
        disabled={state.loading}
        loading={state.loading}
        onClick={() => inputRef.current!.click()}
        className={className}
      />
    </>
  );
}

export default UploadToPreviewGdpduButton;
