import {
  ReactNode,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { z } from "zod";
import { useMainApi } from "../../../main-api";
import { CommunicationError } from "../../../communication-errors/communication-errors";
import { useParams } from "react-router-dom";

export const AccountingClientsSchema = z.object({
  id: z.string(),
  name: z.string(),
  client_number: z.string().nullable(),
});

export type AccountingClient = z.TypeOf<typeof AccountingClientsSchema>;

function useContextValue() {
  const [accountingClients, setAccountingClients] = useState<
    Readonly<{
      data?: AccountingClient[];
      loading?: boolean;
      error?: CommunicationError;
    }>
  >({});

  const [forcedUpdateKey, setForcedUpdateKey] = useState(0);

  const mainApi = useMainApi();

  const params = useParams();

  useEffect(() => {
    (async () => {
      setAccountingClients({ loading: true });

      const result = await mainApi.fetchJSON({
        method: "GET",
        path: "/accounting_clients",
        schema: z.object({
          status: z.literal(200),
          body: z.array(AccountingClientsSchema),
        }),
      });

      if (result.error) {
        setAccountingClients({ error: result.error });
        return;
      }

      setAccountingClients({ data: result.response.body });
    })();
  }, [mainApi, forcedUpdateKey]);

  const refreshAccountingClients = useCallback(() => {
    setForcedUpdateKey((key) => key + 1);
  }, []);

  const selectedAccountingClient = useMemo(() => {
    if (!params.accountingClientId || !accountingClients.data) {
      return undefined;
    }

    const client = accountingClients.data.find(
      (accountingClient) => accountingClient.id === params.accountingClientId
    );

    return client;
  }, [params.accountingClientId, accountingClients.data]);

  return useMemo(() => {
    return {
      accountingClients,
      selectedAccountingClient,
      refreshAccountingClients,
    };
  }, [accountingClients, selectedAccountingClient, refreshAccountingClients]);
}

export const AccountingClientsContext = createContext<
  undefined | ReturnType<typeof useContextValue>
>(undefined);

export function AccountingClientsProvider(props: { children: ReactNode }) {
  const value = useContextValue();

  return (
    <AccountingClientsContext.Provider value={value}>
      {props.children}
    </AccountingClientsContext.Provider>
  );
}
