import { Messages, MessagesMessage } from "primereact/messages";
import { Toast } from "primereact/toast";
import { useEffect, useRef } from "react";
import {
  getCommunicationErrorTitle,
  getCommunicationErrorMessage,
} from "../../../../../communication-errors/communication-error-messages";
import { useMainApi } from "../../../../../main-api";
import { z } from "zod";
import { useCurrentLanguage } from "../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../language/supported-languages";
import { useNavigate } from "react-router-dom";
import { BANK_CONNECTION_ROUTE } from "../../../bank-connections/bank-connection-route";

const GERMAN_TRANSLATIONS = {
  actionRequired: "Handlung erforderlich",
  connectBank: "Bankkonto verbinden",
  updateBank: "Bankverbindung muss aktualisiert werden",
  uploadDocument: "Dokument hochladen",
  noPendingTasks: "Super! Sie haben keine offenen Aufgaben.",
};

const ENGLISH_TRANSLATIONS = {
  actionRequired: "Action Needed",
  connectBank: "Connect Bank Account",
  updateBank: "Bank Connection Needs Update",
  uploadDocument: "Upload Document",
  noPendingTasks: "Great! You have no pending tasks.",
};

const TaskSchema = z.object({
  tasks: z.array(
    z.enum([
      "BANK_NOT_CONNECTED",
      "BANK_CONNECTION_NEEDS_UPDATE",
      "MISSING_DOCUMENTS",
    ])
  ),
});

export type Task = z.infer<typeof TaskSchema>;

export default function Tasks() {
  const msgs = useRef<Messages>(null);
  const mainApi = useMainApi();
  const navigate = useNavigate();
  const toast = useRef<Toast | null>(null);
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  useEffect(() => {
    (async () => {
      const res = await mainApi.fetchJSON({
        path: `/accounting_client/dashboard/tasks`,
        method: "GET",
        schema: z.object({
          status: z.literal(200),
          body: TaskSchema,
        }),
      });

      if (res.error) {
        toast.current!.show({
          severity: "error",
          summary: getCommunicationErrorTitle(res.error),
          detail: getCommunicationErrorMessage(res.error),
        });

        return;
      }

      if (msgs.current) {
        msgs.current.clear();

        const messages = res.response.body.tasks.map((task) => {
          if (task === "BANK_NOT_CONNECTED") {
            return {
              severity: "warn",
              summary: translations.actionRequired,
              detail: translations.connectBank,
              sticky: true,
              closable: false,
              content: (
                <span
                  className="cursor-pointer"
                  onClick={() => navigate(BANK_CONNECTION_ROUTE.getHref())}
                >
                  <b>{translations.actionRequired}:</b>{" "}
                  {translations.connectBank}
                </span>
              ),
            };
          } else if (task === "BANK_CONNECTION_NEEDS_UPDATE") {
            return {
              severity: "warn",
              summary: translations.actionRequired,
              detail: translations.updateBank,
              sticky: true,
              closable: false,

              content: (
                <span
                  className="cursor-pointer"
                  onClick={() => navigate(BANK_CONNECTION_ROUTE.getHref())}
                >
                  <b>{translations.actionRequired}:</b>{" "}
                  {translations.updateBank}
                </span>
              ),
            };
          } else if (task === "MISSING_DOCUMENTS") {
            return {
              severity: "warn",
              summary: translations.actionRequired,
              detail: translations.uploadDocument,
              sticky: true,
              closable: false,
            };
          } else {
            throw new Error();
          }
        });

        if (messages.length > 0) {
          msgs.current.show(messages as MessagesMessage[]);
        } else {
          msgs.current.show({
            severity: "success",
            summary: translations.noPendingTasks,
            detail: "",
            sticky: true,
            closable: false,
          });
        }
      }
    })();
  }, [mainApi, translations, navigate]);

  return (
    <>
      <Toast ref={toast} />
      <Messages ref={msgs} />
    </>
  );
}
