import formSections from "./form-sections.module.scss";
import { useContext } from "react";
import { AccountingClientContext } from "../../../accounting-client-context";
import { throwError } from "../../../../../../../../throw-error";
import { useCurrentLanguage } from "../../../../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../../../../language/supported-languages";

const GERMAN_TRANSLATIONS = {
  clientNumber: "Mandantennummer *",
  clientInformation: "Mandanteninformationen",
  clientType: "Mandantentyp *",
  company: "Firma",
  name: "Name *",
  startingDate: "Abrechnungsbeginn *",
  systemOfAccounts: "Kontenrahmen *",
  selectSysteemOfAccounts: "Bitte wählen Sie einen Kontenrahmen aus",
  vatId: "Ust Id *",
};

const ENGLISH_TRANSLATIONS = {
  clientNumber: "Client Number *",
  clientInformation: "Client Information",
  clientType: "Client Type *",
  company: "Company",
  name: "Name *",
  startingDate: "Start accounting *",
  systemOfAccounts: "System of Accounts *",
  selectSysteemOfAccounts: "Please select a system of accounts type",
  vatId: "Vat Id *",
};

export function ClientData(props: {
  data: {
    client_number: string;
    name: string;
    system_of_accounts: string;
    vat_id: string | null;
  };
}) {
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const { formOptions } = useContext(AccountingClientContext) || throwError();

  return (
    <>
      <div className={formSections.formField}>
        <label className={formSections.label} htmlFor="client_number">
          {translations.clientNumber}
        </label>
        <div className={formSections.inputWrapper}>
          <p id="client_number" className={`${formSections.input}`}>
            {props.data.client_number}
          </p>
        </div>
      </div>

      <div className={formSections.formField}>
        <label className={formSections.label} htmlFor="client_type">
          {translations.clientType}
        </label>
        <div className={formSections.inputWrapper}>
          <p id="client_type" className={`${formSections.input}`}>
            {translations.company}
          </p>
        </div>
      </div>

      <div className={formSections.formField}>
        <label className={formSections.label} htmlFor="name">
          {translations.name}
        </label>
        <div className={formSections.inputWrapper}>
          <p id="name" className={`${formSections.input}`}>
            {props.data.name}
          </p>
        </div>
      </div>

      <div className={formSections.formField}>
        <label className={formSections.label} htmlFor="vat_id">
          {translations.vatId}
        </label>
        <div className={formSections.inputWrapper}>
          <p id="vat_id" className={`${formSections.input}`}>
            {props.data.vat_id}
          </p>
        </div>
      </div>

      <div className={formSections.formField}>
        <>
          <label className={formSections.label} htmlFor={"system_of_accounts"}>
            {translations.systemOfAccounts}
          </label>
          <div className={`${formSections.inputWrapper}`}>
            <p id="system_of_accounts" className={`${formSections.input}`}>
              {(() => {
                const systemOfAccountsType =
                  formOptions.system_of_accounts_types.find(
                    (s) => s.id === props.data.system_of_accounts
                  ) || throwError();

                return `${systemOfAccountsType.name} (${systemOfAccountsType.system_of_accounts_number})`;
              })()}
            </p>
          </div>
        </>
      </div>
    </>
  );
}
