import styles from "./accounting-firm-administration-layout.module.scss";
import { Menu } from "./menu/accounting-firm-administration-menu";
import { ReactNode, useRef } from "react";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { useCurrentLanguage } from "../../../../language/current-language";
import { SupportedLanguage } from "../../../../language/supported-languages";
import { Toast } from "primereact/toast";
import { AccountingFirm } from "../accounting-firm-loader";

const ENGLISH_TRANSLATIONS = {
  backToDashboard: "Back to Dashboard",
};

const GERMAN_TRANSLATIONS = {
  backToDashboard: "Zurück zum Dashboard",
};

function AccountingFirmAdministrationLayout(props: {
  children: ReactNode;
  accountingFirm: AccountingFirm;
}) {
  const currentLanguage = useCurrentLanguage();
  const toast = useRef<Toast | null>(null);

  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const navigate = useNavigate();
  return (
    <div className="flex flex-col h-screen">
      <Toast ref={toast} />
      <div className="px-4 py-2">
        <Button
          icon="pi pi-arrow-left"
          link
          label={translations.backToDashboard}
          onClick={() => navigate(`/accountant`)}
        />
      </div>
      <div className="flex h-full w-full overflow-y-auto">
        <div className={`${styles.menuSection} w-1/5`}>
          <Menu accountingFirm={props.accountingFirm} />
        </div>
        <div className={`w-4/5 ${styles.backgroundColor}`}>
          {props.children}
        </div>
      </div>
    </div>
  );
}
export default AccountingFirmAdministrationLayout;
