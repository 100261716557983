import { Toast } from "primereact/toast";
import {
  RefObject,
  useContext,
  useState,
  useEffect,
  useMemo,
  createContext,
  ReactNode,
  useRef,
} from "react";
import { z } from "zod";
import {
  getCommunicationErrorTitle,
  getCommunicationErrorMessage,
} from "../../../../../../communication-errors/communication-error-messages";
import { CommunicationError } from "../../../../../../communication-errors/communication-errors";
import { useMainApi } from "../../../../../../main-api";
import { throwError } from "../../../../../../throw-error";
import { ResultsContext } from "../../result-context";

const ExportJournalCountsSchema = z.object({
  id: z.string(),
  historical_reduced_journals_count: z.number(),
  corrected_reduced_journals_count: z.number(),
  predicted_reduced_journals_count: z.number(),
  manually_approved_reduced_journals_count: z.number(),
  action_needed_reduced_journals_count: z.number(),
  total: z.number(),
});

export type ExportJournalCount = z.TypeOf<typeof ExportJournalCountsSchema>;

function useProvider({ toastRef }: { toastRef: RefObject<Toast> }) {
  const mainApi = useMainApi();
  const { selectedExport } = useContext(ResultsContext) || throwError();

  const [results, setResults] = useState<
    Readonly<{
      data?: {
        exportJournalCount: ExportJournalCount;
      };
      loading?: boolean;
      error?: CommunicationError;
    }>
  >({});

  useEffect(() => {
    (async () => {
      if (!selectedExport) {
        return;
      }

      setResults((results) => {
        return {
          ...results,
          loading: true,
          error: undefined,
        };
      });

      const response = await mainApi.fetchJSON({
        method: "GET",
        path: `/bookings/exports/metrics/get_export/${selectedExport.id}`,
        schema: z.object({
          status: z.literal(200),
          body: ExportJournalCountsSchema,
        }),
      });

      if (response.error) {
        setResults((results) => {
          return {
            ...results,
            loading: false,
            error: response.error,
          };
        });

        toastRef.current?.show({
          severity: "error",
          summary: getCommunicationErrorTitle(response.error),
          detail: getCommunicationErrorMessage(response.error),
        });

        return;
      }

      setResults((results) => {
        return {
          ...results,
          loading: false,
          data: {
            exportJournalCount: response.response.body,
          },
        };
      });
    })();
  }, [mainApi, toastRef, selectedExport]);

  return useMemo(() => {
    return {
      results,
      selectedExport,
    };
  }, [results, selectedExport]);
}

export const ExportDetailContext = createContext<
  ReturnType<typeof useProvider> | undefined
>(undefined);

export function ExportDetailProvider(props: { children: ReactNode }) {
  const toastRef = useRef<Toast>(null);
  const value = useProvider({ toastRef });

  return (
    <ExportDetailContext.Provider value={value}>
      <Toast ref={toastRef} />
      {props.children}
    </ExportDetailContext.Provider>
  );
}
