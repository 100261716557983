import { Controller, UseFormReturn } from "react-hook-form";
import formSections from "./form-sections.module.scss";
import { AccountingClientFormValue } from "../basic-information";
import { AccountingClient } from "../../accountingClientLoader";
import { InputText } from "primereact/inputtext";
import { useCurrentLanguage } from "../../../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../../../language/supported-languages";
import { HEADINGS_CLASS_NAMES } from "../../../../../../../ui/headings";

const GERMAN_TRANSLATIONS = {
  contactInformation: "Kontaktinformationen",
  email: "E-Mail",
  phoneNumber: "Telefonnummer",
  preferredContactMethod: "Bevorzugte Kontaktmethode",
  emailOption: "E-Mail",
  phoneOption: "Telefon",
};

const ENGLISH_TRANSLATIONS = {
  contactInformation: "Contact information",
  email: "Email",
  phoneNumber: "Phone number",
  preferredContactMethod: "Preferred contact method",
  emailOption: "Email",
  phoneOption: "Phone",
};

export function ContactInformation(props: {
  form: UseFormReturn<AccountingClientFormValue>;
  accountingClient: AccountingClient | null;
}) {
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  return (
    <>
      <h3 className={`${formSections.title} ${HEADINGS_CLASS_NAMES.h3}`}>
        {translations.contactInformation}
      </h3>

      <div className={formSections.formField}>
        <Controller
          name="email"
          control={props.form.control}
          render={({ field, fieldState }) => {
            return (
              <>
                <label className={formSections.label} htmlFor={field.name}>
                  {translations.email}
                </label>
                <div className={`${formSections.inputWrapper}`}>
                  <InputText
                    className={`${formSections.input} ${
                      fieldState.error ? "p-invalid" : ""
                    }`}
                    id={field.name}
                    value={field.value}
                    onChange={(e) => field.onChange(e.target.value)}
                    keyfilter={"email"}
                  />
                  {fieldState.error && (
                    <small className={formSections.errorMessage}>
                      {fieldState.error.message}
                    </small>
                  )}
                </div>
              </>
            );
          }}
        />
      </div>
    </>
  );
}
