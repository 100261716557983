import { useRef, useState } from "react";
import { useMainApi } from "../../../../../../../main-api";
import { useAccountingClientId } from "../../../accounting-client-id";
import { z } from "zod";
import {
  getCommunicationErrorMessage,
  getCommunicationErrorTitle,
} from "../../../../../../../communication-errors/communication-error-messages";
import { Controller, UseFormReturn } from "react-hook-form";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { Toast } from "primereact/toast";
import { useCurrentLanguage } from "../../../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../../../language/supported-languages";
import {
  FallbackAccountFormValue,
  GetAccountState,
} from "./get-fallback-account";

const GERMAN_TRANSLATIONS = {
  success: "Erfolg",
  fallbackAccountUpdated: "Standardgegenkonto wurde erfolgreich aktualisiert",
  save: "Speichern",
};

const ENGLISH_TRANSLATIONS = {
  success: "Success",
  fallbackAccountUpdated: "Default counter account is updated successfully",
  save: "Save",
};

export function FallbackAccountForm(props: {
  form: UseFormReturn<FallbackAccountFormValue>;
  afterSubmit: (args: { fallback_account_number: number | null }) => void;
  accountState: GetAccountState;
}) {
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;
  const [isSubmitting, setIsSubmitting] = useState(false);

  const mainApi = useMainApi();
  const accountingClientId = useAccountingClientId();
  const toastRef = useRef<Toast>(null);

  return (
    <>
      <Toast ref={toastRef} />
      <form
        onSubmit={props.form.handleSubmit(
          async (formData: FallbackAccountFormValue) => {
            setIsSubmitting(true);

            const response = await mainApi.fetchJSON({
              path: `/accounts/set_fallback_account?accounting_client_id=${accountingClientId}`,
              method: "POST",
              body: formData,
              schema: z.object({
                status: z.literal(200),
                body: z.object({
                  fallback_account_number: z.number().nullable(),
                }),
              }),
            });

            setIsSubmitting(false);

            if (response.error) {
              toastRef.current?.show({
                severity: "error",
                summary: getCommunicationErrorTitle(response.error),
                detail: getCommunicationErrorMessage(response.error),
              });
              return;
            }

            toastRef.current?.show({
              severity: "success",
              summary: translations.success,
              detail: translations.fallbackAccountUpdated,
            });

            props.afterSubmit({
              fallback_account_number:
                response.response.body.fallback_account_number,
            });
          }
        )}
      >
        <div className="flex w-fit">
          <Controller
            control={props.form.control}
            name="fallback_account_number"
            render={({ field, fieldState }) => (
              <div className="flex flex-col gap-2">
                <InputNumber
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e.value);
                  }}
                  useGrouping={false}
                  className={`${fieldState.error ? "p-invalid" : ""} w-full`}
                />
                {fieldState.error && (
                  <small className="p-error">{fieldState.error.message}</small>
                )}
              </div>
            )}
          />
          <Button
            disabled={isSubmitting || props.accountState.loading}
            loading={isSubmitting || props.accountState.loading}
            type="submit"
            label={translations.save}
            className="h-fit ms-2"
          />
        </div>
      </form>
    </>
  );
}
