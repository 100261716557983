import { Helmet } from "react-helmet-async";

export function Title({
  accountingClientName,
  page,
}: {
  accountingClientName: string;
  page: string;
}) {
  return (
    <Helmet>
      <title>{`${accountingClientName} - ${page} | Kanzlei21`}</title>
    </Helmet>
  );
}
