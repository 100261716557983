import { z } from "zod";
import { createContext, ReactNode, RefObject, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { useMainApi } from "../../../../main-api";
import { CommunicationError } from "../../../../communication-errors/communication-errors";
import { getCommunicationErrorMessage, getCommunicationErrorTitle } from "../../../../communication-errors/communication-error-messages";
import { QuickFilters, QuickFiltersContext } from "../top-section/nav-bar/quick-filters/quick-filters-context";
import { throwError } from "../../../../throw-error";

export const BankConnectionSchema = z.object({
    id: z.string(),
    bank_name: z.string().nullable(),
    bank_logo_url: z.string().nullable(),
    accounts: z.array(z.object({
        account_iban: z.string().nullable(),
        account_name: z.string().nullable(),
    })),
    user_action_required: z.boolean(),
    created_at: z.coerce.date().nullable()
});

export type BankConnection = z.TypeOf<typeof BankConnectionSchema>;

type RequestedParams = {
    quickFilters: QuickFilters;
};

function useProvider({ toastRef }: { toastRef: RefObject<Toast> }) {
    const mainApi = useMainApi();
    const { quickFilters } = useContext(QuickFiltersContext) || throwError();

    const [results, setResults] = useState<
        Readonly<{
            data?: BankConnection[];
            loading?: boolean;
            error?: CommunicationError;
        }>
    >({});

    const [lastRequestParams, setLastRequestParams] = useState<
        RequestedParams | undefined
    >();


    const [selectedBankConnectionId, setSelectedBankConnectionId] = useState<
        string | undefined
    >();

    useEffect(() => {
        (async () => {

            const requestParams: RequestedParams = {
                quickFilters,
            };

            if (JSON.stringify(requestParams) === JSON.stringify(lastRequestParams)) {
                return;
            }

            setLastRequestParams(requestParams);

            const resetResults =
                JSON.stringify({
                    quickFilters: requestParams.quickFilters,
                }) !==
                JSON.stringify({
                    quickFilters: lastRequestParams?.quickFilters,
                });

            if (resetResults) {
                setResults(() => {
                    return {};
                });
            }

            setResults((results) => {
                return {
                    ...results,
                    loading: true,
                    error: undefined,
                };
            });

            const urlSearchParams = new URLSearchParams();

            if (quickFilters.search) {
                urlSearchParams.append("search", quickFilters.search);
            }

            const response = await mainApi.fetchJSON({
                path: `/bank-accounts/finapi?${urlSearchParams.toString()}`,
                method: "GET",
                schema: z.object({
                    status: z.literal(200),
                    body: z.array(BankConnectionSchema),
                }),
            });


            if (response.error) {
                setResults((results) => {
                    return {
                        ...results,
                        loading: false,
                        error: response.error,
                    };
                });

                toastRef.current?.show({
                    severity: "error",
                    summary: getCommunicationErrorTitle(response.error),
                    detail: getCommunicationErrorMessage(response.error),
                });

                return;
            }

            setResults((results) => {
                return {
                    ...results,
                    loading: false,
                    data: response.response.body,
                };
            });
        })();
    }, [lastRequestParams, mainApi, quickFilters, toastRef]);

    const selectedBankConnection = results.data?.find(
        (connection) => connection.id === selectedBankConnectionId
    );

    const bank_connections = results.data?.filter((connection) => !connection.user_action_required)
    const bank_connections_with_update = results.data?.filter((connection) => connection.user_action_required)

    return useMemo(() => {
        return {
            selectedBankConnectionId,
            setSelectedBankConnectionId,
            results,
            setResults,
            selectedBankConnection,
            toastRef,
            bank_connections,
            bank_connections_with_update
        };
    }, [bank_connections, bank_connections_with_update, results, selectedBankConnection, selectedBankConnectionId, toastRef]);
}

export const ResultsContext = createContext<
    ReturnType<typeof useProvider> | undefined
>(undefined);

export function ResultsContextProvider(props: { children: ReactNode }) {
    const toastRef = useRef<Toast>(null);
    const value = useProvider({ toastRef });

    return (
        <ResultsContext.Provider value={value}>
            <Toast ref={toastRef} />
            {props.children}
        </ResultsContext.Provider>
    );
}
