import styles from "./page.module.scss";
import { ResetPassword } from "./reset-password";
import { useCurrentLanguage } from "../../../language/current-language";
import { SupportedLanguage } from "../../../language/supported-languages";
import { Helmet } from "react-helmet-async";

const GERMAN_TRANSLATIONS = {
  accountantSettings: "Account-Einstellungen",
};

const ENGLISH_TRANSLATIONS = {
  accountantSettings: "Accountant Settings",
};

export function AccountantUserSettings() {
  const currentLanguage = useCurrentLanguage();

  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  return (
    <>
      <Helmet>
        <title>{`${translations.accountantSettings} | Kanzlei21`}</title>
      </Helmet>

      <div className={styles.page}>
        <ResetPassword />
      </div>
    </>
  );
}
