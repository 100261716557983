import {
  ReactNode,
  createContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { z } from "zod";
import { useMainApi } from "../../../../../main-api";
import { CommunicationError } from "../../../../../communication-errors/communication-errors";
import { useAccountingClientId } from "../accounting-client-id";
import { Toast } from "primereact/toast";
import { throwError } from "../../../../../throw-error";
import {
  getCommunicationErrorMessage,
  getCommunicationErrorTitle,
} from "../../../../../communication-errors/communication-error-messages";

export const BankAccountSchema = z.object({
  id: z.string(),
  account_id: z.string(),
  account_number: z.number(),
  account_name: z.string(),
});

export const CounterAccountSchema = z.object({
  id: z.string(),
  name: z.string(),
  number: z.number(),
});

export const TaxCodeSchema = z.object({
  id: z.string(),
  tax_code: z.number(),
  description: z.string(),
});

export type BankAccount = z.TypeOf<typeof BankAccountSchema>;

export type CounterAccount = z.TypeOf<typeof CounterAccountSchema>;

export type TaxCode = z.TypeOf<typeof TaxCodeSchema>;

export const BookingsPageResourcesContext = createContext<
  | undefined
  | {
      bank_accounts: BankAccount[];
      counter_accounts: CounterAccount[];
      tax_codes: TaxCode[];
      fallback_account_number: number | null;
    }
>(undefined);

export function BookingsPageResourcesLoader(props: { children: ReactNode }) {
  const toastRef = useRef<Toast | null>(null);

  const mainApi = useMainApi();
  const accountingClientId = useAccountingClientId();

  const [results, setResults] = useState<
    Readonly<{
      data?: {
        bank_accounts: BankAccount[];
        counter_accounts: CounterAccount[];
        tax_codes: TaxCode[];
        fallback_account_number: number | null;
      };
      loading?: boolean;
      error?: CommunicationError;
    }>
  >({});

  useEffect(() => {
    (async () => {
      setResults({ loading: true });

      const res = await mainApi.fetchJSON({
        method: "GET",
        path: `/accounting_clients/${accountingClientId}/bank_bookings/page_resources`,
        schema: z.object({
          status: z.literal(200),
          body: z.object({
            bank_accounts: z.array(BankAccountSchema),
            counter_accounts: z.array(CounterAccountSchema),
            tax_codes: z.array(TaxCodeSchema),
            fallback_account_number: z.number().nullable(),
          }),
        }),
      });

      if (res.error) {
        const toast = toastRef.current ?? throwError();

        toast.show({
          severity: "error",
          summary: getCommunicationErrorTitle(res.error),
          detail: getCommunicationErrorMessage(res.error),
        });
        return;
      }

      setResults({ data: res.response.body });
    })();
  }, [accountingClientId, mainApi, toastRef]);

  const data = useMemo(() => {
    return results.data;
  }, [results]);

  return (
    <>
      <Toast ref={toastRef} />

      {(() => {
        if (results.loading) {
          return "Loading...";
        }

        if (!data) {
          return <></>;
        }

        return (
          <BookingsPageResourcesContext.Provider value={data}>
            {props.children}
          </BookingsPageResourcesContext.Provider>
        );
      })()}
    </>
  );
}
