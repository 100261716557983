import { Actions } from "./actions/actions";
import { Panel } from "primereact/panel";
import {
  Booking,
  BookingSchema,
  FinancialTransaction,
  ResultsContext,
} from "../../results/results-context";
import { Details } from "./details/details";
import { useMainApi } from "../../../../../../../main-api";
import { z } from "zod";
import { useAccountingClientId } from "../../../accounting-client-id";
import { useContext, useEffect } from "react";
import { Toast } from "primereact/toast";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { BookingsPageBankAccountsContext } from "../../top-section/navbar/quick-filters/bank-accounts-context";
import { throwError } from "../../../../../../../throw-error";
import {
  getCommunicationErrorMessage,
  getCommunicationErrorTitle,
} from "../../../../../../../communication-errors/communication-error-messages";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { useCurrentLanguage } from "../../../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../../../language/supported-languages";
import { RelatedAccountsContext } from "../../top-section/navbar/related-accounts";
import { getSplitBookingStatus } from "../../../../../../../bookings/split-bookings/status";
import { Tag } from "primereact/tag";

const GERMAN_TRANSLATIONS = {
  success: "Erfolg",
  splitBookingsUpdatedSuccessfully:
    "Die Splittbuchungen wurden erfolgreich aktualisiert.",
  amountValidationMessage: "Bitte geben Sie einen Wert größer als 0 ein.",
  convertSplitBookingToRegularBooking:
    "Splittbuchung in normale Buchung umwandeln",
  splitBookingInfo:
    "Eine Splittbuchung besteht aus mindestens zwei Teilbuchungen. Wenn Sie nur eine Teilbuchung übrig lassen, wird die Splittbuchung wieder in eine normale Buchung umgewandelt. Dies kann nicht rückgängig gemacht werden.",
  editLegSuggestion:
    "Wenn Sie dies vermeiden möchten, versuchen Sie, die Teilbuchung zu bearbeiten, anstatt sie zu löschen.",
  confirmDeleteLegAndConvert:
    "Teilbuchung löschen und in eine normale Buchung umwandeln?",
  deleteLeg: "Teilbuchung löschen",
  cancel: "Abbrechen",
  rejected: "Abgelehnt",
  rejectMessage: "Änderungen wurden nicht gespeichert",
  splitBooking: "Splittbuchung",
  onlyOneInvoiceNumberCanBeEntered:
    "Es kann nur eine Rechnungsnummer pro Buchung eingegeben werden.",
  actionNeeded: "Handlungsbedarf",
  correctedByAccountant: "Manuell korrigiert",
  manuallyApproved: "Manuell genehmigt",
  processed: "Vollautomatisiert",
  historical: "Historisch",
};

const ENGLISH_TRANSLATIONS = {
  success: "Success",
  splitBookingsUpdatedSuccessfully: "Split bookings updated successfully",
  amountValidationMessage: "Please enter a value greater than 0",
  convertSplitBookingToRegularBooking:
    "Convert split booking to regular booking",
  splitBookingInfo:
    "A split booking is composed of at least two legs. If you leave only one leg, the split booking will be converted back into a normal booking. This cannot be undone.",
  editLegSuggestion:
    "If you want to avoid this, try editing the leg instead of deleting it.",
  confirmDeleteLegAndConvert: "Delete leg and convert to regular booking?",
  deleteLeg: "Delete leg",
  cancel: "Cancel",
  rejected: "Rejected",
  rejectMessage: "Changes were not saved",
  splitBooking: "Split Booking",
  onlyOneInvoiceNumberCanBeEntered:
    "Only one invoice number can be entered per booking",
  actionNeeded: "Action Needed",
  correctedByAccountant: "Corrected by Accountant",
  manuallyApproved: "Manually Approved",
  processed: "Processed",
  historical: "Historical",
};

export const EditSplitBookingFormSchema = (translations: {
  amountValidationMessage: string;
  onlyOneInvoiceNumberCanBeEntered: string;
}) =>
  z.object({
    split_bookings: z.array(
      z.object({
        id: z.string().uuid().nullable(),
        is_debit: z.boolean(),
        amount: z.coerce
          .number()
          .positive({ message: translations.amountValidationMessage }),
        counter_account_id: z.string().uuid().nullable(),
        tax_code_id: z.string().uuid().nullable(),
        invoice_numbers: z.array(z.string()),
      })
    ),
  });

export type EditSplitBookingFormValue = z.infer<
  ReturnType<typeof EditSplitBookingFormSchema>
>;

export function SplitBookingDetailsPanel({
  financialTransaction,
  selectedLegs,
  toastRef,
}: {
  financialTransaction: FinancialTransaction;
  selectedLegs: Booking[];
  toastRef: React.RefObject<Toast>;
}) {
  const currentLanguage = useCurrentLanguage();

  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const mainApi = useMainApi();

  const accountingClientId = useAccountingClientId();

  const { setResults, setSelected } =
    useContext(ResultsContext) || throwError();

  const { refresh } = useContext(RelatedAccountsContext) || throwError();

  const { selectedBankAccount } =
    useContext(BookingsPageBankAccountsContext) || throwError();

  const financialTransactionId = financialTransaction.id;

  const transformSplitBookingLegs = (legs: Booking[]) => {
    if (legs.length === 0) {
      throw new Error();
    }

    if (legs.length !== 1) {
      return legs.map((leg) => ({
        id: leg.id ?? null,
        is_debit: leg.is_debit,
        amount: leg.amount,
        counter_account_id: leg.counter_account?.id || null,
        tax_code_id: leg.tax_code?.id || null,
        invoice_numbers: leg.invoice_numbers,
      }));
    }

    const firstLeg = legs[0] || throwError();

    if (firstLeg.invoice_numbers.length == 0) {
      return [
        {
          id: firstLeg.id ?? null,
          is_debit: firstLeg.is_debit,
          amount: firstLeg.amount,
          counter_account_id: firstLeg.counter_account?.id || null,
          tax_code_id: firstLeg.tax_code?.id || null,
        },
      ];
    }

    return firstLeg.invoice_numbers.map((invoiceNumber) => ({
      id: firstLeg.id ?? null,
      is_debit: firstLeg.is_debit,
      amount: 0,
      counter_account_id: firstLeg.counter_account?.id || null,
      tax_code_id: firstLeg.tax_code?.id || null,
      invoice_numbers: [invoiceNumber],
    }));
  };

  const form = useForm<EditSplitBookingFormValue>({
    resolver: zodResolver(EditSplitBookingFormSchema(translations)),
    defaultValues: {
      split_bookings: transformSplitBookingLegs(selectedLegs),
    },
  });

  useEffect(() => {
    form.reset({
      split_bookings: transformSplitBookingLegs(selectedLegs),
    });
  }, [form, selectedLegs]);

  const onSubmit = async (formData: EditSplitBookingFormValue) => {
    if (!selectedBankAccount) {
      throwError();
    }

    const urlSearchParams = new URLSearchParams();
    urlSearchParams.append(
      "financial_transaction_id",
      financialTransactionId.toString() ?? throwError()
    );

    urlSearchParams.append("accounting_client_id", accountingClientId);
    urlSearchParams.append(
      "selected_account_id",
      selectedBankAccount.account_id
    );

    const res = await mainApi.fetchJSON({
      method: "PUT",
      path: `/bookings/split_bookings/edit?${urlSearchParams.toString()}`,
      body: formData.split_bookings.map((leg) => ({
        id: leg.id || null,
        is_debit: leg.is_debit,
        amount: leg.amount,
        counter_account_id: leg.counter_account_id || null,
        tax_code_id: leg.tax_code_id || null,
        invoice_numbers: leg.invoice_numbers,
      })),
      schema: z.object({
        status: z.literal(200),
        body: z.array(BookingSchema),
      }),
    });

    if (res.error) {
      toastRef.current?.show({
        severity: "error",
        summary: getCommunicationErrorTitle(res.error),
        detail: getCommunicationErrorMessage(res.error),
      });
      return;
    }

    refresh();

    setResults((results) => {
      if (!results.data) return results;

      if (!financialTransactionId) return results;

      const splitBookingLegsBefore = results.data.bookings.filter(
        (b) => b.financial_transaction_id === financialTransactionId
      );

      let bookings = [...results.data.bookings];

      // Remove from state

      const splitBookingLegsToRemoveIds = splitBookingLegsBefore
        .filter(
          (legFromBefore) =>
            !res.response.body.some((leg) => leg.id === legFromBefore.id)
        )
        .map((leg) => leg.id);

      bookings = [
        ...results.data.bookings.filter(
          (b) => !splitBookingLegsToRemoveIds.includes(b.id)
        ),
      ];

      // Update state

      bookings = bookings.map((booking) => {
        const updatedBooking = res.response.body.find(
          (leg) => leg.id === booking.id
        );

        if (updatedBooking) {
          return updatedBooking;
        } else {
          return booking;
        }
      });

      // Add new to state

      const newBookings = res.response.body.filter(
        (leg) => !bookings.some((booking) => booking.id === leg.id)
      );

      bookings = [...bookings, ...newBookings];

      return {
        ...results,
        data: {
          ...results.data,
          bookings: bookings,
        },
      };
    });

    toastRef.current?.show({
      severity: "success",
      summary: translations.success,
      detail: translations.splitBookingsUpdatedSuccessfully,
    });
  };

  const hasHistoricalSplitBookingLegs =
    selectedLegs.filter((leg) => leg.status === "HISTORICAL").length > 0;

  return (
    <>
      <ConfirmDialog
        pt={{
          acceptButton: {
            className: "p-button-warning",
          },
          root: {
            className: "max-w-[40vw]",
          },
        }}
      />
      <form
        onSubmit={form.handleSubmit((formData) => {
          if (formData.split_bookings.length === 1) {
            confirmDialog({
              message: (
                <div className="flex  flex-col gap-2">
                  <span>{translations.splitBookingInfo}</span>
                  <span>{translations.editLegSuggestion}</span>
                  <span className="font-bold">
                    {translations.confirmDeleteLegAndConvert}
                  </span>
                </div>
              ),
              header: translations.convertSplitBookingToRegularBooking,
              acceptLabel: translations.deleteLeg,
              rejectLabel: translations.cancel,
              accept: async () => {
                await onSubmit(formData);
                setSelected(undefined);
              },
              reject: () => {
                toastRef.current?.show({
                  severity: "warn",
                  summary: translations.rejected,
                  detail: translations.rejectMessage,
                });
              },
            });
          } else {
            onSubmit(formData);
          }
        })}
      >
        <Panel
          pt={{
            content: {
              className: "p-0",
            },
          }}
          headerTemplate={() => (
            <div className="w-full flex justify-between items-center p-3">
              <div className={"flex align-center gap-3"}>
                {(() => {
                  const status = getSplitBookingStatus(selectedLegs);

                  return (
                    <Tag
                      className={`${(() => {
                        if (status === "HISTORICAL") {
                          return "bg-historical text-historicalText";
                        } else if (status === "MANUALLY_APPROVED") {
                          return "bg-manuallyApproved text-manuallyApprovedText";
                        }
                      })()}`}
                      severity={(() => {
                        if (status === "ACTION_NEEDED") {
                          return "danger";
                        } else if (status === "CORRECTED_BY_ACCOUNTANT") {
                          return "info";
                        } else if (status === "MANUALLY_APPROVED") {
                          return undefined;
                        } else if (status === "PREDICTED_WITHOUT_ISSUES") {
                          return "success";
                        } else if (status === "HISTORICAL") {
                          return undefined;
                        } else {
                          throw new Error();
                        }
                      })()}
                      value={(() => {
                        if (status === "ACTION_NEEDED") {
                          return translations.actionNeeded;
                        } else if (status === "CORRECTED_BY_ACCOUNTANT") {
                          return translations.correctedByAccountant;
                        } else if (status === "MANUALLY_APPROVED") {
                          return translations.manuallyApproved;
                        } else if (status === "PREDICTED_WITHOUT_ISSUES") {
                          return translations.processed;
                        } else if (status === "HISTORICAL") {
                          return translations.historical;
                        } else {
                          throw new Error();
                        }
                      })()}
                    />
                  );
                })()}

                <b className="text-xl">
                  {translations.splitBooking} -{" "}
                  {financialTransaction.applicant_name ?? <></>}
                </b>
              </div>
              <Actions
                isSubmitting={form.formState.isSubmitting}
                hasHistoricalSplitBookingLegs={hasHistoricalSplitBookingLegs}
              />
            </div>
          )}
        >
          <div>
            <div className={"p-4"}>
              <Details
                financialTransaction={financialTransaction}
                legs={selectedLegs}
                form={form}
                hasHistoricalSplitBookingLegs={hasHistoricalSplitBookingLegs}
              />
            </div>
            {/* <Divider className={styles.divider} layout="vertical" />
            <div className={styles.column2}>
              <MeasureSize>
                {({ height }) => (
                  <div
                    className={`${styles.overflowYAuto}`}
                    style={{
                      maxHeight: height,
                    }}
                  >
                    <Column2 bookings={selectedLegs} />
                  </div>
                )}
              </MeasureSize>
            </div> */}
          </div>
        </Panel>
      </form>
    </>
  );
}
