import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import { ReactNode, useCallback, useEffect, useRef, useState } from "react";
import styles from "./accountants-loader.module.scss";
import { useMainApi } from "../../../../main-api";
import { z } from "zod";
import {
  getCommunicationErrorMessage,
  getCommunicationErrorTitle,
} from "../../../../communication-errors/communication-error-messages";

export const AccountantSchema = z.object({
  id: z.string(),
  name: z.string(),
  email: z.string(),
  permissions: z.object({
    can_add_clients: z.boolean(),
    accounting_firm_admin: z.boolean(),
  }),
});

export type Accountant = z.TypeOf<typeof AccountantSchema>;

export function AccountantsLoader(props: {
  children: (renderProps: {
    accountants: Accountant[];
    onAccountantCreated: (accountant: Accountant) => void;
    onAccountantUpdated: (accountant: Accountant) => void;
  }) => ReactNode;
}) {
  const mainApi = useMainApi();

  const [state, setState] = useState<{
    loading?: boolean;
    data?: Accountant[];
  }>({});

  const toast = useRef<Toast>(null);

  useEffect(() => {
    (async () => {
      const res = await mainApi.fetchJSON({
        method: "GET",
        path: "/accounting_firms/current/accountants",
        schema: z.object({
          status: z.literal(200),
          body: z.object({
            accountants: z.array(AccountantSchema),
          }),
        }),
      });

      if (res.error) {
        toast.current!.show({
          severity: "error",
          summary: getCommunicationErrorTitle(res.error),
          detail: getCommunicationErrorMessage(res.error),
        });
        setState({});
        return;
      }

      setState({
        loading: false,
        data: res.response.body.accountants,
      });
    })();
  }, [mainApi, toast]);

  const onAccountantCreated = useCallback((accountant: Accountant) => {
    setState((state) => {
      if (!state.data) {
        throw Error();
      }

      return { ...state, data: [...state.data, accountant] };
    });
  }, []);

  const onAccountantUpdated = useCallback((accountant: Accountant) => {
    setState((state) => {
      if (!state.data) {
        throw Error();
      }

      return {
        ...state,
        data: state.data.map((a) => (a.id === accountant.id ? accountant : a)),
      };
    });
  }, []);

  if (state.loading) {
    return (
      <div className={styles.loading}>
        <ProgressSpinner />
      </div>
    );
  }

  if (!state.data) {
    return null;
  }

  return (
    <>
      {props.children({
        accountants: state.data,
        onAccountantCreated,
        onAccountantUpdated,
      })}
    </>
  );
}
