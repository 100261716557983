import { ReactNode, createContext, useMemo, useState } from "react";
import { z } from "zod";

export const FiltersSchema = z.object({
  amount_min: z.number().nullable(),
  amount_max: z.number().nullable(),
  booking_type: z.array(z.enum(["debit", "credit"])),
  counter_account: z.string().nullable(),
  status: z.array(
    z.enum([
      "historical",
      "action",
      "corrected",
      "predicted",
      "manually_approved",
    ])
  ),
});

export type Filters = z.infer<typeof FiltersSchema>;

function useProvider() {
  const [filters, setFilters] = useState<Filters>({
    amount_min: null,
    amount_max: null,
    booking_type: [],
    counter_account: null,
    status: [],
  });

  return useMemo(() => {
    return {
      filters,
      setFilters,
    };
  }, [filters]);
}

export const FiltersContext = createContext<
  ReturnType<typeof useProvider> | undefined
>(undefined);

export function FiltersContextProvider(props: { children: ReactNode }) {
  const value = useProvider();

  return (
    <FiltersContext.Provider value={value}>
      {props.children}
    </FiltersContext.Provider>
  );
}
