import { Badge } from "primereact/badge";
import { Tooltip } from "primereact/tooltip";
import { useContext, useMemo, useCallback } from "react";
import { throwError } from "../../../../../../throw-error";
import { Document, ResultsContext } from "./results-context";
import styles from "./results.module.scss";
import { Column } from "react-data-grid";
import { ReactDataGrid } from "../../../../../../react-data-grid";
import { ProgressSpinner } from "primereact/progressspinner";
import { SortColumn as ReactDataGridSortColumn } from "react-data-grid";
import { SortColumn, SortingContext } from "../sorting/sorting-context";
import { useCurrentLanguage } from "../../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../../language/supported-languages";

const dateFormatter = new Intl.DateTimeFormat(undefined, {
  dateStyle: "medium",
});

const GERMAN_TRANSLATIONS = {
  createdAt: "Erstellt am",
  invoiceNumber: "Rechnungsnummer",
  documentName: "Dokumentname",
  total: "Gesamt",
  clientSourceName: "Quellname des Mandanten",
  clientDestinationName: "Zielname des Mandanten",
  ibanList: "IBAN-Liste",
  issues: "Probleme",
  loadingMore: "Lade weitere Zeilen...",
  reviewNeededIssueType: "Überprüfung erforderlich",
  invalidIbansIssueType: "Ungültige IBANs",
};

const ENGLISH_TRANSLATIONS = {
  createdAt: "Created at",
  invoiceNumber: "Invoice Number",
  documentName: "Document name",
  total: "Total",
  clientSourceName: "Client Source Name",
  clientDestinationName: "Client Destination Name",
  ibanList: "IBAN List",
  issues: "Issues",
  loadingMore: "Loading more rows...",
  reviewNeededIssueType: "Review needed",
  invalidIbansIssueType: "Invalid IBANs",
};

export function Results() {
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const { results, setSelectedDocumentId, setOffset } =
    useContext(ResultsContext) || throwError();

  const sorting = useContext(SortingContext) || throwError();

  const getRowKey = useCallback(
    (r: Partial<Document>) => r.id || throwError(),
    []
  );

  const onSortColumnsChange = useCallback(
    (sortColumns: ReactDataGridSortColumn[]) => {
      const sortColumn = sortColumns[0];
      sorting.setSortColumn(sortColumn as SortColumn | undefined);
    },
    [sorting]
  );

  const sortColumns = useMemo(() => {
    return sorting.sortColumn ? [sorting.sortColumn] : [];
  }, [sorting]);

  const columns: Column<Document>[] = useMemo(() => {
    const _columns: Column<Document>[] = [
      {
        key: "created_at",
        name: translations.createdAt,
        resizable: true,
        sortable: true,
        renderCell: (args) => {
          const value = new Date(args.row.created_at);
          return dateFormatter.format(value);
        },
      },
      {
        key: "document_name",
        name: translations.documentName,
        resizable: true,
        sortable: true,
        renderCell: (args) => {
          const value = args.row.name;
          return value;
        },
      },
      {
        key: "invoice_number",
        name: translations.invoiceNumber,
        resizable: true,
        renderCell: (args) => {
          const value =
            args.row.document_data.general_information.invoice_number;
          return value;
        },
      },
      {
        key: "total_amount",
        name: translations.total,
        resizable: true,
        sortable: true,
        renderCell: (args) => {
          const value = args.row.document_data.general_information.total_amount;
          if (typeof value === "number") {
            return `€ ${value.toFixed(2)}`;
          }
          return null;
        },
      },
      {
        key: "client_source_name",
        name: translations.clientSourceName,
        resizable: true,
        renderCell: (args) => {
          const value =
            args.row.document_data.general_information.client_source_name;
          return value;
        },
      },
      {
        key: "client_destination_name",
        name: translations.clientDestinationName,
        resizable: true,
        renderCell: (args) => {
          const value =
            args.row.document_data.general_information.client_destination_name;
          return value;
        },
      },
      {
        key: "iban_list",
        name: translations.ibanList,
        width: 80,
        cellClass: (row) => {
          const uniqueClass = `iban-list-cell-${row.id}`;
          return `${uniqueClass} ${styles.ibanListCell}`;
        },
        renderCell: (args) => {
          const uniqueClass = `iban-list-cell-${args.row.id}`;

          const ibanList = args.row.document_data.general_information.iban_list;
          return (
            <>
              <Tooltip position={"left"} target={`.${uniqueClass}`}>
                <ul className="list-disc list-inside">
                  {ibanList.map((iban, index) => (
                    <li key={`${args.row.id}-${iban.iban}-${index}`}>
                      <p className={styles.issueDescription}>{iban.iban}</p>
                    </li>
                  ))}
                </ul>
              </Tooltip>
              <Badge value={ibanList.length} />
            </>
          );
        },
      },
      {
        key: "issues",
        name: translations.issues,
        frozen: true,
        width: 70,
        cellClass: (row) => {
          const uniqueClass = `issues-cell-${row.id}`;
          return `${uniqueClass} ${styles.issuesCell}`;
        },
        renderCell: (args) => {
          const uniqueClass = `issues-cell-${args.row.id}`;
          const openIssues = args.row.issues;
          if (openIssues.length === 0) {
            return <></>;
          }
          return (
            <>
              <Tooltip position={"left"} target={`.${uniqueClass}`}>
                <ul className="list-disc list-inside">
                  {openIssues.map((i) => {
                    if (i.type === "review_needed") {
                      return (
                        <li key={i.id}>
                          <p className={styles.issueDescription}>
                            {translations.reviewNeededIssueType}:{" "}
                            {i.description}
                          </p>
                        </li>
                      );
                    } else if (i.type === "invalid_ibans") {
                      return (
                        <li key={i.id}>
                          <p className={styles.issueDescription}>
                            {translations.invalidIbansIssueType}
                          </p>
                        </li>
                      );
                    } else {
                      throw new Error();
                    }
                  })}
                </ul>
              </Tooltip>
              <Badge value={openIssues.length} severity="danger" />
            </>
          );
        },
      },
    ];

    return _columns;
  }, [translations]);

  const data = results.data;

  if (results.loading && !data) {
    return (
      <div className={styles.loadingContainer}>
        <ProgressSpinner />
      </div>
    );
  }

  if (!data) {
    return <></>;
  }

  return (
    <div className={styles.container}>
      <ReactDataGrid
        className={styles.table}
        rows={results.data.rows}
        columns={columns}
        rowKeyGetter={getRowKey}
        onSelectedCellChange={(args) => {
          if (!args.row) {
            return;
          }

          setSelectedDocumentId(args.row.id);
        }}
        onWheelAndBottom={() => {
          setOffset(data.rows.length);
        }}
        sortColumns={sortColumns}
        onSortColumnsChange={onSortColumnsChange}
      />

      {results.loading && (
        <div className={styles.loadingMore}>{translations.loadingMore}</div>
      )}
    </div>
  );
}
