import { useRef } from "react";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { Message } from "primereact/message";
import styles from "./export-success-message.module.scss";
import { useMainApi } from "../../../../../../../../../../main-api";
import { getCommunicationErrorMessage } from "../../../../../../../../../../communication-errors/communication-error-messages";
import { useCurrentLanguage } from "../../../../../../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../../../../../../language/supported-languages";
import { Button } from "primereact/button";

const GERMAN_TRANSLATIONS = {
  exportSuccessful: "Export erfolgreich!",
  aFileCalled: "Eine Datei mit dem Namen",
  hasDownloaded:
    "wurde erstellt und sollte automatisch auf Ihren Computer heruntergeladen werden.",
  bookingsExported: "Buchungen erfolgreich exportiert.",
  downloadXmlTemplate: "Kanzlei21 XML Vorlage herunterladen",
  downloadXmlInstructionPart1: "Laden Sie die ",
  downloadXmlInstructionPart2:
    " hier herunter, um den Datenimport in Edrewe zu vereinfachen. Dieser Schritt ist optional, vereinfacht jedoch den Importprozess in Edrewe. Die Vorlage muss nur einmal heruntergeladen werden. Wenn Sie dies bereits getan haben, müssen Sie es nicht erneut tun.",
  download: "Herunterladen",
};

const ENGLISH_TRANSLATIONS = {
  exportSuccessful: "Export successful!",
  aFileCalled: "A file called",
  hasDownloaded:
    "has been created and should be downloaded into your computer automatically.",
  bookingsExported: "Bookings exported successfully.",
  downloadXmlTemplate: "Download Kanzlei21 XML Template",
  downloadXmlInstructionPart1: "Download the ",
  downloadXmlInstructionPart2:
    " template file here to simplify data import into Edrewe. This step is optional, but simplifies the import process into Edrewe. The template only needs to be downloaded once. If you have done this before, you don't need to do it again",
  download: "Download",
};

export function ExportSuccessMessageDialog({
  visible,
  onHide,
  filename,
}: {
  visible: boolean;
  onHide: () => void;
  filename: string;
}) {
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const mainApi = useMainApi();

  const toastRef = useRef<Toast>(null);

  const onClick = async () => {
    const res = await mainApi.fetch(
      `/bookings/exports/bookings_xml_template`,
      [200],
      { method: "GET" }
    );

    if (res.error) {
      toastRef.current?.show({
        severity: "error",
        summary: "Error",
        detail: getCommunicationErrorMessage(res.error),
      });
      return;
    }

    const blob = await res.response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "Kanzlei21_XML_Vorlage.xml";
    a.click();

    window.URL.revokeObjectURL(url);
  };
  return (
    <>
      <Toast ref={toastRef} />
      <Dialog
        header={() => (
          <>
            <span className={styles.bigText}>
              {translations.exportSuccessful}
            </span>
          </>
        )}
        visible={visible}
        style={{ width: "45vw" }}
        onHide={onHide}
      >
        <div className={styles.dialogContainer}>
          <span>
            {translations.aFileCalled} <b>{filename}</b>{" "}
            {translations.hasDownloaded}
          </span>
          <Message
            severity="contrast"
            content={
              <div className={styles.flexContainer}>
                <b>{translations.downloadXmlTemplate}</b>
                <div>
                  {translations.downloadXmlInstructionPart1}
                  <b>Kanzlei21_XML_Vorlage.xml</b>
                  {translations.downloadXmlInstructionPart2}
                </div>
                <Button
                  severity={"info"}
                  icon="pi pi-download"
                  label={translations.download}
                  onClick={onClick}
                  className={styles.button}
                />
              </div>
            }
          />
        </div>
      </Dialog>
    </>
  );
}
