import { DocumentDataScreen } from "./document-data-screen/document-data-screen";
import { Results } from "./results/results";
import { ResultsContextProvider } from "./results/results-context";
import { QuickFiltersContextProvider } from "./top-section/navbar/quick-filters/quick-filters-context";
import { TopSection } from "./top-section/top-section";
import styles from "./page.module.scss";
import { ReactNode, useContext } from "react";
import { SortingContextProvider } from "./sorting/sorting-context";
import { FiltersContextProvider } from "./top-section/navbar/filters/filters-context";
import { useCurrentLanguage } from "../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../language/supported-languages";
import { Title } from "../../title";
import { AccountingClientsContext } from "../../../menu/accounting-clients-context";
import { throwError } from "../../../../../throw-error";

const GERMAN_TRANSLATIONS = {
  documents: "Dokumente",
};

const ENGLISH_TRANSLATIONS = {
  documents: "Documents",
};

function Page(props: { children: ReactNode }) {
  return <div className={styles.page}>{props.children}</div>;
}

export function DocumentsPage() {
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const { selectedAccountingClient } =
    useContext(AccountingClientsContext) || throwError();

  return (
    <>
      {selectedAccountingClient && (
        <Title
          accountingClientName={selectedAccountingClient.name}
          page={translations.documents}
        />
      )}
      <QuickFiltersContextProvider>
        <FiltersContextProvider>
          <SortingContextProvider>
            <ResultsContextProvider>
              <Page>
                <TopSection />
                <Results />
                <DocumentDataScreen />
              </Page>
            </ResultsContextProvider>
          </SortingContextProvider>
        </FiltersContextProvider>
      </QuickFiltersContextProvider>
    </>
  );
}
