import { useContext, useRef } from "react";
import { ResultsContext } from "../results/results-context";
import { throwError } from "../../../../../../throw-error";
import { BookingsDetailsPanel } from "./booking-details-panel/booking-details-panel";
import { SplitBookingDetailsPanel } from "./split-booking-details-panel/split-booking-details-panel";
import { Toast } from "primereact/toast";
import { SplitBookingLegDetailsPanel } from "./split-booking-leg-details-panel/split-booking-leg-details-panel";

export function DetailsPanel(props: { className: string }) {
  const { selected } = useContext(ResultsContext) || throwError();

  const toastRef = useRef<Toast>(null);
  return (
    <>
      <Toast ref={toastRef} />
      {(() => {
        if (selected?.type === "transaction") {
          return (
            <div className={props.className}>
              <SplitBookingDetailsPanel
                selectedLegs={selected.bookings}
                financialTransaction={selected.financialTransaction}
                toastRef={toastRef}
              />
            </div>
          );
        } else if (selected?.type === "split-booking-leg") {
          return (
            <div className={props.className}>
              <SplitBookingLegDetailsPanel
                selectedBooking={selected.booking}
                toastRef={toastRef}
              />
            </div>
          );
        } else if (selected?.type === "booking") {
          return (
            <div className={props.className}>
              <BookingsDetailsPanel selectedBooking={selected.booking} />
            </div>
          );
        }

        return null;
      })()}
    </>
  );
}
