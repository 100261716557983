import { QuickFilters } from "./quick-filters/quick-filters";
import styles from "./navbar.module.scss";
import { useCurrentLanguage } from "../../../../language/current-language";
import { SupportedLanguage } from "../../../../language/supported-languages";
import { HEADINGS_CLASS_NAMES } from "../../../../ui/headings";

const GERMAN_TRANSLATIONS = {
  metrics: "Kennzahlen",
};

const ENGLISH_TRANSLATIONS = {
  metrics: "Metrics",
};

export function Navbar() {
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  return (
    <div className={styles.page}>
      <h2 className={`${styles.title} ${HEADINGS_CLASS_NAMES.h2}`}>
        {translations.metrics}
      </h2>
      <QuickFilters />
    </div>
  );
}
