import { useState, useRef, useEffect, useMemo, useContext } from "react";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import styles from "./basic-information.module.scss";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import {
  AccountingClient,
  AccountingClientSchema,
  FormOptions,
} from "../accountingClientLoader";
import { useMainApi } from "../../../../../../main-api";
import {
  getCommunicationErrorMessage,
  getCommunicationErrorTitle,
} from "../../../../../../communication-errors/communication-error-messages";
import { ClientData } from "./form-sections/client-data";
import { useCurrentLanguage } from "../../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../../language/supported-languages";
import { ContactInformation } from "./form-sections/contact-information";
import { AccountingClientsContext } from "../../../../menu/accounting-clients-context";
import { throwError } from "../../../../../../throw-error";
import { HEADINGS_CLASS_NAMES } from "../../../../../../ui/headings";
import { Title } from "../../../title";

const GERMAN_TRANSLATIONS = {
  save: "Speichern",
  success: "Erfolg",
  successDetail: "Buchhaltungsmandant erfolgreich gespeichert",
  duplicateClientNumber: "Doppelte Mandantennummer",
  duplicateClientNumberDetail:
    "Ein Mandant mit dieser Nummer existiert bereits",
  duplicateEmailAddress: "Doppelte E-Mail-Adresse",
  duplicateEmailAddressDetail:
    "Ein Mandant mit dieser E-Mail existiert bereits.",
  duplicateVatId: "Doppelte USt-IdNr.",
  duplicateVatIdDetail: "Ein Kunde mit dieser USt-IdNr. existiert bereits.",
  invalidResponse: "Ungültige Antwort",
  invalidResponseDetails: "Der Server hat eine ungültige Antwort zurückgegeben",
  clientNumberRequired: "Mandantennummer ist erforderlich",
  nameRequired: "Name ist erforderlich",
  emailRequired: "E-Mail ist erforderlich",
  emailInvalid: "Ungültige E-Mail-Adresse",
  phoneNumberRequired: "Telefonnummer ist erforderlich",
  vatIdIsTaken:
    "Ein Mandant mit dem gleichen Umsatzsteuer-Identifikationsnummer existiert bereits",
  selectSystemOfAccounts: "Bitte wählen Sie einen Kontenrahmen aus",
  invalidEmail: "Ungültige E-Mail-Adresse",
  vatIdRequired: "USt-IdNr. ist erforderlich",
  clientInformation: "Mandanteninformationen",
};

const ENGLISH_TRANSLATIONS = {
  save: "Save",
  success: "Success",
  successDetail: "Accounting client saved successfully",
  duplicateClientNumber: "Duplicate client number",
  duplicateClientNumberDetail: "A client with this number already exists",
  duplicateVatId: "Duplicate VAT ID",
  duplicateVatIdDetail: "A client with this VAT ID already exists",
  duplicateEmailAddress: "Duplicate email address",
  duplicateEmailAddressDetail:
    "A client with this email address already exists",
  invalidResponse: "Invalid Response",
  invalidResponseDetails: "The server returned an invalid response. ",
  clientNumberRequired: "Client number is required",
  nameRequired: "Name is required",
  emailRequired: "Email is required",
  emailInvalid: "Invalid email address",
  phoneNumberRequired: "Phone number is required",
  vatIdIsTaken: "A client with the same VAT ID already exists",
  selectSystemOfAccounts: "Please select a system of accounts type",
  invalidEmail: "Invalid email address",
  vatIdRequired: "VAT ID is required",
  clientInformation: "Client Information",
};

export const AccountingClientFormSchema = (
  translations: typeof ENGLISH_TRANSLATIONS
) =>
  z.object({
    client_number: z.string().min(1, translations.clientNumberRequired),
    name: z.string().min(1, translations.nameRequired),
    system_of_accounts: z.string().uuid(translations.selectSystemOfAccounts),
    email: z.string().email(translations.invalidEmail).nullable(),
    vat_id: z.string().min(1, translations.vatIdRequired),
  });

export type AccountingClientFormValue = z.infer<
  ReturnType<typeof AccountingClientFormSchema>
>;

function BasicInformation(props: {
  formOptions: FormOptions;
  accountingClient: AccountingClient;
  setAccountingClient: (accountingClient: AccountingClient) => void;
}) {
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const toastRef = useRef<Toast | null>(null);

  const mainApi = useMainApi();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { refreshAccountingClients } =
    useContext(AccountingClientsContext) || throwError();

  const defaultValues = useMemo(() => {
    return {
      client_number: props.accountingClient.client_number,
      name: props.accountingClient.name,
      system_of_accounts: props.accountingClient.system_of_accounts,
      email: props.accountingClient.email,
      vat_id: props.accountingClient.vat_id ?? undefined,
    };
  }, [props.accountingClient]);

  const form = useForm<AccountingClientFormValue>({
    resolver: zodResolver(AccountingClientFormSchema(translations)),
    defaultValues: defaultValues,
  });

  useEffect(() => {
    form.reset(defaultValues);
  }, [form, defaultValues]);

  const onSubmit = async (formData: AccountingClientFormValue) => {
    setIsSubmitting(true);

    const res = await mainApi.fetchJSON({
      method: "PUT",
      path: `/accounting_clients/${props.accountingClient.id}`,
      body: formData,
      schema: z.union([
        z.object({
          status: z.literal(409),
          body: z.object({
            detail: z.union([
              z.literal("client-number-is-taken"),
              z.literal("email-is-taken"),
              z.literal("vat-id-is-taken"),
            ]),
          }),
        }),
        z.object({
          status: z.literal(200),
          body: AccountingClientSchema,
        }),
      ]),
    });

    setIsSubmitting(false);

    if (res.error) {
      toastRef.current?.show({
        severity: "error",
        summary: getCommunicationErrorTitle(res.error),
        detail: getCommunicationErrorMessage(res.error),
      });
      return;
    }

    if (res.response.status === 409) {
      let summary: string;
      let detail: string;

      if (res.response.body.detail === "client-number-is-taken") {
        summary = translations.duplicateClientNumber;
        detail = translations.duplicateClientNumberDetail;
      } else if (res.response.body.detail === "email-is-taken") {
        summary = translations.duplicateEmailAddress;
        detail = translations.duplicateEmailAddressDetail;
      } else if (res.response.body.detail === "vat-id-is-taken") {
        summary = translations.vatIdIsTaken;
        detail = translations.vatIdIsTaken;
      } else {
        throw new Error();
      }

      toastRef.current?.show({
        severity: "error",
        summary,
        detail,
      });
      return;
    }

    toastRef.current?.show({
      severity: "success",
      summary: translations.success,
      detail: translations.successDetail,
    });

    props.setAccountingClient(res.response.body);

    refreshAccountingClients();
  };

  return (
    <>
      <Title
        accountingClientName={props.accountingClient.name}
        page={translations.clientInformation}
      />
      <div className={styles.page}>
        <Toast ref={toastRef} />
        <div className={styles.formSection}>
          <h2 className={HEADINGS_CLASS_NAMES.h2}>
            {translations.clientInformation}
          </h2>
        </div>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className={styles.formSection}>
            <ClientData
              accountingClient={props.accountingClient}
              system_of_accounts_types={
                props.formOptions.system_of_accounts_types
              }
              form={form}
            />
          </div>
          <div className={styles.formSection}>
            <ContactInformation
              form={form}
              accountingClient={props.accountingClient}
            />
          </div>
          <div className={styles.formSection}>
            <Button
              type="submit"
              className={styles.submit}
              label={translations.save}
              disabled={isSubmitting}
              loading={isSubmitting}
            />
          </div>
        </form>
      </div>
    </>
  );
}

export default BasicInformation;
