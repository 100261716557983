import styles from "./document-preview.module.scss";
import { Document } from "../../results/results-context";
import { useCurrentLanguage } from "../../../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../../../language/supported-languages";
import { useRef } from "react";
import { MAIN_API_URL } from "../../../../../../../main-api";
import { Toast } from "primereact/toast";
import { Image } from "primereact/image";
import { useAccountingClientId } from "../../../accounting-client-id";

const GERMAN_TRANSLATIONS = {
  documentPreview: "Dokumentvorschau",
};

const ENGLISH_TRANSLATIONS = {
  documentPreview: "Document Preview",
};

export function DocumentPagesPreview({ document }: { document: Document }) {
  const accountingClientId = useAccountingClientId();

  return (
    <div className={styles.pagesContainer}>
      {document.pages.map((page) => (
        <Image
          key={page.id}
          src={`${MAIN_API_URL}/documents/previewPageForAccountant?accountingClientId=${accountingClientId}&documentId=${document.id}&pageId=${page.id}`}
          alt={`Page ${page.id}`}
          preview
          className={styles.pageImage}
          imageClassName={styles.pageImage}
        />
      ))}
    </div>
  );
}

export function DocumentIframePreview({ document }: { document: Document }) {
  const accountingClientId = useAccountingClientId();

  return (
    <iframe
      src={`${MAIN_API_URL}/documents/previewForAccountant?accountingClientId=${accountingClientId}&documentId=${document.id}`}
      width="100%"
      height="100%"
    />
  );
}

export function DocumentPreview({
  selectedDocument,
}: {
  selectedDocument: Document;
}) {
  const toastRef = useRef<Toast>(null);
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  return (
    <>
      <Toast ref={toastRef} />
      <b className={styles.fontSize1_25Rem}>{translations.documentPreview}</b>
      {(() => {
        if (selectedDocument.file_upload_blob_path === null) {
          return <DocumentPagesPreview document={selectedDocument} />;
        }
        return <DocumentIframePreview document={selectedDocument} />;
      })()}
    </>
  );
}
