import { BottomSection } from "./bottom-section/bottom-section";
import TopSection from "./top-section/top-section";


export function ClientHomePage() {
  return (
    <>
      <TopSection />
      <BottomSection />
    </>
  );
}
