import { Card } from "primereact/card";
import styles from "./policies.module.scss";
import Markdown from "react-markdown";
import { HEADINGS_CLASS_NAMES } from "../../../ui/headings";
import { useCurrentLanguage } from "../../../language/current-language";
import { SupportedLanguage } from "../../../language/supported-languages";
import { Helmet } from "react-helmet-async";

const GERMAN_PRIVACY_POLICY = `# Datenschutzerklärung
  
**Gültig ab:** 24.10.2024  
**Letzte Aktualisierung:** 20.01.2025

Willkommen bei Kanzlei21. Wir schätzen Ihre Privatsphäre und verpflichten uns, Ihre personenbezogenen Daten mit größter Sorgfalt zu behandeln und vollständig mit den geltenden Gesetzen und Vorschriften, einschließlich der Datenschutz-Grundverordnung (DSGVO), übereinzustimmen.

## 1. Informationen, die wir sammeln

Wir erfassen verschiedene Arten personenbezogener Daten, um unsere Dienstleistungen bereitzustellen und zu verbessern. Dazu gehören:

* Kontoinformationen  
* Nutzungsdaten  
* Kommunikationsdaten  
* Transaktionsdaten  
* Cookies und Tracking-Technologien  
* Fehlerprotokolle und Sitzungsaufzeichnungen

Zusätzlich erfassen wir:

* **Banktransaktionsdaten:** Soweit uns Nutzer die notwendigen Berechtigungen erteilen, erfassen wir Banktransaktionsdaten, um die Kernfunktionen unserer Dienstleistungen zu ermöglichen.  
* **Steuer- und Buchhaltungsdaten:** Soweit autorisierte Steuerberatungs- und/oder Buchhaltungsunternehmen uns diese Informationen zur Verfügung stellen, erfassen wir Steuer- und Buchhaltungsdaten, um eine nahtlose Integration und Berichterstattung zu gewährleisten.  
* **Dokumente:** Dazu gehören Belege, Rechnungen, Verträge und andere ähnliche Dokumente, die von unseren Nutzern (einschließlich autorisierter Steuerberatungs- und Buchhaltungsunternehmen) auf unsere Plattform hochgeladen werden.

## 2. Wie wir Ihre Informationen verwenden

Die gesammelten Daten nutzen wir zu folgenden Zwecken:

* **Bereitstellung von Dienstleistungen:** Um die Kernfunktionen unserer Plattform bereitzustellen, einschließlich der Buchhaltungsunterstützung durch das autorisierte Steuerberatungs- oder Buchhaltungsunternehmen, sowie der Rückkommunikation der Ergebnisse an den Nutzer.  
* **Verbesserung der Benutzererfahrung:** Um Nutzungsmuster zu analysieren, Probleme zu beheben und die Gesamtleistung und Benutzerfreundlichkeit unserer App zu verbessern.  
* **Verbesserung von Algorithmen:** Um die Kernfunktionen unserer Plattform durch die Verwendung anonymisierter Daten zu optimieren.  
* **Kommunikation:** Um auf Anfragen zu reagieren, Kundensupport zu leisten und Updates zu unseren Dienstleistungen oder Ihrem Konto zu senden.  
* **Rechtliche Einhaltung:** Um rechtlichen Verpflichtungen, wie Steuer- und Buchhaltungsanforderungen, nachzukommen und die Einhaltung geltender Vorschriften sicherzustellen.

## 3. Rechtsgrundlagen für die Verarbeitung personenbezogener Daten

Wir verarbeiten Ihre personenbezogenen Daten auf der Grundlage folgender Rechtsgrundlagen:

* **Einwilligung:** Wenn Sie ausdrücklich in bestimmte Verarbeitungsaktivitäten einwilligen, wie den Zugriff auf Banktransaktionen oder die Verarbeitung von Buchhaltungsinformationen.  
* **Vertragliche Verpflichtungen:** Wenn die Verarbeitung notwendig ist, um einen Vertrag mit Ihnen zu erfüllen oder Maßnahmen vor Vertragsschluss zu ergreifen.  
* **Berechtigte Interessen:** Wenn die Verarbeitung für unsere berechtigten Interessen erforderlich ist, z. B. zur Verbesserung unserer Dienstleistungen, sofern diese Interessen nicht Ihre Rechte und Freiheiten gemäß DSGVO überschreiben.  
* **Rechtliche Verpflichtungen:** Wenn die Verarbeitung erforderlich ist, um gesetzlichen oder behördlichen Verpflichtungen nachzukommen.

## 4. Weitergabe Ihrer Informationen

Wir verkaufen oder geben Ihre personenbezogenen Daten nicht zu Marketingzwecken an Dritte weiter. Ihre Daten werden nur im notwendigen Umfang für die Bereitstellung unserer Dienstleistungen und mit vertrauenswürdigen Drittanbietern geteilt, die für unsere Betriebsabläufe unerlässlich sind. Konkret teilen wir Ihre Daten mit:

* **FinAPI:** Um sicher und geschützt auf Ihre Banktransaktionen zuzugreifen und diese zu verarbeiten. Sie können deren Datenschutzerklärung hier einsehen: [FinAPI Datenschutzrichtlinie](https://www.finapi.io/en/data-protection-policy/).  
* **Sentry:** Um die Nutzung unserer App zu überwachen und zu verstehen, wodurch wir deren Leistung und Benutzererfahrung verbessern können. Die Datenschutzerklärung von Sentry finden Sie hier: [Sentry Datenschutzrichtlinie](https://sentry.io/privacy/).  
* **Autorisierte Steuerberatungsunternehmen:** Wenn Sie eine Steuerberatungskanzlei autorisiert haben, Ihre Daten zu nutzen, teilen wir relevante Informationen mit diesem, um Buchhaltungs- und Steuerberatungsdienste zu erleichtern.  
* **Cloud-Anbieter:** Wir nutzen Cloud-Dienste für die Speicherung und Datenverarbeitung unter Einhaltung bewährter Sicherheitspraktiken, um die Sicherheit Ihrer Daten zu gewährleisten.

Alle Drittanbieter, mit denen wir zusammenarbeiten, sind an strenge Vertraulichkeitsvereinbarungen und Datenschutzstandards gebunden, um sicherzustellen, dass Ihre Informationen sicher bleiben und ausschließlich für die vorgesehenen Zwecke verwendet werden.

## 5. Aufbewahrung von Daten

Wir speichern Ihre personenbezogenen Daten nur so lange, wie es notwendig ist, um die in dieser Richtlinie beschriebenen Zwecke zu erfüllen, oder wie es gesetzlich vorgeschrieben ist. Die Aufbewahrungsfristen werden basierend auf der Art der Daten und dem Zweck ihrer Erfassung festgelegt. Nach Ablauf der Frist löschen oder anonymisieren wir Ihre Daten sicher in Übereinstimmung mit den gesetzlichen Anforderungen.

## 6. Ihre Rechte

Unter der DSGVO haben Sie folgende Rechte bezüglich Ihrer personenbezogenen Daten:

* **Zugriff:** Das Recht, eine Bestätigung darüber zu erhalten, ob wir Ihre personenbezogenen Daten verarbeiten, sowie Zugriff auf diese Daten.  
* **Berichtigung:** Das Recht, die Berichtigung unrichtiger oder unvollständiger personenbezogener Daten zu verlangen.  
* **Löschung:** Das Recht, die Löschung Ihrer personenbezogenen Daten zu verlangen, vorbehaltlich rechtlicher und vertraglicher Verpflichtungen.  
* **Einschränkung:** Das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten unter bestimmten Umständen zu verlangen.  
* **Datenübertragbarkeit:** Das Recht, Ihre personenbezogenen Daten in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten und sie an einen anderen Verantwortlichen zu übertragen.  
* **Widerspruch:** Das Recht, der Verarbeitung Ihrer personenbezogenen Daten für bestimmte Zwecke, einschließlich Direktmarketing, zu widersprechen.

Um eines dieser Rechte auszuüben, kontaktieren Sie uns bitte über die in dieser Richtlinie angegebenen Kontaktdaten. Wir werden auf Ihre Anfragen gemäß den geltenden gesetzlichen Anforderungen reagieren.

## 7. Einwilligung der Nutzer zur Verarbeitung von Finanzdaten

Durch die Nutzung unserer Dienstleistungen stimmen Sie ausdrücklich zu, dass Ihr Steuerberater (oder Buchhalter) Ihre GDPdU-Daten hochlädt und Ihre Banktransaktionen, hochgeladene Dokumente (wie Rechnungen, Belege usw.) sowie die aus diesen Dokumenten extrahierten Informationen für Buchhaltungs- und Steuerberatungszwecke verarbeitet. Diese Zustimmung ist erforderlich, um die vertraglichen Verpflichtungen der Dienstleistungen zu erfüllen.

## 8. Sicherheit

Wir verwenden geeignete technische und organisatorische Maßnahmen, um Ihre personenbezogenen Daten vor unbefugtem Zugriff, Missbrauch oder Verlust zu schützen. Diese Maßnahmen umfassen Verschlüsselung, Zugriffskontrollen, regelmäßige Sicherheitsüberprüfungen und Schulungen des Personals, um ein höchstes Maß an Datenschutz sicherzustellen. Trotz dieser Bemühungen sind Sicherheitsmaßnahmen nicht unfehlbar, weshalb wir keine absolute Sicherheit Ihrer Daten garantieren können.

## 9. Cookies und Tracking-Technologien

Wir verwenden Cookies und ähnliche Technologien, um Sie in unserer Anwendung zu authentifizieren, um Ihre Benutzererfahrung zu verbessern und um die Leistung der Plattform zu analysieren. Sie können Ihre Cookie-Einstellungen über die Einstellungen Ihres Browsers verwalten.

## 10. Meldung von Datenschutzverletzungen

Im Falle einer Datenschutzverletzung werden wir betroffene Kontoinhaber und die zuständigen Aufsichtsbehörden unverzüglich und in Übereinstimmung mit der DSGVO informieren.

## 11. Internationale Datenübertragungen

Alle unsere Speicherserver befinden sich hauptsächlich in Deutschland und höchstens in anderen Ländern des Europäischen Wirtschaftsraums (EWR). Somit halten wir uns strikt an die GoBD und die DSGVO. Ihre personenbezogenen Daten werden nicht außerhalb des EWR übertragen.

## 12. Änderungen dieser Richtlinie

Wir können diese Datenschutzerklärung von Zeit zu Zeit aktualisieren. Alle Änderungen werden auf dieser Seite veröffentlicht.

## 13. Drittanbieter-Links

Unser System kann Links oder Integrationen mit Drittanbietern enthalten, die für die Bereitstellung unserer Dienstleistungen oder die Verbesserung der Benutzererfahrung notwendig sind (zum Beispiel FinAPI). Wir stellen sicher, dass solche Drittanbieter-Links oder Integrationen strenge Sicherheitsstandards und geltende Datenschutzvorschriften einhalten, um Ihre Daten zu schützen und die Einhaltung zu gewährleisten.

## 14. Kontaktinformationen

Wenn Sie Fragen zu dieser Datenschutzerklärung oder dazu haben, wie wir Ihre personenbezogenen Daten handhaben, kontaktieren Sie uns bitte unter:

**E-Mail:** support@kanzlei21.com`;

const ENGLISH_PRIVACY_POLICY = `# Privacy Policy

**Effective Date**: 24.10.2024  
**Last Updated**: 20.01.2025  
Welcome to Kanzlei21. We value your privacy and are committed to ensuring that your personal data is handled with the utmost care, in full compliance with applicable laws and regulations, including the General Data Protection Regulation (GDPR).

## 1. Information We Collect

We collect different types of personal information to provide and improve our services. This includes:

* account information  
* usage data  
*  communication data  
* transaction data  
* cookies and tracking technologies  
* error logs and session recording

Additionally, we collect:

* **Bank Transaction Data**: To the extent that users provide us with the necessary permissions, we collect bank transaction data to enable core functionalities of our services.  
* **Tax and Accounting Data**: To the extent that authorized tax advisory and/or accounting firm(s) provide us with this information, we collect tax and accounting data to facilitate seamless integration and reporting.  
* **Documents**: This includes receipts, invoices, contracts, and other similar documents uploaded to our platform by our users (including authorized tax advisory and accounting firms).

## 2. How We Use Your Information

We use the collected data for the following purposes:

* **Providing Services**: To deliver the core functionalities of our platform, including accounting support provided to the authorized tax advisory firm (or accounting firm) and communicating results back to the user.  
* **Improving User Experience**: To analyze usage patterns, troubleshoot issues, and enhance the overall performance and usability of our app.  
* **Improving Algorithms**: To refine and optimize the core services of our platform through the use of anonymized data.  
* **Communication**: To respond to inquiries, provide customer support, and send updates related to our services or your account.  
* **Legal Compliance**: To comply with legal obligations, such as tax and accounting requirements, and to ensure adherence to applicable regulations.

## 3. Legal Basis for Processing Personal Data

We process your personal data based on the following legal grounds:

* **Consent**: When you provide explicit consent for specific processing activities, such as accessing bank transactions or processing accounting information.  
* **Contractual Obligations**: When processing is necessary to perform a contract with you or take steps prior to entering into a contract.  
* **Legitimate Interests**: When processing is necessary for our legitimate interests, such as improving our services, provided these interests do not override your rights and freedoms as defined by GDPR.  
* **Legal Obligations**: When processing is required to comply with legal or regulatory obligations.

## 4. Sharing Your Information

We do not sell or give away your personal data to third parties for their marketing purposes. Your data is shared only to the extent necessary for providing our services and with trusted third-party providers essential to our operations. Specifically, we share your information with:

* **FinAPI**: To securely access and process your bank transactions, enabling core functionalities of our service. You can review their data protection policy here: [FinAPI Data Protection Policy](https://www.finapi.io/en/data-protection-policy/).  
* **Sentry**: To monitor and understand how our app is used, helping us improve its performance and user experience. You can review their privacy policy here: [Sentry Privacy Policy](https://sentry.io/privacy/).  
* **Authorized Tax Advisory Firms**: If you have authorized a tax advisory firm to use your data, we will share relevant information with them to facilitate accounting and tax advisory services.  
* **Cloud Providers**: We use cloud services for storage and data processing adhering to best security practices to ensure the safety and security of your data.

All third-party providers we work with are bound by strict confidentiality agreements and data protection standards to ensure your information remains secure and is used solely for the intended purposes.

## 5. Data Retention

We retain your personal data only as long as necessary to fulfill the purposes described in this policy or as required by applicable law. Retention periods are determined based on the type of data and the purpose for its collection, ensuring that data is not kept longer than necessary. Once your data is no longer required, we securely delete or anonymize it in compliance with legal and regulatory requirements.

## 6. Your Rights

Under the GDPR, you have the following rights regarding your personal data:

* **Access**: The right to obtain confirmation of whether we process your personal data and access to such data.  
* **Rectification**: The right to request correction of inaccurate or incomplete personal data.  
* **Erasure**: The right to request deletion of your personal data, subject to legal and contractual obligations.  
* **Restriction**: The right to request the limitation of processing your personal data under certain circumstances.  
* **Data Portability**: The right to receive your personal data in a structured, commonly used, and machine-readable format and to transfer it to another data controller.  
* **Objection**: The right to object to the processing of your personal data for specific purposes, including direct marketing.

To exercise any of these rights, please contact us using the details provided in this policy. We will respond to your requests in accordance with applicable legal requirements.

## 7. User Consent for Financial Data Processing

By using our services, you explicitly consent to your tax advisor (or accountant) uploading your GDPdU data and processing your bank transactions, uploaded documents (such as invoices, receipts, etc.), and the information extracted from these documents for accounting and tax advisory purposes. This consent is required to fulfill the contractual obligations of the services.

## 8. Security

We use appropriate technical and organizational measures to protect your personal data from unauthorized access, misuse, or loss. These measures include encryption, access controls, regular security audits, and staff training to ensure the highest level of data protection. Despite these efforts, no security measures are entirely foolproof, and we cannot guarantee absolute security of your data.

## 9. Cookies and Tracking Technologies

We use cookies and similar technologies to authenticate you in our app, improve user experience, and analyze platform performance. You can manage your cookie preferences through your browser settings.

## 10. Data Breach Notification

In the event of a data breach, we will promptly inform affected account holders and the appropriate supervisory authorities in compliance with the GDPR.

## 11. International Data Transfers

All storage servers are located mainly in Germany and at most in other countries of the European Economic Area (EEA). Thus, we strictly adhere to the GoBD and the GDPR. Your personal data will not be transferred outside the EEA.

## 12. Changes to This Policy

We may update this privacy policy from time to time. All changes will be posted on this page.

## 13. Third-Party Links

Our system may include links or integrations with third-party services necessary for providing our services or enhancing the user experience (for example FinAPI). We ensure that any such third-party links or integrations comply with strict security standards and applicable privacy regulations to protect your data and maintain compliance.

## 14. Contact Information

If you have any questions about this privacy policy or how we handle your personal data, please contact us at support@kanzlei21.com.`;

export default function PrivacyPolicy() {
  const language = useCurrentLanguage();

  return (
    <>
      <Helmet>
        <title>{`${
          language == SupportedLanguage.German
            ? "Datenschutzerklärung"
            : "Privacy Policy"
        } | Kanzlei21`}</title>
      </Helmet>

      <Card className={styles.policyCard}>
        <Markdown
          components={{
            h1: (o) => (
              <h1 className={`${HEADINGS_CLASS_NAMES.h1} mb-4`}>
                {o.children}
              </h1>
            ),
            h2: (o) => (
              <h2 className={`${HEADINGS_CLASS_NAMES.h2} mb-4 mt-4`}>
                {o.children}
              </h2>
            ),
            h3: (o) => (
              <h3 className={`${HEADINGS_CLASS_NAMES.h3} mb-4 mt-4`}>
                {o.children}
              </h3>
            ),
            h4: (o) => (
              <h4 className={`${HEADINGS_CLASS_NAMES.h4} mb-4 mt-4`}>
                {o.children}
              </h4>
            ),
            ul: (o) => <ul className="list-disc list-inside">{o.children}</ul>,
            ol: (o) => (
              <ol className="list-decimal list-inside">{o.children}</ol>
            ),
          }}
        >
          {language == SupportedLanguage.German
            ? GERMAN_PRIVACY_POLICY
            : ENGLISH_PRIVACY_POLICY}
        </Markdown>
      </Card>
    </>
  );
}
