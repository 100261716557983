import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { HEADINGS_CLASS_NAMES } from "../../../../ui/headings";
import { InputTextarea } from "primereact/inputtextarea";
import { z } from "zod";
import { useCurrentLanguage } from "../../../../language/current-language";
import { SupportedLanguage } from "../../../../language/supported-languages";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useId, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import {
  AccountingFirm,
  AccountingFirmSchema,
} from "../accounting-firm-loader";
import { useMainApi } from "../../../../main-api";
import {
  getCommunicationErrorMessage,
  getCommunicationErrorTitle,
} from "../../../../communication-errors/communication-error-messages";
import { Helmet } from "react-helmet-async";

const GERMAN_TRANSLATIONS = {
  accountingFirmAdministrationAccountingFirmInformation:
    "Kanzlei-Einstellungen | Kanzleiinformationen",
  accountingFirmInformation: "Kanzleiinformationen",
  name: "Name",
  companyEmail: "Firmen-E-Mail",
  email: "E-Mail",
  officialId: "Handelsregister-Eintrag",
  vatId: "USt-ID",
  address: "Adresse",
  homepageUrl: "Homepage-URL",
  phone: "Telefon",
  adminAccountant: "Admin Buchhalter",
  adminEmail: "Admin E-Mail",
  save: "Speichern",
  success: "Erfolg",
  eitherOfficialIdOrVatIdRequired:
    "Entweder Firmen-ID oder USt-ID muss angegeben werden",
  required: "Pflichtfeld",
  enterEmailInValidFormat:
    "Geben Sie eine gültige E-Mail-Adresse ein. Stellen Sie sicher, dass dieses Format verwendet wird: beispiel@xyz.de.",
  enterValidPhoneNumber: "Bitte geben Sie eine gültige Telefonnummer ein.",
  enterValidUrl: "Bitte geben Sie eine gültige URL ein.",
  successMessage: "Kanzleiinformationen erfolgreich aktualisiert",
  duplicateEmailAddress: "Doppelte E-Mail-Adresse",
  duplicateEmailAddressDetail:
    "Ein Buchhalter mit dieser E-Mail existiert bereits.",
};

const ENGLISH_TRANSLATIONS = {
  accountingFirmAdministrationAccountingFirmInformation:
    "Kanzlei Administration| Kanzlei Information",
  accountingFirmInformation: "Kanzlei Information",
  backToDashboard: "Back to Dashboard",
  name: "Name",
  companyEmail: "Company Email",
  email: "Email",
  officialId: "Official ID",
  vatId: "VAT ID",
  address: "Address",
  homepageUrl: "Homepage URL",
  phone: "Phone",
  adminAccountant: "Admin Accountant",
  adminEmail: "Admin Email",
  save: "Save",
  success: "Success",
  eitherOfficialIdOrVatIdRequired:
    "Either Official ID or VAT ID must be provided",
  required: "Required",
  enterEmailInValidFormat:
    "Enter a valid email address. Make sure to use this format: example@xyz.de.",
  enterValidPhoneNumber: "Please enter a valid phone number.",
  enterValidUrl: "Please enter a valid URL.",
  successMessage: "Accounting firm information successfully updated",
  duplicateEmailAddress: "Duplicate email address",
  duplicateEmailAddressDetail: "An accountant with this email already exists.",
};

const AdminSchema = (translations: {
  required: string;
  enterEmailInValidFormat: string;
  enterValidPhoneNumber: string;
  enterValidUrl: string;
}) =>
  z.object({
    name: z.string().min(1, { message: translations.required }),
    email: z.string().email({ message: translations.enterEmailInValidFormat }),
  });

export const UpdateCurrentAccountingFirmSchema = (translations: {
  required: string;
  eitherOfficialIdOrVatIdRequired: string;
  enterEmailInValidFormat: string;
  enterValidPhoneNumber: string;
  enterValidUrl: string;
}) =>
  z
    .object({
      name: z.string().min(1, { message: translations.required }),
      email: z
        .string()
        .min(1, { message: translations.required })
        .email({ message: translations.enterEmailInValidFormat }),
      official_id: z.string().nullable(),
      vat_id: z.string().nullable(),
      address: z.string().min(1, { message: translations.required }),
      homepage_url: z
        .string()
        .min(1, { message: translations.required })
        .url({ message: translations.enterValidUrl }),
      phone: z.string().nullable(),
      admin: AdminSchema(translations),
    })
    .refine((data) => data.official_id || data.vat_id, {
      message: translations.eitherOfficialIdOrVatIdRequired,
      path: ["official_id"],
    });

export type UpdateCurrentAccountingFirm = z.infer<
  ReturnType<typeof UpdateCurrentAccountingFirmSchema>
>;

export default function AccountingFirmInformationForm(props: {
  accountingFirm: AccountingFirm;
  onAccountingFirmDataUpdated: (accountingFirm: AccountingFirm) => void;
}) {
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const mainApi = useMainApi();

  const toastRef = useRef<Toast>(null);

  const idPrefix = useId();

  const form = useForm<UpdateCurrentAccountingFirm>({
    resolver: zodResolver(UpdateCurrentAccountingFirmSchema(translations)),
    defaultValues: {
      name: props.accountingFirm.name || "",
      email: props.accountingFirm.email || "",
      official_id: props.accountingFirm.official_id || null,
      vat_id: props.accountingFirm.vat_id || null,
      address: props.accountingFirm.address || "",
      homepage_url: props.accountingFirm.homepage_url || "",
      phone: props.accountingFirm.phone || null,
      admin: {
        name: props.accountingFirm.admin.name || "",
        email: props.accountingFirm.admin.email || "",
      },
    },
  });

  return (
    <>
      <Helmet>
        <title>{`${translations.accountingFirmAdministrationAccountingFirmInformation} | Kanzlei21`}</title>
      </Helmet>

      <Toast ref={toastRef} />
      <div className="w-full h-full">
        <div className="p-4 max-w-[1080px] mx-auto">
          <h2 className={`${HEADINGS_CLASS_NAMES.h2} my-8`}>
            {translations.accountingFirmInformation}
          </h2>
          <form
            onSubmit={form.handleSubmit(
              async (data: UpdateCurrentAccountingFirm) => {
                setIsSubmitting(true);

                const response = await mainApi.fetchJSON({
                  path: `/accounting_firms/current/accounting_firm_information`,
                  method: "PUT",
                  body: data,

                  schema: z.union([
                    z.object({
                      status: z.literal(409),
                      body: z.object({
                        detail: z.literal("email-is-taken"),
                      }),
                    }),
                    z.object({
                      status: z.literal(200),
                      body: AccountingFirmSchema,
                    }),
                  ]),
                });

                setIsSubmitting(false);

                if (response.error) {
                  toastRef.current?.show({
                    severity: "error",
                    summary: getCommunicationErrorTitle(response.error),
                    detail: getCommunicationErrorMessage(response.error),
                  });
                  return;
                }

                if (response.response.status === 409) {
                  if (response.response.body.detail === "email-is-taken") {
                    toastRef.current?.show({
                      severity: "error",
                      summary: translations.duplicateEmailAddress,
                      detail: translations.duplicateEmailAddressDetail,
                    });
                  } else {
                    throw new Error();
                  }

                  return;
                }

                toastRef.current?.show({
                  severity: "success",
                  summary: translations.success,
                  detail: translations.successMessage,
                });

                props.onAccountingFirmDataUpdated(response.response.body);
              }
            )}
          >
            <div className="flex flex-col gap-4 mb-8">
              <div className="flex flex-1">
                <label className="font-bold w-1/4">{translations.name}</label>
                <div className="w-3/4">
                  <Controller
                    name="name"
                    control={form.control}
                    render={({ field, fieldState }) => (
                      <>
                        <InputText
                          id={`${idPrefix}-name`}
                          className={`${fieldState.error ? "p-invalid" : ""} w-full`}
                          value={field.value || ""}
                          onChange={field.onChange}
                        />
                        {fieldState.error && (
                          <small className="p-error">
                            {fieldState.error.message}
                          </small>
                        )}
                      </>
                    )}
                  />
                </div>
              </div>
              <div className="flex flex-1">
                <label className="font-bold w-1/4">
                  {translations.companyEmail}
                </label>
                <div className="w-3/4">
                  <Controller
                    name="email"
                    control={form.control}
                    render={({ field, fieldState }) => (
                      <>
                        <InputText
                          id={`${idPrefix}-email`}
                          className={`${fieldState.error ? "p-invalid" : ""} w-full`}
                          value={field.value || ""}
                          onChange={field.onChange}
                          keyfilter={"email"}
                        />
                        {fieldState.error && (
                          <small className="p-error">
                            {fieldState.error.message}
                          </small>
                        )}
                      </>
                    )}
                  />
                </div>
              </div>
              <div className="flex flex-1">
                <label className="font-bold w-1/4">
                  {translations.officialId}
                </label>
                <div className="w-3/4">
                  <Controller
                    name="official_id"
                    control={form.control}
                    render={({ field, fieldState }) => (
                      <>
                        <InputText
                          id={`${idPrefix}-official_id`}
                          className={`${fieldState.error ? "p-invalid" : ""} w-full`}
                          value={field.value || ""}
                          onChange={(e) =>
                            field.onChange(e.target.value || null)
                          }
                        />
                        {fieldState.error && (
                          <small className="p-error">
                            {fieldState.error.message}
                          </small>
                        )}
                      </>
                    )}
                  />
                </div>
              </div>
              <div className="flex flex-1">
                <label className="font-bold w-1/4">{translations.vatId}</label>
                <div className="w-3/4">
                  <Controller
                    name="vat_id"
                    control={form.control}
                    render={({ field, fieldState }) => (
                      <>
                        <InputText
                          id={`${idPrefix}-vat_id`}
                          className={`${fieldState.error ? "p-invalid" : ""} w-full`}
                          value={field.value || ""}
                          onChange={(e) =>
                            field.onChange(e.target.value || null)
                          }
                        />
                        {fieldState.error && (
                          <small className="p-error">
                            {fieldState.error.message}
                          </small>
                        )}
                      </>
                    )}
                  />
                </div>
              </div>
              <div className="flex flex-1">
                <label className="font-bold w-1/4">
                  {translations.address}
                </label>
                <div className="w-3/4">
                  <Controller
                    name="address"
                    control={form.control}
                    render={({ field, fieldState }) => (
                      <>
                        <InputTextarea
                          id={`${idPrefix}-address`}
                          className={`${fieldState.error ? "p-invalid" : ""} w-full`}
                          value={field.value || ""}
                          onChange={field.onChange}
                          rows={3}
                          autoResize={false}
                        />
                        {fieldState.error && (
                          <small className="p-error">
                            {fieldState.error.message}
                          </small>
                        )}
                      </>
                    )}
                  />
                </div>
              </div>
              <div className="flex flex-1">
                <label className="font-bold w-1/4">{translations.phone}</label>
                <div className="w-3/4">
                  <Controller
                    name="phone"
                    control={form.control}
                    render={({ field, fieldState }) => (
                      <>
                        <InputText
                          id={`${idPrefix}-phone`}
                          className={`${fieldState.error ? "p-invalid" : ""} w-full`}
                          value={field.value || ""}
                          onChange={(e) =>
                            field.onChange(e.target.value || null)
                          }
                        />
                        {fieldState.error && (
                          <small className="p-error">
                            {fieldState.error.message}
                          </small>
                        )}
                      </>
                    )}
                  />
                </div>
              </div>
              <div className="flex flex-1">
                <label className="font-bold w-1/4">
                  {translations.homepageUrl}
                </label>
                <div className="w-3/4">
                  <Controller
                    name="homepage_url"
                    control={form.control}
                    render={({ field, fieldState }) => (
                      <>
                        <InputText
                          id={`${idPrefix}-homepage_url`}
                          className={`${fieldState.error ? "p-invalid" : ""} w-full`}
                          value={field.value || ""}
                          onChange={field.onChange}
                        />
                        {fieldState.error && (
                          <small className="p-error">
                            {fieldState.error.message}
                          </small>
                        )}
                      </>
                    )}
                  />
                </div>
              </div>
              <div className="flex flex-1">
                <label className="font-bold w-1/4">
                  {translations.adminAccountant}
                </label>
                <div className="w-3/4">
                  <Controller
                    name="admin.name"
                    control={form.control}
                    render={({ field, fieldState }) => (
                      <>
                        <InputText
                          id={`${idPrefix}-admin-name`}
                          className={`${fieldState.error ? "p-invalid" : ""} w-full`}
                          value={field.value || ""}
                          onChange={field.onChange}
                        />
                        {fieldState.error && (
                          <small className="p-error">
                            {fieldState.error.message}
                          </small>
                        )}
                      </>
                    )}
                  />
                </div>
              </div>
              <div className="flex flex-1">
                <label className="font-bold w-1/4">{translations.email}</label>
                <div className="w-3/4">
                  <Controller
                    name="admin.email"
                    control={form.control}
                    render={({ field, fieldState }) => (
                      <>
                        <InputText
                          id={`${idPrefix}-admin-email`}
                          className={`${fieldState.error ? "p-invalid" : ""} w-full`}
                          value={field.value || ""}
                          onChange={field.onChange}
                          keyfilter={"email"}
                        />
                        {fieldState.error && (
                          <small className="p-error">
                            {fieldState.error.message}
                          </small>
                        )}
                      </>
                    )}
                  />
                </div>
              </div>
            </div>

            <Button
              className="w-full mt-8"
              label="Speichern"
              type="submit"
              loading={isSubmitting}
              disabled={isSubmitting}
            />
          </form>
        </div>
      </div>
    </>
  );
}
