import { useState } from "react";
import { AccountingClient } from "../accountingClientLoader";
import { Accounts } from "./accounts/accounts";
import { useCurrentLanguage } from "../../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../../language/supported-languages";
import styles from "./page.module.scss";
import { HEADINGS_CLASS_NAMES } from "../../../../../../ui/headings";
import { FallbackAccount } from "./fallback-account/fallback-account";
import { Title } from "../../../title";

const GERMAN_TRANSLATIONS = {
  accountsOverview: "Kontenübersicht",
  setCounterAccountForIncompleteBookings:
    "Gegenkonto für unvollständige Buchungen festlegen",
};

const ENGLISH_TRANSLATIONS = {
  accountsOverview: "Accounts Overview",
  setCounterAccountForIncompleteBookings:
    "Set counter account for incomplete bookings",
};

function _AccountsOverview({
  activeIndex,
  setActiveIndex,
  selectedAccountId,
  setSelectedAccountId,
  accountingClient,
}: {
  activeIndex: number;
  setActiveIndex: (index: number) => void;
  selectedAccountId: string | undefined;
  setSelectedAccountId: (id: string | undefined) => void;
  accountingClient: AccountingClient;
}) {
  return (
    <Accounts
      accountingClient={accountingClient}
      activeIndex={activeIndex}
      setActiveIndex={setActiveIndex}
      selectedAccountId={selectedAccountId}
      setSelectedAccountId={setSelectedAccountId}
    />
  );
}

export default function AccountsOverview(props: {
  accountingClient: AccountingClient;
}) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [selectedAccountId, setSelectedAccountId] = useState<
    string | undefined
  >();

  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  return (
    <>
      <Title
        accountingClientName={props.accountingClient.name}
        page={translations.accountsOverview}
      />
      <div className={styles.page}>
        <h2 className={HEADINGS_CLASS_NAMES.h2}>
          {translations.setCounterAccountForIncompleteBookings}
        </h2>

        <div className="flex flex-col gap-8 my-8">
          <FallbackAccount />
        </div>
        <h2 className={HEADINGS_CLASS_NAMES.h2}>
          {translations.accountsOverview}
        </h2>

        <div className={styles.body}>
          <_AccountsOverview
            accountingClient={props.accountingClient}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            selectedAccountId={selectedAccountId}
            setSelectedAccountId={setSelectedAccountId}
          />
        </div>
      </div>
    </>
  );
}
