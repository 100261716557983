import { useEffect, useState } from "react";
import { CommunicationError } from "../../../../../../../communication-errors/communication-errors";
import { useMainApi } from "../../../../../../../main-api";
import { useAccountingClientId } from "../../../accounting-client-id";
import { z } from "zod";
import { Message } from "primereact/message";
import { useForm, useWatch } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useCurrentLanguage } from "../../../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../../../language/supported-languages";
import { FallbackAccountState } from "./fallback-account";
import { FallbackAccountForm } from "./fallback-account-form";

const GERMAN_TRANSLATIONS = {
  counterAccountForIncompleteBookings:
    "Gegenkonto für unvollständige Buchungen",
  enterValidNumber:
    "Bitte geben Sie eine gültige Zahl zwischen 1000 und 9999 ein",
  accountName: "Kontoname",
  accountNumber: "Kontonummer",
  thisAccountIsUsedAsPaymentAccount:
    "Dieses Konto wird als Zahlungskonto verwendet.",
  accountNotInKontenplan:
    "Dieses Konto befindet sich noch nicht im Kontenplan.",
};

const ENGLISH_TRANSLATIONS = {
  counterAccountForIncompleteBookings:
    "Counter account for incomplete bookings",
  enterValidNumber: "Please enter a valid number between 1000 and 9999",
  accountName: "Account Name",
  accountNumber: "Account Number",
  thisAccountIsUsedAsPaymentAccount: "This account is used as payment account.",
  accountNotInKontenplan: "This account is not yet in the chart of accounts.",
};

const FallbackAccountFormSchema = (translations: {
  enterValidNumber: string;
}) =>
  z.object({
    fallback_account_number: z
      .number()
      .int()
      .min(1000, {
        message: translations.enterValidNumber,
      })
      .max(9999, {
        message: translations.enterValidNumber,
      })
      .nullable(),
  });

export type FallbackAccountFormValue = z.infer<
  ReturnType<typeof FallbackAccountFormSchema>
>;

const FallbackAccountSchema = z
  .object({
    account_id: z.string().uuid(),
    account_name: z.string(),
    account_number: z.number(),
    is_payment_account: z.boolean(),
  })
  .nullable();

type FallbackAccount = z.infer<typeof FallbackAccountSchema>;

export type GetAccountState = {
  loading?: boolean;
  error?: CommunicationError | "account-not-found";
  data?: FallbackAccount;
};

const AccountNumberSchema = z.number().int().min(1000).max(9999);

export function GetFallbackAccount(props: {
  fallback_account_number: number | null;
  setState: (state: FallbackAccountState) => void;
}) {
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const [account, setAccount] = useState<GetAccountState>({});

  const mainApi = useMainApi();
  const accountingClientId = useAccountingClientId();

  const form = useForm<FallbackAccountFormValue>({
    resolver: zodResolver(FallbackAccountFormSchema(translations)),
    defaultValues: {
      fallback_account_number: props.fallback_account_number || null,
    },
    mode: "onChange",
  });
  const accountNumber = useWatch({
    control: form.control,
    name: "fallback_account_number",
  });

  useEffect(() => {
    if (!accountNumber) {
      setAccount({});
      return;
    }

    const debounceTimer = window.setTimeout(async () => {
      setAccount({ loading: true });

      const accountNumberValidation =
        AccountNumberSchema.safeParse(accountNumber);

      if (!accountNumberValidation.success) {
        setAccount({});
        return;
      }

      const urlSearchParams = new URLSearchParams();
      urlSearchParams.set(
        "account_number",
        accountNumberValidation.data.toString()
      );

      const res = await mainApi.fetchJSON({
        path: `/accounts/get_account?accounting_client_id=${accountingClientId}&${urlSearchParams.toString()}`,
        method: "GET",
        schema: z.union([
          z.object({
            status: z.literal(404),
            body: z.object({
              detail: z.literal("account-not-found"),
            }),
          }),
          z.object({
            status: z.literal(200),
            body: FallbackAccountSchema,
          }),
        ]),
      });

      setAccount({ loading: false });

      if (res.error) {
        setAccount({ error: res.error });
        return;
      }

      if (res.response.status === 404) {
        setAccount({ error: "account-not-found" });
        return;
      }

      setAccount({ data: res.response.body });
    }, 500);

    return () => {
      window.clearTimeout(debounceTimer);
    };
  }, [accountNumber, mainApi, accountingClientId]);

  return (
    <div>
      <span className="font-bold block mb-2">
        {translations.counterAccountForIncompleteBookings}
      </span>
      <div className="flex gap-4 justify-content-center">
        <FallbackAccountForm
          form={form}
          afterSubmit={(args) => {
            props.setState({
              data: {
                fallback_account_number: args.fallback_account_number,
              },
            });
          }}
          accountState={account}
        />

        {(() => {
          if (account.data) {
            return (
              <div className="flex flex-col gap-4">
                <Message
                  severity="info"
                  content={
                    <div className="flex flex-col font-medium">
                      <span>
                        {translations.accountNumber}:{" "}
                        {account.data.account_number}
                      </span>
                      <span>
                        {translations.accountName}: {account.data.account_name}
                      </span>
                    </div>
                  }
                  className="h-fit"
                />

                {account.data.is_payment_account && (
                  <Message
                    severity="warn"
                    text={translations.thisAccountIsUsedAsPaymentAccount}
                    className="h-fit"
                  />
                )}
              </div>
            );
          }

          if (account.error === "account-not-found") {
            return (
              <div>
                <Message
                  severity="warn"
                  text={translations.accountNotInKontenplan}
                  className="h-fit"
                />
              </div>
            );
          }

          return null;
        })()}
      </div>
    </div>
  );
}
