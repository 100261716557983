import { Button } from "primereact/button";
import styles from "./actions.module.scss";
import { useContext, useState } from "react";
import { throwError } from "../../../../../../../../throw-error";
import { Booking, ResultsContext } from "../../../results/results-context";
import { useCurrentLanguage } from "../../../../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../../../../language/supported-languages";
import { useMainApi } from "../../../../../../../../main-api";
import { useAccountingClientId } from "../../../../accounting-client-id";
import { z } from "zod";
import { Toast } from "primereact/toast";
import {
  getCommunicationErrorMessage,
  getCommunicationErrorTitle,
} from "../../../../../../../../communication-errors/communication-error-messages";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";

const GERMAN_TRANSLATIONS = {
  splitBooking: "Splittbuchung",
  cancel: "Abbrechen",
  approve: "Genehmigen",
  deleteLeg: "Teilbuchung löschen",
  success: "Erfolg",
  successfullyDeleted: "Teilbuchung wurde erfolgreich gelöscht.",
  deleteConfirmTitle: "Teilbuchung löschen",
  deleteConfirmMessage:
    "Möchten Sie diese Teilbuchung wirklich löschen? Dies kann nicht rückgängig gemacht werden.",
  rejected: "Abgelehnt",
  rejectMessage: "Änderungen wurden nicht gespeichert",
};

const ENGLISH_TRANSLATIONS = {
  splitBooking: "Split Booking",
  cancel: "Cancel",
  approve: "Approve",
  deleteLeg: "Delete leg",
  success: "Success",
  successfullyDeleted: "Leg was successfully deleted.",
  deleteConfirmTitle: "Delete Leg",
  deleteConfirmMessage:
    "Are you sure you want to delete this leg? This cannot be undone.",
  rejected: "Rejected",
  rejectMessage: "Changes were not saved",
};

export function Actions({
  selectedBooking,
  isSubmitting,
  toastRef,
}: {
  selectedBooking: Booking;
  isSubmitting: boolean;
  toastRef: React.RefObject<Toast>;
}) {
  const [isDeleting, setIsDeleting] = useState(false);

  const { setSelected, setResults } =
    useContext(ResultsContext) || throwError();

  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const mainApi = useMainApi();
  const accountingClientId = useAccountingClientId();

  return (
    <>
      <ConfirmDialog />
      <div className={styles.actionBar}>
        <Button
          icon="pi pi-trash"
          severity="danger"
          outlined
          label={translations.deleteLeg}
          disabled={
            isSubmitting || isDeleting || selectedBooking.status == "HISTORICAL"
          }
          type="button"
          onClick={() => {
            confirmDialog({
              message: translations.deleteConfirmMessage,
              header: translations.deleteConfirmTitle,
              acceptLabel: translations.deleteLeg,
              rejectLabel: translations.cancel,
              accept: async () => {
                setIsDeleting(true);

                const urlSearchParams = new URLSearchParams();

                urlSearchParams.append(
                  "accounting_client_id",
                  accountingClientId
                );
                urlSearchParams.append("booking_id", selectedBooking.id);

                const res = await mainApi.fetchJSON({
                  method: "DELETE",
                  path: `/bookings/split_bookings/delete_leg?${urlSearchParams.toString()}`,
                  schema: z.object({
                    status: z.literal(200),
                    body: z.object({}),
                  }),
                });

                setIsDeleting(false);

                if (res.error) {
                  toastRef.current?.show({
                    severity: "error",
                    summary:
                      getCommunicationErrorTitle(res.error) ??
                      translations.cancel,
                    detail: getCommunicationErrorMessage(res.error),
                  });
                  return;
                }

                setResults((results) => ({
                  ...results,
                  data: results.data && {
                    ...results.data,
                    bookings: results.data.bookings.filter(
                      (booking) => booking.id !== selectedBooking.id
                    ),
                  },
                }));

                toastRef.current?.show({
                  severity: "success",
                  summary: translations.success,
                  detail: translations.successfullyDeleted,
                });
              },
              reject: () => {
                toastRef.current?.show({
                  severity: "warn",
                  summary: translations.rejected,
                  detail: translations.rejectMessage,
                });
              },
            });
          }}
        />
        <Button
          severity="success"
          icon="pi pi-check"
          label={translations.approve}
          disabled={
            isSubmitting || isDeleting || selectedBooking.status == "HISTORICAL"
          }
          loading={isSubmitting}
          type="submit"
        />
        <Button
          severity="secondary"
          icon="pi pi-times"
          text
          type="button"
          onClick={() => setSelected(undefined)}
        />
      </div>
    </>
  );
}
