import { InputText } from "primereact/inputtext";
import styles from "./user-settings.module.scss";
import { Controller, useForm } from "react-hook-form";
import { Button } from "primereact/button";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Divider } from "primereact/divider";
import { ResetPassword } from "./reset-password/reset-password";
import { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { ProgressSpinner } from "primereact/progressspinner";
import { CommunicationError } from "../../../communication-errors/communication-errors";
import { useCurrentLanguage } from "../../../language/current-language";
import { SupportedLanguage } from "../../../language/supported-languages";
import { useMainApi } from "../../../main-api";
import { HEADINGS_CLASS_NAMES } from "../../../ui/headings";
import {
  getCommunicationErrorMessage,
  getCommunicationErrorTitle,
} from "../../../communication-errors/communication-error-messages";

export const UserSettingSchema = z.object({
  accounting_firm: z.object({
    name: z.string().min(1),
  }),
  name: z.string().min(1),
  phone: z.string().nullable(),
  user: z.object({
    name: z.string().nullable(),
    email: z.string().min(1).email(),
  }),
});

export type UserSettings = z.infer<typeof UserSettingSchema>;

const GERMAN_TRANSLATIONS = {
  userProfile: "Benutzerprofil",
  yourData: "Ihre Daten",
  save: "Speichern",
  accountingFirm: "Buchhaltungsfirma",
  companyName: "Name Ihrer Firma",
  name: "Name",
  email: "E-Mail",
  phone: "Telefon",
  emailNotAvailable: "E-Mail nicht verfügbar",
  emailNotAvailableDetail:
    "Diese E-Mail-Adresse wird bereits von einem anderen Konto verwendet.",
  emailUpdatedSuccessfully: "E-Mail erfolgreich aktualisiert",
  emailUpdatedSuccessfullyDetail:
    "Bitte überprüfen Sie Ihre E-Mail, um die E-Mail-Adresse zu bestätigen und sich anzumelden.",
  success: "Erfolg",
  successDetail: "Konto erfolgreich aktualisiert",
};

const ENGLISH_TRANSLATIONS = {
  userProfile: "User Profile",
  yourData: "Your Data",
  save: "Save",
  accountingFirm: "Accounting Firm",
  companyName: "Company Name",
  name: "Name",
  email: "Email",
  phone: "Phone",
  emailNotAvailable: "Email not available",
  emailNotAvailableDetail:
    "This email address is already being used by another account.",
  emailUpdatedSuccessfully: "Email Updated Successfully",
  emailUpdatedSuccessfullyDetail:
    "Please check your email to verify email address and login.",
  success: "Success",
  successDetail: "Account updated successfully",
};

export function ClientUserSettings() {
  const mainApi = useMainApi();
  const toast = useRef<Toast | null>(null);

  const [results, setResults] = useState<{
    loading?: boolean;
    error?: CommunicationError;
    data?: UserSettings;
  }>({});

  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  useEffect(() => {
    (async () => {
      if (results.loading) {
        return;
      }
      const res = await mainApi.fetchJSON({
        path: `/accounting_client`,
        method: "GET",
        schema: z.object({
          status: z.literal(200),
          body: UserSettingSchema,
        }),
      });

      if (res.error) {
        toast.current!.show({
          severity: "error",
          summary: getCommunicationErrorTitle(res.error),
          detail: getCommunicationErrorMessage(res.error),
        });

        setResults({ error: res.error });
        return;
      }

      setResults({ data: res.response.body });
    })();
  }, [mainApi, results.loading]);

  const form = useForm<UserSettings>({
    resolver: zodResolver(UserSettingSchema),
  });

  useEffect(() => {
    if (!results.data) {
      form.reset({
        accounting_firm: { name: "" },
        name: "",
        phone: "",
        user: { name: "", email: "" },
      });
      return;
    }

    form.reset({
      accounting_firm: { name: results.data.accounting_firm.name || "" },
      name: results.data.name || "",
      phone: results.data.phone || "",
      user: {
        name: results.data.user.name || "",
        email: results.data.user.email || "",
      },
    });
  }, [form, results.data]);

  return (
    <>
      <Toast ref={toast} />

      {(() => {
        if (results.loading) {
          return <ProgressSpinner className="flex justify-center" />;
        }

        if (!results.data) {
          return null;
        }

        return (
          <form
            className={`${styles.page}`}
            onSubmit={form.handleSubmit(async (values) => {
              setResults({ ...results, loading: true });

              const res = await mainApi.fetchJSON({
                method: "PUT",
                path: `/accounting_client`,
                body: values,
                schema: z.union([
                  z.object({
                    status: z.literal(409),
                    body: z.object({
                      detail: z.literal("email-is-taken"),
                    }),
                  }),
                  z.object({
                    status: z.literal(200),
                    body: UserSettingSchema,
                  }),
                ]),
              });

              setResults({ ...results, loading: false });

              if (res.error) {
                toast.current!.show({
                  severity: "error",
                  summary: getCommunicationErrorTitle(res.error),
                  detail: getCommunicationErrorMessage(res.error),
                });
                return;
              }

              if (res.response.status === 409) {
                toast.current?.show({
                  severity: "error",
                  summary: translations.emailNotAvailable,
                  detail: translations.emailNotAvailableDetail,
                });
                return;
              }

              toast.current?.show({
                severity: "success",
                summary: translations.success,
                detail: translations.successDetail,
              });

              setResults({ data: res.response.body });
            })}
          >
            <h2 className={`${styles.title} ${HEADINGS_CLASS_NAMES.h2}`}>
              {translations.userProfile}
            </h2>

            <div className={"grid grid-cols-4 gap-4 mx-10 mt-10"}>
              <h3 className={`${styles.title} ${HEADINGS_CLASS_NAMES.h3}`}>
                {translations.yourData}
              </h3>
            </div>

            <Divider />

            <div className={`${styles.form} mx-10`}>
              <h4 className={`${styles.formLabel} ${HEADINGS_CLASS_NAMES.h4}`}>
                {translations.accountingFirm}
              </h4>
              <Controller
                name="accounting_firm.name"
                control={form.control}
                render={({ field, fieldState }) => {
                  return (
                    <div className={`${styles.inputGroup}`}>
                      <InputText
                        className={styles.input}
                        id="accounting_firm.name"
                        value={field.value}
                        onChange={(e) => field.onChange(e.target.value)}
                        disabled
                      />
                      {fieldState.error ? (
                        <small className="p-error">
                          {fieldState.error.message}
                        </small>
                      ) : null}
                    </div>
                  );
                }}
              />
            </div>
            <div className={`${styles.form} mx-10`}>
              <h4 className={`${styles.formLabel} ${HEADINGS_CLASS_NAMES.h4}`}>
                {translations.companyName}
              </h4>
              <Controller
                name="name"
                control={form.control}
                render={({ field, fieldState }) => {
                  return (
                    <div className={`${styles.inputGroup}`}>
                      <InputText
                        className={styles.input}
                        id="name"
                        disabled
                        value={field.value}
                        onChange={(e) => field.onChange(e.target.value)}
                      />
                      {fieldState.error ? (
                        <small className="p-error">
                          {fieldState.error.message}
                        </small>
                      ) : null}
                    </div>
                  );
                }}
              />
            </div>

            <div className={`${styles.form} mx-10`}>
              <h4 className={`${styles.formLabel} ${HEADINGS_CLASS_NAMES.h4}`}>
                {translations.name}
              </h4>
              <Controller
                name="user.name"
                control={form.control}
                render={({ field, fieldState }) => {
                  return (
                    <div className={`${styles.inputGroup}`}>
                      <InputText
                        className={styles.input}
                        id="name"
                        value={field.value}
                        onChange={(e) => field.onChange(e.target.value)}
                      />
                      {fieldState.error ? (
                        <small className="p-error">
                          {fieldState.error.message}
                        </small>
                      ) : null}
                    </div>
                  );
                }}
              />
            </div>
            <div className={`${styles.form} mx-10`}>
              <h4 className={`${styles.formLabel} ${HEADINGS_CLASS_NAMES.h4}`}>
                {translations.email}
              </h4>
              <Controller
                name="user.email"
                control={form.control}
                render={({ field, fieldState }) => {
                  return (
                    <div className={`${styles.inputGroup}`}>
                      <InputText
                        className={styles.input}
                        id="user.email"
                        value={field.value}
                        onChange={(e) => field.onChange(e.target.value)}
                        keyfilter="email"
                      />
                      {fieldState.error ? (
                        <small className="p-error">
                          {fieldState.error.message}
                        </small>
                      ) : null}
                    </div>
                  );
                }}
              />
            </div>
            <div className={`${styles.form} mx-10`}>
              <h4 className={`${styles.formLabel} ${HEADINGS_CLASS_NAMES.h4}`}>
                {translations.phone}
              </h4>
              <Controller
                name="phone"
                control={form.control}
                render={({ field, fieldState }) => {
                  return (
                    <div className={`${styles.inputGroup}`}>
                      <InputText
                        className={styles.input}
                        id="phone"
                        value={field.value}
                        onChange={(e) => field.onChange(e.target.value)}
                      />
                      {fieldState.error ? (
                        <small className="p-error">
                          {fieldState.error.message}
                        </small>
                      ) : null}
                    </div>
                  );
                }}
              />
            </div>

            <Button
              type="submit"
              className="w-100 mx-10 my-10"
              label={translations.save}
              loading={results.loading}
            />

            <div className="m-10">
              <ResetPassword />
            </div>
          </form>
        );
      })()}
    </>
  );
}
