import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Booking } from "../../../results/results-context";
import { useContext } from "react";
import { BookingsPageResourcesContext } from "../../../page-resources";
import { useCurrentLanguage } from "../../../../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../../../../language/supported-languages";
import { Controller, UseFormReturn, useFieldArray } from "react-hook-form";
import { EditSplitBookingFormValue } from "../split-booking-details-panel";
import styles from "./split-booking-table.module.scss";
import { throwError } from "../../../../../../../../throw-error";
import { Chips } from "primereact/chips";
import { BookingsPageBankAccountsContext } from "../../../top-section/navbar/quick-filters/bank-accounts-context";

const GERMAN_TRANSLATIONS = {
  amount: "Betrag",
  debit: "Soll",
  credit: "Haben",
  debitCredit: "Soll / Haben",
  taxCode: "Steuercode",
  account: "Konto",
  counterAccount: "Gegenkonto",
  skonto: "Skonto",
  skontoAccount: "Skontokonto",
  invoiceNumber: "Rechnungsnummer",
  addNewLeg: "Neue Teilbuchung hinzufügen",
};

const ENGLISH_TRANSLATIONS = {
  amount: "Amount",
  credit: "Credit",
  debit: "Debit",
  debitCredit: "Debit / Credit",
  taxCode: "Tax Code",
  account: "Account",
  counterAccount: "Counter Account",
  skonto: "Skonto",
  skontoAccount: "Skonto Account",
  invoiceNumber: "Invoice Number",
  addNewLeg: "Add New Leg",
};

export function SplitBookingTable(props: {
  legs: Booking[];
  form: UseFormReturn<EditSplitBookingFormValue>;
  hasHistoricalSplitBookingLegs: boolean;
}) {
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const { counter_accounts, tax_codes } =
    useContext(BookingsPageResourcesContext) || throwError();

  const { selectedBankAccount } =
    useContext(BookingsPageBankAccountsContext) || throwError();

  const splitBookingsArray = useFieldArray({
    control: props.form.control,
    name: "split_bookings",
  });

  const actionHeaderTemplate = () => (
    <Button
      size={"small"}
      icon="pi pi-plus"
      tooltip={translations.addNewLeg}
      type="button"
      disabled={props.hasHistoricalSplitBookingLegs}
      onClick={() =>
        splitBookingsArray.append({
          id: null,
          is_debit: true,
          amount: 0,
          counter_account_id: null,
          tax_code_id: null,
          invoice_numbers: [],
        })
      }
    />
  );

  return (
    <div className="flex flex-col w-full h-full">
      <DataTable
        value={splitBookingsArray.fields}
        showGridlines
        scrollable
        scrollHeight="24vh"
        dataKey={"id"}
        size={"small"}
      >
        <Column
          header={translations.amount}
          body={(_rowData, { rowIndex }) => (
            <Controller
              name={`split_bookings.${rowIndex}.amount`}
              control={props.form.control}
              render={({ field, fieldState }) => (
                <div className="flex flex-col gap-2">
                  <InputNumber
                    value={field.value}
                    onValueChange={(e) => field.onChange(e.value)}
                    mode="currency"
                    currency="EUR"
                    className={`p-inputtext-sm w-full ${fieldState.error ? "p-invalid" : ""}`}
                    disabled={props.hasHistoricalSplitBookingLegs}
                  />
                  {fieldState.error && (
                    <small className="p-error">
                      {fieldState.error.message}
                    </small>
                  )}
                </div>
              )}
            />
          )}
        />
        <Column
          header={translations.counterAccount}
          body={(_rowData, { rowIndex }) => (
            <Controller
              name={`split_bookings.${rowIndex}.counter_account_id`}
              control={props.form.control}
              render={({ field }) => (
                <Dropdown
                  options={counter_accounts.map((t) => ({
                    value: t.id,
                    label: `${t.number}: ${t.name}`,
                    disabled: t.id === selectedBankAccount?.account_id,
                  }))}
                  value={field.value}
                  onChange={(e) => field.onChange(e.target.value)}
                  className="p-inputtext-sm w-full"
                  disabled={props.hasHistoricalSplitBookingLegs}
                />
              )}
            />
          )}
        />
        <Column
          header={translations.debitCredit}
          body={(_rowData, { rowIndex }) => (
            <Controller
              name={`split_bookings.${rowIndex}.is_debit`}
              control={props.form.control}
              render={({ field }) => (
                <Dropdown
                  options={[
                    { value: true, label: translations.debit },
                    { value: false, label: translations.credit },
                  ]}
                  value={field.value}
                  onChange={(e) => field.onChange(e.target.value)}
                  className="p-inputtext-sm w-full"
                  disabled={props.hasHistoricalSplitBookingLegs}
                  itemTemplate={(option) => (
                    <span
                      className={option.value ? styles.debit : styles.credit}
                    >
                      {option.label}
                    </span>
                  )}
                  valueTemplate={(option) => (
                    <span
                      className={option.value ? styles.debit : styles.credit}
                    >
                      {option.label}
                    </span>
                  )}
                />
              )}
            />
          )}
        />
        <Column
          header={translations.taxCode}
          body={(_rowData, { rowIndex }) => (
            <Controller
              name={`split_bookings.${rowIndex}.tax_code_id`}
              control={props.form.control}
              render={({ field }) => (
                <Dropdown
                  options={tax_codes.map((t) => ({
                    value: t.id,
                    label: `${t.tax_code} | ${t.description}`,
                  }))}
                  value={field.value}
                  onChange={(e) => field.onChange(e.target.value)}
                  className="p-inputtext-sm w-full"
                  disabled={props.hasHistoricalSplitBookingLegs}
                />
              )}
            />
          )}
        />
        <Column
          header={translations.invoiceNumber}
          body={(_rowData, { rowIndex }) => (
            <Controller
              name={`split_bookings.${rowIndex}.invoice_numbers`}
              control={props.form.control}
              render={({ field, fieldState }) => (
                <div className="flex flex-col gap-2">
                  <Chips
                    value={field.value || []}
                    onChange={(e) => field.onChange(e.value)}
                    className={`p-inputtext-sm w-full ${fieldState.error ? "p-invalid" : ""}`}
                    disabled={props.hasHistoricalSplitBookingLegs}
                    pt={{
                      container: {
                        className: "w-full",
                      },
                    }}
                  />
                  {fieldState.error && (
                    <small className="p-error">
                      {fieldState.error.message}
                    </small>
                  )}
                </div>
              )}
            />
          )}
        />

        {props.hasHistoricalSplitBookingLegs && (
          <Column
            header={translations.skonto}
            body={(_rowData, { rowIndex }) => {
              const skontoAmount = props.legs[rowIndex]?.skonto_amount;
              if (!skontoAmount) return <></>;
              return `${skontoAmount.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })} €`;
            }}
          />
        )}

        {props.hasHistoricalSplitBookingLegs && (
          <Column
            header={translations.skontoAccount}
            body={(_rowData, { rowIndex }) => {
              const skontoAccount = props.legs[rowIndex]?.skonto_account;
              if (!skontoAccount) return <></>;
              return skontoAccount.number;
            }}
          />
        )}

        {!props.hasHistoricalSplitBookingLegs && (
          <Column
            frozen
            alignFrozen="right"
            header={actionHeaderTemplate}
            body={(_rowData, { rowIndex }) => {
              return (
                <Button
                  icon="pi pi-trash"
                  text
                  severity="danger"
                  type="button"
                  onClick={() => splitBookingsArray.remove(rowIndex)}
                  disabled={splitBookingsArray.fields.length <= 1}
                />
              );
            }}
            className="w-16"
          />
        )}
      </DataTable>
    </div>
  );
}
