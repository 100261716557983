import { useEffect, useState } from "react";
import { CommunicationError } from "../../../../../../../communication-errors/communication-errors";
import { useMainApi } from "../../../../../../../main-api";
import { useAccountingClientId } from "../../../accounting-client-id";
import { z } from "zod";
import { ProgressSpinner } from "primereact/progressspinner";
import { useCommunicationErrorTranslations } from "../../../../../../../communication-errors/communication-error-messages";
import { Message } from "primereact/message";
import { GetFallbackAccount } from "./get-fallback-account";

export type FallbackAccountState = {
  loading?: boolean;
  error?: CommunicationError;
  data?: { fallback_account_number: number | null };
};

export function FallbackAccount() {
  const mainApi = useMainApi();

  const communicationErrorTranslations = useCommunicationErrorTranslations();

  const accountingClientId = useAccountingClientId();
  const [state, setState] = useState<FallbackAccountState>({});

  useEffect(() => {
    (async () => {
      setState({ loading: true });

      const res = await mainApi.fetchJSON({
        path: `/accounts/get_fallback_account?accounting_client_id=${accountingClientId}`,
        method: "GET",
        schema: z.object({
          status: z.literal(200),
          body: z.object({
            fallback_account_number: z.number().nullable(),
          }),
        }),
      });

      if (res.error) {
        setState({ error: res.error });
        return;
      }

      setState({ data: res.response.body });
    })();
  }, [accountingClientId, mainApi]);

  if (state.loading) {
    return (
      <div className="flex justify-content-center">
        <ProgressSpinner />
      </div>
    );
  }

  if (state.error) {
    return (
      <div className="flex justify-content-center">
        <Message
          severity="error"
          text={communicationErrorTranslations.getCommunicationErrorTitle(
            state.error
          )}
        />
      </div>
    );
  }

  if (!state.data) {
    return <></>;
  }

  return (
    <GetFallbackAccount
      fallback_account_number={state.data.fallback_account_number}
      setState={setState}
    />
  );
}
