import { SupportedLanguage } from "./supported-languages";

export function useCurrentLanguage() {
  if (
    window.navigator.language === "en" ||
    window.navigator.language.startsWith("en-")
  ) {
    return SupportedLanguage.English;
  } else {
    return SupportedLanguage.German;
  }
}
