import { useContext, useState, useRef } from "react";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useMainApi } from "../../../../../../../../../../main-api";
import { useAccountingClientId } from "../../../../../../accounting-client-id";
import { QuickFiltersContext } from "../../../quick-filters/quick-filters-context";
import { toDateString } from "../../../../../../../../../../dates";
import { throwError } from "../../../../../../../../../../throw-error";
import { getCommunicationErrorMessage } from "../../../../../../../../../../communication-errors/communication-error-messages";
import { useCurrentLanguage } from "../../../../../../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../../../../../../language/supported-languages";
import { BookingsPageBankAccountsContext } from "../../../quick-filters/bank-accounts-context";

const GERMAN_TRANSLATIONS = {
  exportCsv: "CSV exportieren",
};

const ENGLISH_TRANSLATIONS = {
  exportCsv: "Export CSV",
};

export function ExportCsvButton({
  onExport,
  disabled,
}: {
  onExport: (filename: string) => void;
  disabled: boolean;
}) {
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const mainApi = useMainApi();

  const accountingClientId = useAccountingClientId();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { selectedBankAccount } =
    useContext(BookingsPageBankAccountsContext) || throwError();

  const { quickFilters } = useContext(QuickFiltersContext) || throwError();

  const toastRef = useRef<Toast>(null);

  const onSubmit = async () => {
    if (!selectedBankAccount) {
      return;
    }

    setIsSubmitting(true);

    const urlSearchParams = new URLSearchParams();
    urlSearchParams.append("accounting_client_id", accountingClientId);
    urlSearchParams.append("account_id", selectedBankAccount.account_id);

    if (quickFilters.time_frame_from != null) {
      urlSearchParams.append(
        "time_frame_from",
        toDateString(quickFilters.time_frame_from)
      );
    }

    if (quickFilters.time_frame_to != null) {
      urlSearchParams.append(
        "time_frame_to",
        toDateString(quickFilters.time_frame_to)
      );
    }

    urlSearchParams.append("accounting_client_id", accountingClientId);

    const res = await mainApi.fetch(
      `/bookings/exports/export_bookings_csv?${urlSearchParams.toString()}`,
      [200],
      { method: "GET" }
    );

    setIsSubmitting(false);

    if (res.error) {
      toastRef.current?.show({
        severity: "error",
        summary: "Error",
        detail: getCommunicationErrorMessage(res.error),
      });
      return;
    }

    const disposition = res.response.headers.get("Content-Disposition");
    const filename =
      disposition?.match(/filename="?(.+)"?/i)?.[1] || "bookings_export.csv";

    const blob = await res.response.blob();
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = filename;
    link.click();

    URL.revokeObjectURL(url);

    onExport(filename);
  };

  return (
    <>
      <Toast ref={toastRef} />
      <Button
        label={translations.exportCsv}
        onClick={onSubmit}
        disabled={isSubmitting || !selectedBankAccount || disabled}
        loading={isSubmitting}
      />
    </>
  );
}
