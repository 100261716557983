import { useCurrentLanguage } from "../../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../../language/supported-languages";
import { HEADINGS_CLASS_NAMES } from "../../../../../../ui/headings";
import { NavBar } from "./navbar/navbar";
import styles from "./top-section.module.scss";

const GERMAN_TRANSLATIONS = {
  title: "Bankbuchungen",
};

const ENGLISH_TRANSLATIONS = {
  title: "Bank Bookings",
};

export function TopSection(props: { className: string }) {
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  return (
    <>
      <div className={`${styles.page} ${props.className}`}>
        <h2 className={`${styles.title} ${HEADINGS_CLASS_NAMES.h2}`}>
          {translations.title}
        </h2>
        <NavBar />
      </div>
    </>
  );
}
