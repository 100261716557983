import { AddBankConnection } from "../../results/add-connection/add-connection"
import styles from "./nav-bar.module.scss"
import { QuickFilters } from "./quick-filters/quick-filters"

export function Navbar() {
    return (
        <>
            <div className={styles.navbar}>
                <div className={styles.navbarItems}>
                    <div className={styles.buttonBar}>
                        <QuickFilters />
                    </div>
                </div>

                <div className={styles.navbarItems}>
                    <div className={styles.buttonBar}>
                        <AddBankConnection />
                    </div>
                </div>
            </div >
        </>
    )
}