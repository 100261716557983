import styles from "./page.module.scss";
import { Clients } from "./clients/clients";
import { TodaysOverview } from "./todays-overview";
import { Button } from "primereact/button";
import { useContext } from "react";
import { throwError } from "../../throw-error";
import { useNavigate } from "react-router-dom";
import { ACCOUNTING_FIRM_ROUTES } from "./accounting-firm/accounting-firm-routes";
import { AccountantAuthenticationStateContext } from "../../accountants/authentication/authentication-state";
import { ACCOUNTANT_USER_SETTING_ROUTE } from "./settings/accountant-user-setting-routes";
import { SupportedLanguage } from "../../language/supported-languages";
import { useCurrentLanguage } from "../../language/current-language";
import { Metrics } from "./metrics/page";
import { NotificationsButton } from "./notifications-button";
import { Helmet } from "react-helmet-async";

const GERMAN_TRANSLATIONS = {
  accountantHome: "Buchhalter | Startseite",
  accountantSettings: "Account-Einstellungen",
  accountingFirmAdministration: "Kanzlei-Einstellungen",
};

const ENGLISH_TRANSLATIONS = {
  accountantHome: "Accountant | Home",
  accountantSettings: "Accountant Settings",
  accountingFirmAdministration: "Kanzlei Administration",
};

export default function AccountantHomePage() {
  const navigate = useNavigate();
  const { sessionState } =
    useContext(AccountantAuthenticationStateContext) || throwError();

  const session = sessionState.data || throwError();

  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  return (
    <>
      <Helmet>
        <title>{`${translations.accountantHome} | Kanzlei21`}</title>
      </Helmet>

      <div className={styles.header}>
        <div></div>
        <div className={styles.buttons}>
          <NotificationsButton />
          <Button
            severity="secondary"
            icon="pi pi-user"
            label={translations.accountantSettings}
            onClick={() => navigate(ACCOUNTANT_USER_SETTING_ROUTE.getHref())}
          />
          {session.permissions.accounting_firm_admin ? (
            <Button
              label={translations.accountingFirmAdministration}
              icon="pi pi-building"
              severity={"secondary"}
              onClick={() =>
                navigate(ACCOUNTING_FIRM_ROUTES.accountants.getHref())
              }
            />
          ) : null}
        </div>
      </div>
      <main className={styles.main}>
        <Clients />
        <Metrics />
        <TodaysOverview />
      </main>
    </>
  );
}
