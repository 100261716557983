export const ACCOUNTING_FIRM_ROUTES = {
  accountants: {
    path: "/accountant/accounting-firm/accountants",
    getHref: () => "/accountant/accounting-firm/accountants",
  },
  accountingFirmInformation: {
    path: "/accountant/accounting-firm/accounting-firm-information",
    getHref: () => "/accountant/accounting-firm/accounting-firm-information",
  },
};
