import {
  PieChart,
  Pie,
  ResponsiveContainer,
  PieLabelRenderProps,
} from "recharts";
import styles from "./export-details.module.scss";
import { useContext } from "react";
import { Badge } from "primereact/badge";
import { throwError } from "../../../../../../throw-error";
import { ResultsContext } from "../../result-context";
import {
  ExportDetailContext,
  ExportDetailProvider,
} from "./export-details-context";
import { ProgressSpinner } from "primereact/progressspinner";
import { useCurrentLanguage } from "../../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../../language/supported-languages";

const dateFormatter = new Intl.DateTimeFormat(undefined, {
  dateStyle: "medium",
});

type PieGraph = {
  name: string;
  value: number;
  fill?: string;
};

const GERMAN_TRANSLATIONS = {
  accountingClient: "Rechnungsprüfer",
  account: "Konto",
  exportedDate: "Exportdatum",
  totalBookings: "Gesamtbuchungen",
  fullyAutomated: "Vollautomatisiert",
  manuallyApproved: "Manuell genehmigt",
  actionNeeded: "Handlungsbedarf",
  correctedByAccountant: "Manuell korrigiert",
  historical: "Historisch",
};

const ENGLISH_TRANSLATIONS = {
  accountingClient: "Accounting Client",
  account: "Account",
  exportedDate: "Exported Date",
  totalBookings: "Total Bookings",
  fullyAutomated: "Fully Automated",
  manuallyApproved: "Manually Approved",
  actionNeeded: "Action Needed",
  correctedByAccountant: "Corrected by Accountant",
  historical: "Historical",
};

function _ExportDetails() {
  const { results } = useContext(ExportDetailContext) || throwError();
  const { selectedExport } = useContext(ResultsContext) || throwError();
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  if (results.loading) {
    return (
      <div className={styles.loadingSpinner}>
        <ProgressSpinner />
      </div>
    );
  }

  if (results.error) {
    return <></>;
  }

  if (results.data && selectedExport) {
    const data = results.data.exportJournalCount;

    const pie_data: PieGraph[] = [
      {
        name: translations.fullyAutomated,
        value: data.predicted_reduced_journals_count,
        fill: styles.successColor,
      },
      {
        name: translations.manuallyApproved,
        value: data.manually_approved_reduced_journals_count,
        fill: styles.manuallyApprovedColor,
      },
      {
        name: translations.actionNeeded,
        value: data.action_needed_reduced_journals_count,
        fill: styles.errorColor,
      },
      {
        name: translations.correctedByAccountant,
        value: data.corrected_reduced_journals_count,
        fill: styles.infoColor,
      },
      {
        name: translations.historical,
        value: data.historical_reduced_journals_count,
        fill: styles.secondaryColor,
      },
    ];

    const renderCustomizedLabel = ({
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      percent,
    }: PieLabelRenderProps) => {
      const RADIAN = Math.PI / 180;
      const radius =
        Number(innerRadius) + (Number(outerRadius) - Number(innerRadius)) * 0.5;
      const x = Number(cx) + radius * Math.cos(-midAngle * RADIAN);
      const y = Number(cy) + radius * Math.sin(-midAngle * RADIAN);

      return (
        <text
          x={x}
          y={y}
          fill="white"
          textAnchor="middle"
          dominantBaseline="central"
          fontWeight={"bold"}
        >
          {`${((percent ?? 0) * 100).toFixed(0)}%`}
        </text>
      );
    };

    return (
      <div>
        <div className={styles.exportDetails}>
          <div>
            {translations.accountingClient}:{" "}
            <span> {selectedExport?.accounting_client?.name}</span>
          </div>

          <div>
            {translations.account}:{" "}
            <span>
              {" "}
              {selectedExport?.account?.number} |{" "}
              {selectedExport?.account?.name}{" "}
            </span>
          </div>

          <div>
            {translations.exportedDate}:{" "}
            <span> {dateFormatter.format(selectedExport?.exported_date)} </span>
          </div>
        </div>

        <div className={styles.pieChartSummary}>
          <ResponsiveContainer width={400} height={400}>
            <PieChart>
              <Pie
                data={pie_data}
                dataKey="value"
                cx="50%"
                cy="50%"
                outerRadius={150}
                labelLine={false}
                label={renderCustomizedLabel}
              />
            </PieChart>
          </ResponsiveContainer>
          <div>
            <div className={styles.details}>
              <div className={styles.detailsItem}>
                <Badge className={`${styles.badge} ${styles.total}`} />
                <span>{translations.totalBookings}</span>
              </div>
              <span className={`${styles.value} ${styles.total_text}`}>
                {data.total}
              </span>
            </div>
            <div className={styles.details}>
              <div className={styles.detailsItem}>
                <Badge severity={"success"} className={styles.badge} />
                <span>{translations.fullyAutomated}</span>
              </div>
              <span className={`${styles.value} ${styles.predicted}`}>
                {data.predicted_reduced_journals_count}
              </span>
            </div>
            <div className={styles.details}>
              <div className={styles.detailsItem}>
                <Badge
                  severity={"success"}
                  className={`${styles.badge} bg-manuallyApproved`}
                />
                <span>{translations.manuallyApproved}</span>
              </div>
              <span className={`${styles.value} text-textManuallyApproved`}>
                {data.manually_approved_reduced_journals_count}
              </span>
            </div>
            <div className={styles.details}>
              <div className={styles.detailsItem}>
                <Badge severity={"danger"} className={styles.badge} />
                <span>{translations.actionNeeded}</span>
              </div>
              <span className={`${styles.value} ${styles.actionNeeded}`}>
                {data.action_needed_reduced_journals_count}
              </span>
            </div>
            <div className={styles.details}>
              <div className={styles.detailsItem}>
                <Badge severity={"info"} className={styles.badge} />
                <span>{translations.correctedByAccountant}</span>
              </div>
              <span className={`${styles.value} ${styles.corrected}`}>
                {data.corrected_reduced_journals_count}
              </span>
            </div>
            <div className={styles.details}>
              <div className={styles.detailsItem}>
                <Badge className={`${styles.badge} ${styles.historical}`} />
                <span>{translations.historical}</span>
              </div>
              <span className={`${styles.value} ${styles.historical_text}`}>
                {data.historical_reduced_journals_count}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export function ExportDetails() {
  return (
    <ExportDetailProvider>
      <_ExportDetails />
    </ExportDetailProvider>
  );
}
