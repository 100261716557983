import { Card } from 'primereact/card';
import styles from "./export-bookings-to-edrewe.module.scss"
import Markdown from 'react-markdown';
import { SupportedLanguage } from '../../../../language/supported-languages';
import { HEADINGS_CLASS_NAMES } from '../../../../ui/headings';
import { useCurrentLanguage } from '../../../../language/current-language';
import select_client from "./images/select_client.png";
import go_to_bank_bookings from "./images/go_to_bank_bookings.png";
import set_filters from "./images/set_filters.png";
import export_preview from "./images/export_preview.png";
import success_message from "./images/success_message.png";
import download_xml_template from "./images/download_xml_template.png";
import go_to_imports_section_a from "./images/go_to_imports_section_a.png";
import go_to_imports_section_b from "./images/go_to_imports_section_b.png";
import select_kanzlei21_template from "./images/select_kanzlei21_template.png";
import start_import from "./images/start_import.png";
import select_file_to_import from "./images/select_file_to_import.png";
import finish_import from "./images/finish_import.png";

const GERMAN_TUTORIAL = `
# Tutorial: Export von Buchungen aus Kanzlei21 und Import in Edrewe

In diesem Tutorial erfahren Sie, wie Sie Bankbuchungen aus der Kanzlei21-Webanwendung im CSV-Format exportieren und in Edrewe importieren können.

1. **Schritte zum Export in Kanzlei21:**		  
   1. **Anmeldung:** Melden Sie sich in der Kanzlei21-Webanwendung an.  
   2. **Mandanten auswählen:** Auf der linken Seite des Dashboards sehen Sie eine Sidebar. Wählen Sie hier einen Mandanten aus dem Dropdown-Menü aus.

![](${select_client}) 

3. **Bankbuchungen aufrufen:** Wurde ein Mandant ausgewählt, dann klicken Sie in der Sidebar auf **Bankbuchungen**. Standardmäßig werden alle Bankbuchungen des Standardkontos angezeigt.

![](${go_to_bank_bookings}) 

4. **Filter setzen:** Falls nötig, können Sie oben im Top-Menü ein anderes Bankkonto auswählen, Datumsfilter setzen oder weitere Filter anwenden. Hinweis: Für den Import in Edrewe müssen die Buchungen immer kalenderjahresweise exportiert werden. Dies bedeutet, dass es unmöglich ist, Buchungen aus zwei Kalenderjahren zu exportieren.

![](${set_filters}) 

5. **Exportieren:** Klicken Sie oben rechts im Top-Menü auf **CSV exportieren**. Ein Popup erscheint mit einer Zusammenfassung der zu exportierenden Buchungen. Wenn Anpassungen notwendig sind, klicken Sie auf **Abbrechen**, nehmen die Anpassungen vor und klicken erneut auf **CSV exportieren**. Sobald alles korrekt ist, klicken Sie auf **CSV jetzt exportieren** (**already implemented???**). Die CSV-Datei wird erstellt und auf Ihrem Computer heruntergeladen.

![](${export_preview}) 

6. **Erfolgsmeldung:** Eine Erfolgsmeldung wird angezeigt. Schließen Sie dieses Popup **noch nicht**. Die CSV-Datei enthält die folgenden Spalten: Umsatz, Soll-Kennzeichen, Konto, Belegdatum, Gegenkonto, IBAN, Buchungstext, Buchungsschlüssel. 

![](${success_message}) 

7. **XML-Vorlage herunterladen:** Im Erfolgsmeldung-Popup finden Sie einen Link zum Herunterladen der XML-Vorlage für den Import in Edrewe. Klicken Sie auf Herunterladen. Die Vorlage wird auf Ihrem Computer heruntergeladen. Haben Sie das Popup geschlossen, ohne die XML- Vorlage herunterzuladen, dann können Sie sie noch herunterladen, indem Sie **[hier klicken]()**.

![](${download_xml_template}) 

2. **Schritte zum Import in Edrewe:**   
   1. **Anmeldung:** Melden Sie sich in der Edrewe-Anwendung an.  
   2. **Import-Bereich auswählen:** Stellen Sie sicher, dass Sie sich im Tab **Buchführung** der Sidebar befinden. 

![](${go_to_imports_section_a})  
Von hier:

1. Ist die **Import**-Option in der Sidebar aktiv, klicken Sie direkt darauf.  
   2. Ist die **Import**-Option nicht aktiv, wählen Sie einen Mandanten auf der Hauptseite aus. Doppelklicken Sie dann auf ein Jahr (z. B. 2024). Nun sollte die **Import**-Option in der Sidebar aktiv sein. Klicken Sie darauf.

**![](${go_to_imports_section_b}) **

3. **Kanzlei21-XML-Vorlage auswählen:** Wechseln Sie zum Tab **Buchungen & Sonstige Daten**. Zunächst klicken Sie im oberen Top-Menü auf **Austausch** und wählen Sie **Laden aus einer Datei**. Im Dateiöffnen-Dialog wählen Sie die zuvor heruntergeladene XML-Vorlage von Kanzlei21 (**Name**??) aus. Klicken Sie auf **Öffnen**. Eine Erfolgsmeldung erscheint, die Sie schließen können.

![](${select_kanzlei21_template}) 

4. **Datenimport starten:** In der Liste der Import-Vorlagen finden Sie nun die neue Vorlage namens **Kanzlei21**. Wählen Sie es mit einem einfachen Klick aus.  
   Mit der ausgewählten Vorlage von Kanzlei21, klicken Sie im oberen Top-Menü auf **Auswahl Importdaten**. Ein Popup Import aus Fremddaten erscheint.  
   

![](${start_import}) 

5. **Datei auswählen:** Klicken Sie auf **Durchsuchen**, um den Ordner auszuwählen, in dem Sie die CSV-Datei von Kanzlei21 gespeichert haben (wahrscheinlich der Downloads-Ordner). Klicken Sie auf **Öffnen**. Suchen Sie die entsprechende Datei. Der Dateiname hat in der Regel ein Format wie **Kanzlei21_Buchungen_Export_JJJJ-MM-TT_HH-MM-SS.csv**. Wählen Sie die Datei durch Anklicken der Checkbox aus und klicken Sie auf **Weiter**.

![](${select_file_to_import}) 

6. **Einstellungen prüfen:** Im nächsten Bildschirm klicken Sie auf **Weiter**, es sei denn, Sie möchten Änderungen vornehmen (erweiterte Optionen). Im darauffolgenden Bildschirm wird eine Vorschau der Dateien angezeigt. Hier können Sie vor dem Import Änderungen vornehmen. Wenn alles in Ordnung ist, klicken Sie auf **Weiter**.  
   7. **Import abschließen:** Im letzten Bildschirm erscheint erneut eine Vorschau. Klicken Sie auf Fertigstellen. Eine Erfolgsmeldung erscheint, die Sie schließen können.

![](${finish_import}) 

8. **Fertig!** Sie haben Ihre Buchungen erfolgreich aus Kanzlei21 in Edrewe importiert. Sie finden diese, indem Sie den entsprechenden Mandanten und das Jahr aufrufen und in der Sidebar auf Buchungsprotokoll klicken. Die importierten Buchungen sollten am Ende der Liste stehen.

`;

const ENGLISH_TUTORIAL = `
# Tutorial for exporting bookings from Kanzlei21 and reimporting them into Edrewe

This tutorial explains how to export bank bookings from the Kanzlei21 web application in CSV format and reimport the bookings into Edrewe.

1. **Export steps on Kanzlei21:**  
   1. **Login:** Log into the Kanzlei21 web application.  
   2. **Select client:** Starting at the dashboard screen, select a client from the sidebar dropdown menu.
   ![](${select_client})  
   3. **Go to Bank Bookings:** With a client selected, click on **Bank Bookings** on the sidebar. As default, you will see all the bank bookings from the default bank account (usually the bank account 1200).  
   ![](${go_to_bank_bookings})
   4. **Set filters:** If needed, you can select a different bank account, *refine the dates and set other filters from the topbar options.* Note that you can only export bookings from one calendar year at a time.  
   ![](${set_filters})
   5. **Export preview:** Click on the **Export CSV** button at the topbar. A popup appears with a summary of the bookings that will be exported. If any adjustments are needed, click on the **Cancel** button, make the adjustments needed and then click on **Export CSV** again. Once all configurations are as desired, click on the **Export CSV now** button. A CSV file is created and downloaded into your computer.  
   ![](${export_preview})
   6. **Success message:** A success message popup will appear. **Do not close this popup yet**.The created CSV file has the following columns: Umsatz, S (Soll) Kennzeichen, Konto, Belegdatum, Gegenkonto, IBAN, Buchungstext, Buchungsschlüssel.   
   ![](${success_message})
   7. **Download XML template:** From the Success message popup, download XML template for importing into Edrewe. To do this, click on the **Download** button. The XML template will download to your computer. If you close the popup before downloading the XML template, you can also get it **[by clicking here]()**.
    ![](${download_xml_template})
   

2. **Import steps on Edrewe.**   
   1. **Login:** Log into the Erdewe app.   
   2. **Go to the import section:** Make sure you are on the **Buchführung** tab on the sidebar panel.
   ![](${go_to_imports_section_a})
    From here, do one of the following:  
      1. If the **Import** option on the sidebar is active, just click on it.  
      2. If the **Import** option is not active, choose one **Mandant** from the main screen. Then, double click on a year (e.g. **2024**). At this point it doesn’t matter which Mandant and which year. Now, the **Import** option on the sidebar should be active. Click on it. 
      ![](${go_to_imports_section_b}) 
   3. **Select Kanzlei21 template:** Go to the **Buchungen & Sonstige Daten** tab. Click on **Austausch** from the top menu. Select **Laden aus einer Datei**. On the File Open window, choose the XML template from Kanzlei21 you downloaded on a previous step. Click on **Öffnen**. This will upload the XML template. A success message will appear. You can close it.  
   ![](${select_kanzlei21_template})
   4. **Start import:** Under the import templates, a new template called **Kanzlei21** has been created. Select it with a single click. With the **Kanzlei21** template selected, click on **Auswahl Importdaten** on the top menu. A popup **Import aus Fremddaten** will appear.   
   ![](${start_import})
   5. **Select file to import:** Click on **Durchsuchen** to choose the right folder where you downloaded the CSV file from Kanzlei21 (probably your **Downloads** folder) and click **Öffnen**. Find the right file. The file will have a format like **Kanzlei21_Buchungen_YYYY-MM-DD_HH-MM-SS_XXXXXX.csv**. Select the file by clicking on the checkbox, and then click on **Weiter**.  
   ![](${select_file_to_import})
   6. **Check settings:** On the next screen, just click on **Weiter** unless you want to make any changes (advanced). The following screen shows a preview of the files. You can make modifications here before importing. Once all is ok, click on **Weiter**.   
   7. **Finish import:** The last screen shows again a preview before importing. Click on **Fertigstellen**. A success message will appear. You can close it.  
   ![](${finish_import})
   8. **Congratulations!** You imported your bookings from Kanzlei21 into Edrewe. You can find them going into the right Mandant and Year, and clicking on **Buchungsprotokoll** on the sidebar. They should be at the bottom of the list.
`;

function ExportBookingsToEdrewe() {
    const language = useCurrentLanguage();

    return (
        <Card className={styles.tutorialCard}>
            <Markdown
                components={{
                    h1: (o) => (
                        <h1 className={`${HEADINGS_CLASS_NAMES.h1} mb-4`}>{o.children}</h1>
                    ),
                    h2: (o) => (
                        <h2 className={`${HEADINGS_CLASS_NAMES.h2} mb-4 mt-4`}>
                            {o.children}
                        </h2>
                    ),
                    h3: (o) => (
                        <h3 className={`${HEADINGS_CLASS_NAMES.h3} mb-4 mt-4`}>
                            {o.children}
                        </h3>
                    ),
                    h4: (o) => (
                        <h4 className={`${HEADINGS_CLASS_NAMES.h4} mb-4 mt-4`}>
                            {o.children}
                        </h4>
                    ),
                    ul: (o) => <ul className="list-disc list-inside">{o.children}</ul>,
                    ol: (o) => <ol className="list-decimal list-inside">{o.children}</ol>,
                    li: (o) => <li className="list-decimal list-inside mb-2">{o.children}</li>,
                    a: (o) => (
                        <a href={o.href} target="_blank" rel="noopener noreferrer" >
                            {o.children}
                        </a>
                    ),
                    img: (o) => <img src={o.src} alt="" className="mb-10 mt-2" />,
                    p: (o) => <p className="mb-6">{o.children}</p>,

                }}
            >
                {language == SupportedLanguage.German
                    ? GERMAN_TUTORIAL
                    : ENGLISH_TUTORIAL}
            </Markdown>
        </Card>
    );
}

export default ExportBookingsToEdrewe