import { useContext, useEffect, useState } from "react";
import { QuickFiltersContext } from "./quick-filters-context";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { ResetFiltersButton } from "./reset-filters-button";
import { useCurrentLanguage } from "../../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../../language/supported-languages";
import { throwError } from "../../../../../../throw-error";

const GERMAN_TRANSLATIONS = {
    year: "Jahr",
    from: "Von",
    to: "Bis",
    search: "Suche",
};

const ENGLISH_TRANSLATIONS = {
    year: "Year",
    from: "From",
    to: "To",
    search: "Search",
};

export function QuickFilters() {
    const { quickFilters, setQuickFilters } =
        useContext(QuickFiltersContext) || throwError();
    const currentLanguage = useCurrentLanguage();
    const translations =
        currentLanguage === SupportedLanguage.German
            ? GERMAN_TRANSLATIONS
            : ENGLISH_TRANSLATIONS;

    const [searchValue, setSearchValue] = useState<string>("");

    const handleSearch = () => {
        setQuickFilters({
            ...quickFilters,
            search: searchValue || null,
        });
    };

    useEffect(() => {
        setSearchValue(quickFilters.search || "");
    }, [quickFilters]);

    return (
        <>
            <div className="p-inputgroup flex flex-1">
                <InputText
                    className="w-96"
                    value={searchValue || ""}
                    onChange={(e) => setSearchValue(e.target.value)}
                    placeholder={translations.search}
                    onKeyDown={(event: React.KeyboardEvent) => {
                        if (event.key === "Enter") {
                            handleSearch();
                        }
                    }}
                />
                <Button icon="pi pi-search" onClick={handleSearch} />
            </div>
            <ResetFiltersButton />
        </>
    );
}
