import { useContext } from "react";
import { BankConnection, ResultsContext } from "./results-context";
import { throwError } from "../../../../../throw-error";
import { Card } from "primereact/card";
import { Avatar } from "primereact/avatar";
import { HEADINGS_CLASS_NAMES } from "../../../../../ui/headings";
import { Carousel } from "primereact/carousel";
import { ProgressSpinner } from "primereact/progressspinner";
import { AddBankConnection } from "./add-connection/add-connection";
import DisconnectBankConnectionButton from "./delete-connection/delete-connection";
import { useCurrentLanguage } from "../../../../../language/current-language"; // Assuming this hook exists
import { SupportedLanguage } from "../../../../../language/supported-languages"; // Assuming this enum exists
import { UpdateBankConnection } from "./update-connection/update-connection";

const GERMAN_TRANSLATIONS = {
  bankAccountsFromFinAPI: "Bankkonten von finAPI",
  noBankAccountConnected: "Kein Bankkonto verbunden",
  connectedOn: "Verbunden am",
};

const ENGLISH_TRANSLATIONS = {
  bankAccountsFromFinAPI: "Bank accounts from finAPI",
  noBankAccountConnected: "No Bank Account Connected",
  connectedOn: "Connected on",
};

const dateFormatter = new Intl.DateTimeFormat(undefined, {
  dateStyle: "medium",
});

function BankConnectionItem({ connection }: { connection: BankConnection }) {
  const currentLanguage = useCurrentLanguage(); // Get the current language
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  return (
    <Card>
      <div className="flex items-center">
        <Avatar
          className="mr-5"
          image={`${connection.bank_logo_url}`}
          size="xlarge"
          shape="circle"
        />
        <div>
          <h3 className={`${HEADINGS_CLASS_NAMES.h3}`}>
            {connection.bank_name}
          </h3>
        </div>
      </div>

      <ul className="list-disc list-inside">
        {connection.accounts.map((account, idx) => (
          <li key={idx}>
            {account.account_name} - {account.account_iban}
          </li>
        ))}
      </ul>
      <p className="mt-5 font-bold">
        {`${translations.connectedOn} ${dateFormatter.format(connection.created_at)}`}
      </p>

      <div className="flex justify-between mt-4">
        <DisconnectBankConnectionButton connection_id={connection.id} />
        {connection.user_action_required && (
          <div className="ml-5">
            <UpdateBankConnection connection_id={connection.id} />
          </div>
        )}
      </div>
    </Card>
  );
}

export function Results() {
  const { results, bank_connections, bank_connections_with_update } =
    useContext(ResultsContext) || throwError();
  const currentLanguage = useCurrentLanguage(); // Get the current language
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  if (results.loading) {
    return <ProgressSpinner />;
  }

  if (!results.data) {
    return null;
  }

  return (
    <>
      <div className="flex justify-between items-center">
        <h2 className={`${HEADINGS_CLASS_NAMES.h2} mb-3`}>
          {translations.bankAccountsFromFinAPI}
        </h2>
        <AddBankConnection />
      </div>

      {(() => {
        if (results.data.length == 0) {
          return (
            <h3 className={`${HEADINGS_CLASS_NAMES.h3} text-center my-10`}>
              {translations.noBankAccountConnected}
            </h3>
          );
        } else {
          return (
            <div className="py-5">
              <Carousel
                value={[
                  ...(bank_connections_with_update ?? []),
                  ...(bank_connections ?? []),
                ]}
                numVisible={3}
                numScroll={1}
                showIndicators={false}
                itemTemplate={(item) => (
                  <BankConnectionItem connection={item} />
                )}
              />
            </div>
          );
        }
      })()}
    </>
  );
}
