import { Helmet } from "react-helmet-async";
import { useCurrentLanguage } from "../language/current-language";
import { SupportedLanguage } from "../language/supported-languages";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { ACCOUNTANT_HOME_PAGE } from "./accountant/routes";
import { CLIENT_HOME_PAGE } from "./client/routes";

const GERMAN_TRANSLATIONS = {
  homePage: "Startseite",
  accountant: "Buchhalter",
  client: "Mandant",
};

const ENGLISH_TRANSLATIONS = {
  homePage: "Home",
  accountant: "Accountant",
  client: "Client",
};

export function HomePage() {
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>{`${translations.homePage} | Kanzlei21`}</title>
      </Helmet>
      <div className="flex gap-10 h-screen items-center justify-center">
        <Button
          severity={"secondary"}
          className="flex flex-col gap-2 items-center aspect-square h-[200px]"
          onClick={() => navigate(ACCOUNTANT_HOME_PAGE.getHref())}
        >
          <i className={"pi pi-building text-[3rem]"}></i>
          <span className="text-[1.5rem]">{translations.accountant}</span>
        </Button>
        <Button
          severity={"secondary"}
          className="flex flex-col gap-2 items-center aspect-square h-[200px]"
          onClick={() => navigate(CLIENT_HOME_PAGE.getHref())}
        >
          <i className={"pi pi-user text-[3rem]"}></i>
          <span className="text-[1.5rem]">{translations.client}</span>
        </Button>
      </div>
    </>
  );
}
