import { Menu as PrimeReactMenu } from "primereact/menu";
import styles from "./accounting-firm-administration-menu.module.scss";
import { useNavigate } from "react-router-dom";
import { useCurrentLanguage } from "../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../language/supported-languages";
import { ACCOUNTING_FIRM_ROUTES } from "../../accounting-firm-routes";
import { AccountingFirm } from "../../accounting-firm-loader";
import { HEADINGS_CLASS_NAMES } from "../../../../../ui/headings";

const GERMAN_TRANSLATIONS = {
  accountants: "Buchhalter",
  accountingFirmInformation: "Kanzleiinformationen",
};

const ENGLISH_TRANSLATIONS = {
  accountants: "Accountants",
  accountingFirmInformation: "Accounting Firm Information",
};

export function Menu(props: { accountingFirm: AccountingFirm }) {
  const navigate = useNavigate();
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  return (
    <div className={styles.container}>
      <span className={`${HEADINGS_CLASS_NAMES.h2} text-center py-12 px-2`}>
        {props.accountingFirm.name}
      </span>
      <PrimeReactMenu
        className={styles.primeReactMenu}
        model={[
          {
            label: translations.accountants,
            className: `${window.location.pathname.includes(ACCOUNTING_FIRM_ROUTES.accountants.path) ? "p-menuitem-active" : ""}`,
            command: () => {
              navigate(ACCOUNTING_FIRM_ROUTES.accountants.getHref());
            },
          },
        ]}
      />

      <PrimeReactMenu
        className={styles.primeReactMenu}
        model={[
          {
            label: translations.accountingFirmInformation,
            className: `${window.location.pathname.includes(ACCOUNTING_FIRM_ROUTES.accountingFirmInformation.path) ? "p-menuitem-active" : ""}`,
            command: () => {
              navigate(
                ACCOUNTING_FIRM_ROUTES.accountingFirmInformation.getHref()
              );
            },
          },
        ]}
      />
    </div>
  );
}
