import { Card } from "primereact/card";
import styles from "./help-support.module.scss";
import { useCurrentLanguage } from "../../../language/current-language";
import { SupportedLanguage } from "../../../language/supported-languages";
import { Link } from "react-router-dom";
import { CONNECT_BANK_ACCOUNT } from "./connect-bank-account/connect-bank-account.route";
import { CREATE_AN_ACCOUNT } from "./create-an-account/create-an-account.route";
import { Helmet } from "react-helmet-async";

const GERMAN_TRANSLATIONS = {
  helpSupport: "Hilfe & Support",
  assistanceText:
    "Benötigen Sie Unterstützung? Wir helfen Ihnen gern. Sie können:",
  contactSupport: "Support kontaktieren",
  reportBugs: "Fehler melden",
  askQuestions: "Fragen stellen",
  suggestFeatures: "Funktionen vorschlagen",
  sendEmailText: "Senden Sie uns eine E-Mail an",
  howTo: "Tutorials",
  connectBankAccount: "Bankkonto mit FinAPI verbinden",
  createAnAccount: "Ein Konto erstellen",
};

const ENGLISH_TRANSLATIONS = {
  helpSupport: "Help & Support",
  assistanceText: "Need assistance? We're here to help! You can:",
  contactSupport: "Contact Support",
  reportBugs: "Report Bugs",
  askQuestions: "Ask Questions",
  suggestFeatures: "Suggest Features",
  sendEmailText: "Send us an email at",
  howTo: "How to",
  connectBankAccount: "Connect Bank Account to FinAPI",
  createAnAccount: "Create an account",
};

export default function AccountingClientHelpSupportPage() {
  const currentLanguage = useCurrentLanguage();

  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  return (
    <>
      <Helmet>
        <title>{`${translations.helpSupport} | Kanzlei21`}</title>
      </Helmet>

      <div className={`${styles.container} ${styles.dFlexColumn}`}>
        <Card title={translations.helpSupport}>
          <div className={styles.dFlexColumn}>
            <p>{translations.assistanceText}</p>
            <ul className="list-disc list-inside">
              <li>{translations.reportBugs}</li>
              <li>{translations.askQuestions}</li>
              <li>{translations.suggestFeatures}</li>
            </ul>
            <p className={styles.bigText}>
              {translations.sendEmailText}{" "}
              <a href="mailto:support@kanzlei21.com">support@kanzlei21.com</a>
            </p>
          </div>
        </Card>
        <Card title={translations.howTo}>
          <div>
            <ul className="list-disc list-inside leading-[2]">
              <li>
                <Link
                  to={CONNECT_BANK_ACCOUNT.getHref()}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <b>{translations.connectBankAccount}</b>
                </Link>
              </li>
              <li>
                <Link
                  to={CREATE_AN_ACCOUNT.getHref()}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <b>{translations.createAnAccount}</b>
                </Link>
              </li>
            </ul>
          </div>
        </Card>
      </div>
    </>
  );
}
