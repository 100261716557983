import { z } from "zod";
import { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Actions } from "./actions/actions";
import { Details } from "./details/details";
import styles from "./details.module.scss";
import { Panel } from "primereact/panel";
import {
  ResultsContext,
  Booking,
  BookingSchema,
} from "../../results/results-context";
import { throwError } from "../../../../../../../throw-error";
import { Divider } from "primereact/divider";
import { useMainApi } from "../../../../../../../main-api";
import { useAccountingClientId } from "../../../accounting-client-id";
import { Toast } from "primereact/toast";
import {
  getCommunicationErrorTitle,
  getCommunicationErrorMessage,
} from "../../../../../../../communication-errors/communication-error-messages";
import { useCurrentLanguage } from "../../../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../../../language/supported-languages";
import { BookingsPageBankAccountsContext } from "../../top-section/navbar/quick-filters/bank-accounts-context";
import { Column2 } from "./column-2/column-2";
import MeasureSize from "../../../../../../../ui/measure-size";
import { RelatedAccountsContext } from "../../top-section/navbar/related-accounts";
import { Tag } from "primereact/tag";

const GERMAN_TRANSLATIONS = {
  success: "Erfolg",
  successMessage: "Buchungdetails erfolgreich aktualisiert",
  error: "Fehler",
  amountValidationMessage: "Bitte geben Sie einen Wert größer als 0 ein.",
  splitBookingLeg: "Teilbuchung",
  actionNeeded: "Handlungsbedarf",
  correctedByAccountant: "Manuell korrigiert",
  manuallyApproved: "Manuell genehmigt",
  processed: "Vollautomatisiert",
  historical: "Historisch",
  onlyOneInvoiceNumberCanBeEntered:
    "Es kann nur eine Rechnungsnummer pro Buchung eingegeben werden.",
};

const ENGLISH_TRANSLATIONS = {
  success: "Success",
  successMessage: "Booking details successfully updated",
  error: "Error",
  amountValidationMessage: "Please enter a value greater than 0",
  splitBookingLeg: "Split Booking Leg",
  actionNeeded: "Action Needed",
  correctedByAccountant: "Corrected by Accountant",
  manuallyApproved: "Manually Approved",
  processed: "Processed",
  historical: "Historical",
  onlyOneInvoiceNumberCanBeEntered:
    "Only one invoice number can be entered per booking",
};

export const EditSplitBookingLegDetailsFormSchema = (translations: {
  amountValidationMessage: string;
  onlyOneInvoiceNumberCanBeEntered: string;
}) =>
  z.object({
    counter_account_id: z.string().uuid().nullable(),
    tax_code_id: z.string().uuid().nullable(),
    amount: z.coerce
      .number()
      .positive({ message: translations.amountValidationMessage }),
    is_debit: z.boolean(),
    invoice_numbers: z.array(z.string()),
  });

export type EditSplitBookingLegDetailsFormValue = z.infer<
  ReturnType<typeof EditSplitBookingLegDetailsFormSchema>
>;

export function SplitBookingLegDetailsPanel(props: {
  selectedBooking: Booking;
  toastRef: React.RefObject<Toast>;
}) {
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const { results, setResults } = useContext(ResultsContext) || throwError();

  const mainApi = useMainApi();

  const accountingClientId = useAccountingClientId();

  const { selectedBankAccount } =
    useContext(BookingsPageBankAccountsContext) || throwError();

  const { refresh } = useContext(RelatedAccountsContext) || throwError();

  const form = useForm<EditSplitBookingLegDetailsFormValue>({
    resolver: zodResolver(EditSplitBookingLegDetailsFormSchema(translations)),
    defaultValues: {
      counter_account_id: props.selectedBooking.counter_account?.id ?? null,
      tax_code_id: props.selectedBooking.tax_code?.id ?? null,
      amount: props.selectedBooking.amount,
      is_debit: props.selectedBooking.is_debit,
      invoice_numbers: props.selectedBooking.invoice_numbers,
    },
  });

  useEffect(() => {
    form.reset({
      counter_account_id: props.selectedBooking.counter_account?.id ?? null,
      tax_code_id: props.selectedBooking.tax_code?.id ?? null,
      amount: props.selectedBooking.amount,
      is_debit: props.selectedBooking.is_debit,
      invoice_numbers: props.selectedBooking.invoice_numbers,
    });
  }, [form, props.selectedBooking]);

  const onSubmit = async (formData: EditSplitBookingLegDetailsFormValue) => {
    const urlSearchParams = new URLSearchParams();

    if (!selectedBankAccount) {
      throw new Error();
    }

    urlSearchParams.append("accounting_client_id", accountingClientId);

    urlSearchParams.append("booking_id", props.selectedBooking.id);

    urlSearchParams.append(
      "selected_account_id",
      selectedBankAccount.account_id
    );

    const res = await mainApi.fetchJSON({
      method: "PUT",
      path: `/bookings/split_bookings/edit_leg?${urlSearchParams.toString()}`,
      body: {
        ...formData,
        counter_account_id: formData.counter_account_id,
        tax_code_id: formData.tax_code_id,
        amount: formData.amount,
        is_debit: formData.is_debit,
        invoice_numbers: formData.invoice_numbers,
      },
      schema: z.object({
        status: z.literal(200),
        body: BookingSchema,
      }),
    });

    if (res.error) {
      props.toastRef.current?.show({
        severity: "error",
        summary: getCommunicationErrorTitle(res.error) ?? translations.error,
        detail: getCommunicationErrorMessage(res.error),
      });

      return;
    }

    refresh();

    setResults((results) => ({
      ...results,
      data: results.data && {
        ...results.data,
        bookings: results.data.bookings.map((booking) =>
          booking.id === props.selectedBooking.id
            ? { ...booking, ...res.response.body }
            : booking
        ),
      },
    }));

    props.toastRef.current?.show({
      severity: "success",
      summary: translations.success,
      detail: translations.successMessage,
    });
  };

  const transaction = props.selectedBooking.financial_transaction_id
    ? results.data?.financial_transactions[
        props.selectedBooking.financial_transaction_id
      ]
    : undefined;

  return (
    <>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Panel
          pt={{
            content: {
              className: "p-0",
            },
          }}
          headerTemplate={() => (
            <div className="w-full flex justify-between items-center p-3">
              <div className={"flex align-center gap-3"}>
                <Tag
                  className={`${(() => {
                    if (props.selectedBooking.status === "HISTORICAL") {
                      return "bg-historical text-historicalText";
                    } else if (
                      props.selectedBooking.status === "MANUALLY_APPROVED"
                    ) {
                      return "bg-manuallyApproved text-manuallyApprovedText";
                    }
                  })()}`}
                  severity={(() => {
                    if (props.selectedBooking.status === "ACTION_NEEDED") {
                      return "danger";
                    } else if (
                      props.selectedBooking.status === "CORRECTED_BY_ACCOUNTANT"
                    ) {
                      return "info";
                    } else if (
                      props.selectedBooking.status === "MANUALLY_APPROVED"
                    ) {
                      return undefined;
                    } else if (
                      props.selectedBooking.status ===
                      "PREDICTED_WITHOUT_ISSUES"
                    ) {
                      return "success";
                    } else if (props.selectedBooking.status === "HISTORICAL") {
                      return undefined;
                    } else {
                      throw new Error();
                    }
                  })()}
                  value={(() => {
                    if (props.selectedBooking.status === "ACTION_NEEDED") {
                      return translations.actionNeeded;
                    } else if (
                      props.selectedBooking.status === "CORRECTED_BY_ACCOUNTANT"
                    ) {
                      return translations.correctedByAccountant;
                    } else if (
                      props.selectedBooking.status === "MANUALLY_APPROVED"
                    ) {
                      return translations.manuallyApproved;
                    } else if (
                      props.selectedBooking.status ===
                      "PREDICTED_WITHOUT_ISSUES"
                    ) {
                      return translations.processed;
                    } else if (props.selectedBooking.status === "HISTORICAL") {
                      return translations.historical;
                    } else {
                      throw new Error();
                    }
                  })()}
                />
                <b className="text-xl">
                  {translations.splitBookingLeg} - {""}
                  {transaction?.applicant_name ?? <></>}
                </b>
              </div>
              <Actions
                selectedBooking={props.selectedBooking}
                isSubmitting={form.formState.isSubmitting}
                toastRef={props.toastRef}
              />
            </div>
          )}
        >
          <div className={styles.row}>
            <div className={`${styles.details}`}>
              <Details selectedBooking={props.selectedBooking} form={form} />
            </div>
            <Divider className={styles.divider} layout="vertical" />
            <div className={styles.column2}>
              <MeasureSize>
                {({ height }) => (
                  <div
                    className={`${styles.overflowYAuto}`}
                    style={{
                      maxHeight: height,
                    }}
                  >
                    <Column2 selectedBooking={props.selectedBooking} />
                  </div>
                )}
              </MeasureSize>
            </div>
          </div>
        </Panel>
      </form>
    </>
  );
}
