import { ReactNode } from "react";
import styles from "./bottom-section.module.scss"
import { ResultsContextProvider } from "./results/results-context";
import { Results } from "./results/results";

function Page(props: { children: ReactNode }) {
    return <div className={styles.page}>{props.children}</div>;
}

export function BottomSection() {
    return (
        <>
            <ResultsContextProvider>
                <Page>
                    <Results />
                </Page>
            </ResultsContextProvider>

        </>
    )
}
