import { Route, Routes, BrowserRouter } from "react-router-dom";
import { AccountantAuthenticationStateProvider } from "./accountants/authentication/authentication-state";
import { PrimeReactProvider } from "primereact/api";
import { AccountantAuthEffects } from "./accountants/authentication/authentication-effects";
import AccountantHomePage from "./app/accountant/page";
import LoginPage from "./app/accountant/login/page";
import { AccountantUserForgotPassword } from "./app/accountant/forgot-password/forgot-password";
import { AccountantUserResetPassword } from "./app/accountant/reset-password/reset-password";
import Layout from "./app/accountant/layout";
import { Layout as ClientLayout } from "./app/client/layout";
import { AccountantUserSettings } from "./app/accountant/settings/page";
import EditClientsPage from "./app/accountant/accounting-clients/accounting-client-id/edit/page";
import { ClientAuthEffects } from "./accounting-clients/authentication/authentication-effects";
import { ClientAuthenticationStateProvider } from "./accounting-clients/authentication/authentication-state";
import { Outlet } from "react-router-dom";
import { ClientLoginPage } from "./app/client/login/page";
import { ClientUserForgotPassword } from "./app/client/forgot-password/forgot-password";
import { ClientUserResetPassword } from "./app/client/reset-password/page";
import { ClientHomePage } from "./app/client/page/page";
import { AccountantAuthenticatedPage } from "./accountants/authentication/authenticated-page";
import { ClientAuthenticatedPage } from "./accounting-clients/authentication/authenticated-page";
import { AcceptInvitePage } from "./app/client/accept-invite/page";
import { BookingsPage } from "./app/accountant/accounting-clients/accounting-client-id/bookings/page";
// import { DocumentsPage as AccountingClientDocumentsPage } from "./app/client/documents/page";
import BankTransactionsPage from "./app/accountant/accounting-clients/accounting-client-id/bank-transactions/page";
import { NotFoundPage } from "./app/not-found/page";
import PrivacyPolicy from "./app/client/privacy-policy/privacy-policy";
import TermsAndConditions from "./app/client/terms-and-conditions/terms_and_conditions";
import { AccountingClientsProvider } from "./app/accountant/menu/accounting-clients-context";
import { DocumentsPage } from "./app/accountant/accounting-clients/accounting-client-id/documents/page";
import HelpSupportPage from "./app/accountant/help-support/help-support";
import CreateAccountingClientsPage from "./app/accountant/accounting-clients/create/create-accounting-clients-page";
import CreateAccountingClientSteps from "./app/accountant/accounting-clients/accounting-client-id/create/create-accounting-client-steps";
import { Notifications } from "./app/accountant/notifications/notifications-page";
import ImportGDPDUToK21 from "./app/accountant/help-support/import-gdpdu-to-k21/import-gdpdu-to-k21";
import OnboardClientToK21 from "./app/accountant/help-support/onboard-client/onboard-client";
import ExportGDPDUFromEdrewe from "./app/accountant/help-support/export-gdpdu-from-edrewe/export-gdpdu-from-edrewe";
import ExportBookingsToEdrewe from "./app/accountant/help-support/export-bookings-to-edrewe/export-bookings-to-edrewe";
import AccountingClientHelpSupportPage from "./app/client/help-support/help-support";
import ConnectBankAccount from "./app/client/help-support/connect-bank-account/connect-bank-account";
import CreateAnAccount from "./app/client/help-support/create-an-account/create-an-account";
import { BankConnectionPage } from "./app/client/bank-connections/page";
import { AccountingFirmLoader } from "./app/accountant/accounting-firm/accounting-firm-loader";
import AccountingFirmAdministrationLayout from "./app/accountant/accounting-firm/layout/accounting-firm-administration-layout";
import { Accountants } from "./app/accountant/accounting-firm/accountants/accountants";
import AccountingFirmInformationForm from "./app/accountant/accounting-firm/accounting-firm-information/accounting-firm-information-form";
import { HelmetProvider } from "react-helmet-async";
import { ClientUserSettings } from "./app/client/user-settings/user-settings";
import { HomePage } from "./app/home-page";

const GIT_COMMIT_SHA = import.meta.env.VITE_GIT_COMMIT_SHA;

function App() {
  return (
    <>
      <span className={"hidden"}>{GIT_COMMIT_SHA ?? "none"}</span>
      <HelmetProvider>
        <AccountantAuthenticationStateProvider>
          <ClientAuthenticationStateProvider>
            <PrimeReactProvider>
              <AccountantAuthEffects />
              <ClientAuthEffects />
              <BrowserRouter>
                <Routes>
                  <Route index element={<HomePage />} />
                  <Route path="accountant">
                    <Route path="login" element={<LoginPage />} />
                    <Route
                      path="forgot-password"
                      element={<AccountantUserForgotPassword />}
                    />
                    <Route
                      path="reset-password"
                      element={<AccountantUserResetPassword />}
                    />
                    <Route
                      element={
                        <AccountantAuthenticatedPage>
                          <AccountingClientsProvider>
                            <Outlet />
                          </AccountingClientsProvider>
                        </AccountantAuthenticatedPage>
                      }
                    >
                      <Route
                        index
                        element={
                          <Layout>
                            <AccountantHomePage />
                          </Layout>
                        }
                      />
                      <Route
                        path="settings"
                        element={
                          <Layout>
                            <AccountantUserSettings />
                          </Layout>
                        }
                      />

                      <Route path="help-support">
                        <Route
                          index
                          element={
                            <Layout>
                              <HelpSupportPage />
                            </Layout>
                          }
                        />
                        <Route
                          path="export-bookings-to-edrewe"
                          element={
                            <Layout>
                              <ExportBookingsToEdrewe />
                            </Layout>
                          }
                        />
                        <Route
                          path="import-gdpdu-to-k21"
                          element={
                            <Layout>
                              <ImportGDPDUToK21 />
                            </Layout>
                          }
                        />
                        <Route
                          path="onboard-client"
                          element={
                            <Layout>
                              <OnboardClientToK21 />
                            </Layout>
                          }
                        />
                        <Route
                          path="export-gdpdu-from-edrewe"
                          element={
                            <Layout>
                              <ExportGDPDUFromEdrewe />
                            </Layout>
                          }
                        />
                      </Route>

                      <Route path="accounting-clients">
                        <Route
                          path="create"
                          element={<CreateAccountingClientsPage />}
                        />
                        <Route path=":accountingClientId">
                          <Route
                            path="create"
                            element={<CreateAccountingClientSteps />}
                          />
                          <Route
                            path="edit/*"
                            element={
                              <Layout>
                                <EditClientsPage />
                              </Layout>
                            }
                          />
                          {/* <Route path="invoices" element={<InvoicesPage />} /> */}
                          <Route
                            path="bank-transactions"
                            element={
                              <Layout>
                                <BankTransactionsPage />
                              </Layout>
                            }
                          />
                          <Route
                            path="bank-bookings"
                            element={
                              <Layout>
                                <BookingsPage />
                              </Layout>
                            }
                          />
                          <Route
                            path="documents"
                            element={
                              <Layout>
                                <DocumentsPage />
                              </Layout>
                            }
                          />
                        </Route>
                      </Route>
                      <Route
                        path="notifications"
                        element={
                          <Layout>
                            <Notifications />
                          </Layout>
                        }
                      />
                      <Route
                        path="accounting-firm/*"
                        element={
                          <AccountingFirmLoader>
                            {({
                              accountingFirm,
                              onAccountingFirmDataUpdated,
                            }) => (
                              <AccountingFirmAdministrationLayout
                                accountingFirm={accountingFirm}
                              >
                                <Routes>
                                  <Route
                                    path="accountants"
                                    element={
                                      <Accountants
                                        accountingFirm={accountingFirm}
                                        onAccountingFirmDataUpdated={
                                          onAccountingFirmDataUpdated
                                        }
                                      />
                                    }
                                  />
                                  <Route
                                    path="accounting-firm-information"
                                    element={
                                      <AccountingFirmInformationForm
                                        accountingFirm={accountingFirm}
                                        onAccountingFirmDataUpdated={
                                          onAccountingFirmDataUpdated
                                        }
                                      />
                                    }
                                  />
                                </Routes>
                              </AccountingFirmAdministrationLayout>
                            )}
                          </AccountingFirmLoader>
                        }
                      />
                    </Route>
                  </Route>

                  <Route path="client">
                    <Route
                      element={
                        <ClientAuthenticatedPage>
                          <Outlet />
                        </ClientAuthenticatedPage>
                      }
                    >
                      <Route
                        index
                        element={
                          <ClientLayout>
                            <ClientHomePage />
                          </ClientLayout>
                        }
                      />
                      {/* <Route
                    path="documents"
                    element={
                      <ClientLayout>
                        <AccountingClientDocumentsPage />
                      </ClientLayout>
                    }
                      />*/}
                      <Route
                        path="bank-connections"
                        element={
                          <ClientLayout>
                            <BankConnectionPage />
                          </ClientLayout>
                        }
                      />
                      <Route
                        path="settings"
                        element={
                          <ClientLayout>
                            <ClientUserSettings />
                          </ClientLayout>
                        }
                      />
                    </Route>

                    <Route path="help-support">
                      <Route
                        index
                        element={
                          <ClientLayout>
                            <AccountingClientHelpSupportPage />
                          </ClientLayout>
                        }
                      />
                      <Route
                        path="connect-bank-account"
                        element={
                          <ClientLayout>
                            <ConnectBankAccount />
                          </ClientLayout>
                        }
                      />
                      <Route
                        path="create-an-account"
                        element={
                          <ClientLayout>
                            <CreateAnAccount />
                          </ClientLayout>
                        }
                      />
                    </Route>

                    <Route path="login" element={<ClientLoginPage />} />
                    <Route
                      path="forgot-password"
                      element={<ClientUserForgotPassword />}
                    />
                    <Route
                      path="reset-password"
                      element={<ClientUserResetPassword />}
                    />
                    <Route
                      path="accept-invite"
                      element={<AcceptInvitePage />}
                    />

                    <Route path="privacy-policy" element={<PrivacyPolicy />} />

                    <Route
                      path="terms-and-conditions"
                      element={<TermsAndConditions />}
                    />
                  </Route>
                  <Route path="*" element={<NotFoundPage />} />
                </Routes>
              </BrowserRouter>
            </PrimeReactProvider>
          </ClientAuthenticationStateProvider>
        </AccountantAuthenticationStateProvider>
      </HelmetProvider>
    </>
  );
}

export default App;
