import { Button } from "primereact/button";
import styles from "./actions.module.scss";
import { useContext } from "react";
import { throwError } from "../../../../../../../../throw-error";
import { ResultsContext } from "../../../results/results-context";
import { useCurrentLanguage } from "../../../../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../../../../language/supported-languages";

const GERMAN_TRANSLATIONS = {
  approve: "Genehmigen",
};

const ENGLISH_TRANSLATIONS = {
  approve: "Approve",
};

export function Actions({
  isSubmitting,
  hasHistoricalSplitBookingLegs,
}: {
  isSubmitting: boolean;
  hasHistoricalSplitBookingLegs: boolean;
}) {
  const { setSelected } = useContext(ResultsContext) || throwError();

  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  return (
    <div className={styles.actionBar}>
      <Button
        severity={"success"}
        icon="pi pi-check"
        label={translations.approve}
        className={styles.actionButton}
        disabled={isSubmitting || hasHistoricalSplitBookingLegs}
        loading={isSubmitting}
        type="submit"
      />
      <Button
        severity="secondary"
        text
        icon="pi pi-times"
        type="button"
        onClick={() => setSelected(undefined)}
      />
    </div>
  );
}
