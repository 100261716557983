import { Button } from "primereact/button";
import styles from "./actions.module.scss";
import { useContext } from "react";
import { throwError } from "../../../../../../../../throw-error";
import { Booking, ResultsContext } from "../../../results/results-context";
import { useCurrentLanguage } from "../../../../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../../../../language/supported-languages";

const GERMAN_TRANSLATIONS = {
  splitBooking: "Splittbuchung",
  approve: "Genehmigen",
};

const ENGLISH_TRANSLATIONS = {
  splitBooking: "Split Booking",
  approve: "Approve",
};

export function Actions({
  selectedBooking,
  isSubmitting,
}: {
  selectedBooking: Booking;
  isSubmitting: boolean;
}) {
  const { setSelected } = useContext(ResultsContext) || throwError();

  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  return (
    <div className={styles.actionBar}>
      {selectedBooking.status !== "HISTORICAL" && (
        <Button
          outlined
          icon="pi pi-arrow-right-arrow-left"
          label={translations.splitBooking}
          type="button"
          onClick={() =>
            setSelected({
              type: "transaction",
              id: selectedBooking.financial_transaction_id || throwError(),
            })
          }
        />
      )}
      <Button
        icon="pi pi-check"
        severity="success"
        label={translations.approve}
        disabled={isSubmitting || selectedBooking.status == "HISTORICAL"}
        loading={isSubmitting}
        type="submit"
      />
      <Button
        icon="pi pi-times"
        severity="secondary"
        text
        type="button"
        onClick={() => setSelected(undefined)}
      />
    </div>
  );
}
