import { Card } from 'primereact/card';
import styles from "./import-gdpdu-to-k21.module.scss"
import Markdown from 'react-markdown';
import { SupportedLanguage } from '../../../../language/supported-languages';
import { HEADINGS_CLASS_NAMES } from '../../../../ui/headings';
import { useCurrentLanguage } from '../../../../language/current-language';
import select_client from "./images/select_client.png"
import upload_gdpdu_file from "./images/upload_gdpdu_file.png"
import finish from "./images/finish.png"
import { EXPORT_GDPDU_FROM_EDREWE } from '../export-gdpdu-from-edrewe/export-gdpdu-from-edrewe.route';
import { EXPORT_BOOKINGS_TO_EDREWE } from '../export-bookings-to-edrewe/export-bookings-to-edrewe.route';
import { IMPORT_GDPDU_TO_K21 } from './import-gdpdu-to-k21.route';

const GERMAN_TUTORIAL = `
# Tutorial zum Import einer GDPDU-Datei in Kanzlei21

Dieses Tutorial erklärt, wie eine GDPDU-Datei in Kanzlei21 importiert wird. Es wird vorausgesetzt, dass Sie bereits eine GDPDU-Datei für den entsprechenden Mandanten aus Edrewe exportiert haben. Eine Anleitung zum Exportieren einer GDPDU-Datei aus Edrewe finden Sie ***[hier](${IMPORT_GDPDU_TO_K21.getHref()})***.

**Wichtige Hinweise:**

* Es kann jeweils nur eine GDPDU-Datei für ein Jahr und einen Mandanten importiert werden. Wenn Sie Daten für mehrere Jahre (z. B. 2023 und 2024) importieren müssen, wiederholen Sie den Vorgang für jedes Jahr.  
* Dieses Tutorial konzentriert sich auf den Import einer GDPDU-Datei für einen bestehenden Mandanten. Wenn Sie einen Mandanten durch den Import einer GDPDU-Datei erstellen möchten, folgen Sie ***[diesem anderen Tutorial](${IMPORT_GDPDU_TO_K21.getHref()})***.

---

Schritte

1. **Anmeldung:** Melden Sie sich bei der Kanzlei21-Webanwendung an.  
2. **Mandanten auswählen:** Suchen Sie auf der Dashboard-Seite den entsprechenden Mandanten im Hauptmenü. Klicken Sie auf den Mandanten (2.1), damit das **Gehe zu**-Kontextmenü erscheint. Wählen Sie **Mandanten bearbeiten** (2.2).  
![](${select_client})
3. **GDPDU-Datei hochladen:** Navigieren Sie zum **GDPdU** (3.1). Klicken Sie auf den Button **GDPdU-Datei hochladen** (3.2). Wählen Sie die GDPDU-Datei, die Sie aus Edrewe exportiert haben. Klicken Sie auf **Öffnen**, um die Datei hochzuladen. 
![](${upload_gdpdu_file})
4. **Abschluss:** Die GDPDU-Datei wird hochgeladen und verarbeitet. Nach Abschluss des Prozesses erscheint der Status „Erfolg“. Beachten Sie, dass die Verarbeitung einige Minuten dauern kann. Sie können diese Seite verlassen und Ihre Arbeit fortsetzen, während der Prozess im Hintergrund läuft. Aktualisieren Sie die Seite, falls der Status nicht automatisch auf „Erfolg“ wechselt. Neu importierte Transaktionen werden am folgenden Tag um 7:00 Uhr (deutsche Zeit) angezeigt.
![](${finish})

Relevante Links:  
***[Tutorial zum Exportieren einer GDPDU-Datei aus Edrewe](${EXPORT_GDPDU_FROM_EDREWE.getHref()})***  
***[Tutorial: Export von Buchungen aus Kanzlei21 und Import in Edrewe](${EXPORT_BOOKINGS_TO_EDREWE.getHref()})*** 
`;

const ENGLISH_TUTORIAL = `
# Tutorial for importing a GDPDU file on Kanzlei21

This tutorial explains how to import a GDPDU file into Kanzlei21. It assumes you have already exported a GDPDU file for the corresponding client from Edrewe. To learn how to export a GDPDU file on Edrewe,  ***click here*** **(placeholder for URL tutorial 4)**. 

**Important Notes:**

* You can only import one GDPDU file for one year and one client at a time. If you need to import data for multiple years (e.g., 2023 and 2024), you will need to repeat this process for each year.  
* This tutorial focuses on importing a GDPDU file for an existing client. If you wish to create a client by importing a GDPDU file, ***[follow this other tutorial](${IMPORT_GDPDU_TO_K21.getHref()})***.

---

Steps  
	

1. **Login:** Log into the Kanzlei21 web application.  
2. **Select client:** From the dashboard screen, locate and click on the corresponding client (2.1) in the main menu. By clicking on the client, the **Gehe zu** context menu opens. Select **Mandanten bearbeiten** (2.2).  
![](${select_client})
3. **Upload GDPdU file:** Navigate to the **GDPdU** tab (3.1). Once here, click on the **GDPdU-Datei hochladen** button (3.2). Select the corresponding GDPDU file you exported from Edrewe. Click on **Open** to upload the file.  
![](${upload_gdpdu_file})
4. **Finish:** The GDPDU file will begin uploading and processing. Once the process is complete, you will see a “Success” status. Note that processing can take several minutes. You can leave this page and continue your work while the process runs in the background. If the status does not automatically update to “Success,” try refreshing the page. Note that newly imported transactions will be visible at 7:00 AM (German time) the following day.
![](${finish})
   
Relevant links:  
***[Tutorial for exporting a GDPDU file from Edrewe](${EXPORT_GDPDU_FROM_EDREWE.getHref()})***  
***[Tutorial for exporting bookings from Kanzlei21 and reimporting them into Edrewe](${EXPORT_BOOKINGS_TO_EDREWE.getHref()})***
`;

function ImportGDPDUToK21() {
    const language = useCurrentLanguage();

    return (
        <Card className={styles.tutorialCard}>
            <Markdown
                components={{
                    h1: (o) => (
                        <h1 className={`${HEADINGS_CLASS_NAMES.h1} mb-4`}>{o.children}</h1>
                    ),
                    h2: (o) => (
                        <h2 className={`${HEADINGS_CLASS_NAMES.h2} mb-4 mt-4`}>
                            {o.children}
                        </h2>
                    ),
                    h3: (o) => (
                        <h3 className={`${HEADINGS_CLASS_NAMES.h3} mb-4 mt-4`}>
                            {o.children}
                        </h3>
                    ),
                    h4: (o) => (
                        <h4 className={`${HEADINGS_CLASS_NAMES.h4} mb-4 mt-4`}>
                            {o.children}
                        </h4>
                    ),
                    ul: (o) => <ul className="list-disc list-inside">{o.children}</ul>,
                    ol: (o) => <ol className="list-decimal list-inside">{o.children}</ol>,
                    li: (o) => <li className="list-decimal list-inside mb-2">{o.children}</li>,
                    a: (o) => (
                        <a href={o.href} target="_blank" rel="noopener noreferrer" >
                            {o.children}
                        </a>
                    ),
                    img: (o) => <img src={o.src} alt="" className="mb-10 mt-2" />,
                    p: (o) => <p className="mb-6">{o.children}</p>,
                }}
            >
                {language == SupportedLanguage.German
                    ? GERMAN_TUTORIAL
                    : ENGLISH_TUTORIAL}
            </Markdown>
        </Card>
    );
}

export default ImportGDPDUToK21