import styles from "./details.module.scss";
import { Divider } from "primereact/divider";
import { Detail } from "./detail/detail-column";
import {
  Booking,
  FinancialTransaction,
} from "../../../results/results-context";
import { useCurrentLanguage } from "../../../../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../../../../language/supported-languages";
import { SplitBookingTable } from "./split-booking-table";
import { UseFormReturn } from "react-hook-form";
import { EditSplitBookingFormValue } from "../split-booking-details-panel";
import { isSplitBookingADebit } from "../../../../../../../../bookings/split-bookings/direction";

// Intl formatter that formats dates as '11th January 2023'
const dateFormatter = new Intl.DateTimeFormat(undefined, {
  day: "numeric",
  month: "short",
  year: "numeric",
});

const GERMAN_TRANSLATIONS = {
  transactionDate: "Transaktionsdatum",
  applicantName: "Name",
  amount: "Betrag",
  description: "Beschreibung",
  status: "Status",
  actionNeeded: "Handlungsbedarf",
  correctedByAccountant: "Manuell korrigiert",
  processed: "Vollautomatisiert",
  historical: "Historisch",
  creditDebit: "Soll / Haben",
  debit: "Soll",
  credit: "Haben",
};

const ENGLISH_TRANSLATIONS = {
  transactionDate: "Transaction Date",
  applicantName: "Applicant Name",
  amount: "Amount",
  description: "Description",
  status: "Status",
  actionNeeded: "Action Needed",
  correctedByAccountant: "Corrected by Accountant",
  processed: "Processed",
  historical: "Historical",
  creditDebit: "Credit / Debit",
  debit: "Debit",
  credit: "Credit",
};

export function Details(props: {
  financialTransaction: FinancialTransaction;
  legs: Booking[];
  form: UseFormReturn<EditSplitBookingFormValue>;
  hasHistoricalSplitBookingLegs: boolean;
}) {
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const isDebit = isSplitBookingADebit(props.financialTransaction.amount);

  return (
    <div className={styles.rows}>
      <div className={styles.row}>
        <div className={styles.column}>
          <Detail label={translations.transactionDate}>
            <p className={styles.bigText}>
              {props.financialTransaction?.date ? (
                dateFormatter.format(props.financialTransaction.date)
              ) : (
                <></>
              )}
            </p>
          </Detail>
        </div>
        <Divider layout="vertical" />
        <div className={styles.column}>
          <Detail label={translations.amount}>
            <p className={styles.bigText}>
              {Math.abs(props.financialTransaction.amount).toLocaleString(
                undefined,
                {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }
              )}{" "}
              €
            </p>
          </Detail>
        </div>
        <Divider layout="vertical" />
        <div className={styles.column}>
          <Detail label={translations.creditDebit}>
            <p
              className={`${styles.bigText} ${isDebit ? styles.debit : styles.credit}`}
            >
              {isDebit ? translations.debit : translations.credit}
            </p>
          </Detail>
        </div>
        <Divider layout="vertical" />
        <div className={styles.column}>
          <Detail label={translations.description}>
            <p>{props.financialTransaction.purpose}</p>
          </Detail>
        </div>
      </div>
      <div className={styles.row}>
        <SplitBookingTable
          legs={props.legs}
          form={props.form}
          hasHistoricalSplitBookingLegs={props.hasHistoricalSplitBookingLegs}
        />
      </div>
    </div>
  );
}
