import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import { ReactNode, useCallback, useEffect, useRef, useState } from "react";
import styles from "./accounting-firm-loader.module.scss";
import { useMainApi } from "../../../main-api";
import { z } from "zod";
import {
  getCommunicationErrorMessage,
  getCommunicationErrorTitle,
} from "../../../communication-errors/communication-error-messages";

export const AccountingFirmSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  email: z.string().nullable(),
  official_id: z.string().nullable(),
  vat_id: z.string().nullable(),
  address: z.string().nullable(),
  homepage_url: z.string().nullable(),
  phone: z.string().nullable(),
  admin: z.object({
    id: z.string().uuid(),
    name: z.string(),
    email: z.string(),
  }),
});

export type AccountingFirm = z.infer<typeof AccountingFirmSchema>;

export function AccountingFirmLoader(props: {
  children: (renderProps: {
    accountingFirm: AccountingFirm;
    onAccountingFirmDataUpdated: (accountingFirm: AccountingFirm) => void;
  }) => ReactNode;
}) {
  const mainApi = useMainApi();

  const toast = useRef<Toast>(null);

  const [state, setState] = useState<{
    loading?: boolean;
    data?: AccountingFirm;
  }>({});

  useEffect(() => {
    (async () => {
      setState({ loading: true });

      const res = await mainApi.fetchJSON({
        method: "GET",
        path: "/accounting_firms/current",
        schema: z.object({
          status: z.literal(200),
          body: AccountingFirmSchema,
        }),
      });

      if (res.error) {
        toast.current!.show({
          severity: "error",
          summary: getCommunicationErrorTitle(res.error),
          detail: getCommunicationErrorMessage(res.error),
        });
        setState({});
        return;
      }

      setState({ loading: false, data: res.response.body });
    })();
  }, [mainApi, toast]);

  const onAccountingFirmDataUpdated = useCallback(
    (accountingFirm: AccountingFirm) => {
      setState((state) => {
        if (!state.data) {
          throw Error();
        }

        return {
          ...state,
          data: accountingFirm,
        };
      });
    },
    []
  );

  if (state.loading) {
    return (
      <div className={styles.loading}>
        <ProgressSpinner />
      </div>
    );
  }

  if (!state.data) {
    return null;
  }

  return (
    <>
      <Toast ref={toast} />
      {props.children({
        accountingFirm: state.data,
        onAccountingFirmDataUpdated,
      })}
    </>
  );
}
