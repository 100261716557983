import { useContext } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { EditSplitBookingLegDetailsFormValue } from "../split-booking-leg-details-panel";
import styles from "./details.module.scss";
import { Divider } from "primereact/divider";
import { Dropdown } from "primereact/dropdown";
import { Detail } from "./detail/detail-column";
import { Booking } from "../../../results/results-context";
import { BookingsPageResourcesContext } from "../../../page-resources";
import { throwError } from "../../../../../../../../throw-error";
import { useCurrentLanguage } from "../../../../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../../../../language/supported-languages";
import { InputNumber } from "primereact/inputnumber";
import { Chips } from "primereact/chips";
import { BookingsPageBankAccountsContext } from "../../../top-section/navbar/quick-filters/bank-accounts-context";

// Intl formatter that formats dates as '11th January 2023'
const dateFormatter = new Intl.DateTimeFormat(undefined, {
  day: "numeric",
  month: "short",
  year: "numeric",
});

const GERMAN_TRANSLATIONS = {
  transactionDate: "Transaktionsdatum",
  applicantName: "Name",
  amount: "Betrag",
  creditDebit: "Soll / Haben",
  taxCode: "Steuercode",
  taxPercentage: "Steuer %",
  account: "Konto",
  counterAccount: "Gegenkonto",
  skonto: "Skonto",
  skontoAccount: "Skontokonto",
  description: "Beschreibung",
  status: "Status",
  debit: "Soll",
  credit: "Haben",
  actionNeeded: "Handlungsbedarf",
  correctedByAccountant: "Manuell korrigiert",
  manuallyApproved: "Manuell genehmigt",
  processed: "Vollautomatisiert",
  historical: "Historisch",
  invoiceNumbers: "Rechnungsnummern",
  addNewInvoiceNumber: "Neue Rechnungsnummer hinzufügen",
  deleteInvoiceNumber: "Rechnungsnummer löschen",
};

const ENGLISH_TRANSLATIONS = {
  transactionDate: "Transaction Date",
  applicantName: "Applicant Name",
  amount: "Amount",
  creditDebit: "Credit / Debit",
  taxCode: "Tax Code",
  taxPercentage: "Tax %",
  account: "Account",
  counterAccount: "Counter Account",
  skonto: "Skonto",
  skontoAccount: "Skonto Account",
  description: "Description",
  status: "Status",
  debit: "Debit",
  credit: "Credit",
  actionNeeded: "Action Needed",
  correctedByAccountant: "Corrected by Accountant",
  manuallyApproved: "Manually Approved",
  processed: "Processed",
  historical: "Historical",
  invoiceNumbers: "Invoice Numbers",
  addNewInvoiceNumber: "Add new invoice number",
  deleteInvoiceNumber: "Delete invoice number",
};

export function Details(props: {
  selectedBooking: Booking;
  form: UseFormReturn<EditSplitBookingLegDetailsFormValue>;
}) {
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const { counter_accounts, tax_codes } =
    useContext(BookingsPageResourcesContext) || throwError();

  const { selectedBankAccount } =
    useContext(BookingsPageBankAccountsContext) || throwError();

  return (
    <div className={styles.rows}>
      <div className={styles.row}>
        <div className={styles.column}>
          <Detail label={translations.transactionDate}>
            <p className={styles.bigText}>
              {props.selectedBooking.date ? (
                dateFormatter.format(props.selectedBooking.date)
              ) : (
                <></>
              )}
            </p>
          </Detail>
        </div>
        <Divider layout="vertical" />
        <div className={styles.column}>
          <Detail label={translations.amount}>
            <Controller
              name="amount"
              control={props.form.control}
              render={({ field, fieldState }) => (
                <div className="flex flex-col gap-2">
                  <InputNumber
                    value={props.selectedBooking.amount}
                    mode="currency"
                    currency="EUR"
                    locale="de-DE"
                    onValueChange={(e) => field.onChange(e.value)}
                    disabled={props.selectedBooking.status === "HISTORICAL"}
                    className={`w-full ${fieldState.error ? "p-invalid" : ""}`}
                  />
                  {fieldState.error && (
                    <small className="p-error">
                      {fieldState.error.message}
                    </small>
                  )}
                </div>
              )}
            />
          </Detail>
        </div>
        <Divider layout="vertical" />
        <div className={styles.column}>
          <Detail label={translations.creditDebit}>
            <Controller
              name="is_debit"
              control={props.form.control}
              render={({ field }) => (
                <Dropdown
                  options={[
                    { label: translations.debit, value: true },
                    { label: translations.credit, value: false },
                  ]}
                  value={field.value}
                  onChange={(e) => field.onChange(e.target.value)}
                  disabled={props.selectedBooking.status === "HISTORICAL"}
                  className="w-full"
                  itemTemplate={(option) => (
                    <span
                      className={option.value ? styles.debit : styles.credit}
                    >
                      {option.label}
                    </span>
                  )}
                  valueTemplate={(option) => (
                    <span
                      className={option.value ? styles.debit : styles.credit}
                    >
                      {option.label}
                    </span>
                  )}
                />
              )}
            />
          </Detail>
        </div>
      </div>
      <div className={styles.row}>
        <div className={`${styles.column} ${styles.minWidth0}`}>
          <Detail label={translations.counterAccount}>
            <Controller
              name="counter_account_id"
              control={props.form.control}
              render={({ field }) => {
                return (
                  <>
                    <Dropdown
                      id={field.name}
                      className={styles.width100}
                      options={counter_accounts.map((t) => ({
                        value: t.id,
                        label: `${t.number}: ${t.name}`,
                        disabled: t.id === selectedBankAccount?.account_id,
                      }))}
                      value={field.value}
                      onChange={(e) => field.onChange(e.value)}
                      disabled={props.selectedBooking.status === "HISTORICAL"}
                    />
                  </>
                );
              }}
            />
          </Detail>
        </div>
        <Divider layout="vertical" />
        <div className={`${styles.column} ${styles.minWidth0}`}>
          <Detail label={translations.taxCode}>
            <Controller
              name="tax_code_id"
              control={props.form.control}
              render={({ field }) => {
                return (
                  <>
                    <Dropdown
                      id={field.name}
                      className={styles.width100}
                      options={tax_codes.map((t) => ({
                        value: t.id,
                        label: `${t.tax_code} | ${t.description}`,
                      }))}
                      value={field.value}
                      onChange={(e) => field.onChange(e.target.value)}
                      disabled={props.selectedBooking.status === "HISTORICAL"}
                    />
                  </>
                );
              }}
            />
          </Detail>
        </div>

        <Divider layout="vertical" />
        <div className={`${styles.column} ${styles.minWidth0}`}>
          <Detail label={translations.skonto}>
            <p>
              {props.selectedBooking.skonto_amount &&
                `${props.selectedBooking.skonto_amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} €`}
            </p>
          </Detail>
        </div>
        <Divider layout="vertical" />
        <div className={`${styles.column} ${styles.minWidth0}`}>
          <Detail label={translations.skontoAccount}>
            <p>
              {props.selectedBooking.skonto_account &&
                `${props.selectedBooking.skonto_account.number}: ${props.selectedBooking.skonto_account.name}`}
            </p>
          </Detail>
        </div>
      </div>
      <div className={"flex"}>
        <div className={"flex-1"}>
          <Detail label={translations.description}>
            <p>{props.selectedBooking.booking_text}</p>
          </Detail>
        </div>
        <Divider layout="vertical" />
        <div className={"flex-1"}>
          <Detail label={translations.invoiceNumbers}>
            <div className="flex flex-wrap gap-3">
              <Controller
                name="invoice_numbers"
                control={props.form.control}
                render={({ field, fieldState }) => (
                  <div className="flex flex-wrap gap-3">
                    <Chips
                      value={field.value || []}
                      onChange={(e) => field.onChange(e.value)}
                      className={`w-full ${fieldState.error ? "p-invalid" : ""}`}
                      disabled={props.selectedBooking.status === "HISTORICAL"}
                      pt={{
                        container: {
                          className: "w-full",
                        },
                      }}
                    />
                    {fieldState.error && (
                      <small className="p-error">
                        {fieldState.error.message}
                      </small>
                    )}
                  </div>
                )}
              />
            </div>
          </Detail>
        </div>
      </div>
    </div>
  );
}
