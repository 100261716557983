import styles from "./page.module.scss";
import { useCurrentLanguage } from "../../language/current-language";
import { SupportedLanguage } from "../../language/supported-languages";
import { Helmet } from "react-helmet-async";

const GERMAN_TRANSLATIONS = {
  pageNotFound: "Seite nicht gefunden",
  pageNotFoundDescription: "Die von Ihnen gesuchte Seite existiert nicht.",
};

const ENGLISH_TRANSLATIONS = {
  pageNotFound: "Page Not Found",
  pageNotFoundDescription: "The page you are looking for does not exist.",
};

export function NotFoundPage() {
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  return (
    <>
      <Helmet>
        <title>{`${translations.pageNotFound} | Kanzlei21`}</title>
      </Helmet>

      <div className={`${styles.page}`}>
        <b className={`${styles.fontSize3Rem} ${styles.marginBottom}`}>404</b>
        <b className={`${styles.fontSize1_5Rem} ${styles.marginBottom}`}>
          {translations.pageNotFound}
        </b>
        <p className={`${styles.marginBottom}`}>
          {translations.pageNotFoundDescription}
        </p>
      </div>
    </>
  );
}
