import styles from "./top-section.module.scss"
import Tasks from "./tasks/tasks";
import Greeting from "./greeting/greeting";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { useCurrentLanguage } from "../../../../language/current-language";
import { SupportedLanguage } from "../../../../language/supported-languages";
import { CLIENT_USER_SETTING_ROUTE } from "../../user-settings/user-settings-route";

const GERMAN_TRANSLATIONS = {
    userProfile: "Benutzerprofil",
};

const ENGLISH_TRANSLATIONS = {
    userProfile: "User Profile",
};

export default function TopSection() {

    const navigate = useNavigate();
    const currentLanguage = useCurrentLanguage();
    const translations =
        currentLanguage === SupportedLanguage.German
            ? GERMAN_TRANSLATIONS
            : ENGLISH_TRANSLATIONS;

    return (
        <>
            <div className={styles.header}>
                <div className={styles.buttons}>
                    <Button
                        severity="secondary"
                        icon="pi pi-user"
                        label={translations.userProfile}
                        onClick={() => navigate(CLIENT_USER_SETTING_ROUTE.getHref())}
                    />
                </div>
            </div>

            <div className={styles.page}>
                <div className={styles.pageHeader}>
                    <div className={styles.topSectionPanel}>
                        <Greeting />
                    </div>
                    <div className={styles.topSectionPanel}>
                        <Tasks />
                    </div>
                </div>
            </div>
        </>

    )
}
