import { ReactNode, createContext, useMemo, useState } from "react";

export type QuickFilters = {
  time_frame_from: Date | null;
  time_frame_to: Date | null;
  search: string | null;
};

function useProvider() {
  const currentYear = new Date().getFullYear();

  const [quickFilters, setQuickFilters] = useState<QuickFilters>({
    time_frame_from: new Date(currentYear, 0, 1),
    time_frame_to: new Date(currentYear, 11, 31),
    search: null,
  });

  return useMemo(() => {
    return {
      quickFilters,
      setQuickFilters,
    };
  }, [quickFilters]);
}

export const QuickFiltersContext = createContext<
  ReturnType<typeof useProvider> | undefined
>(undefined);

export function QuickFiltersContextProvider(props: { children: ReactNode }) {
  const value = useProvider();

  return (
    <QuickFiltersContext.Provider value={value}>
      {props.children}
    </QuickFiltersContext.Provider>
  );
}
