import { ReactNode } from "react";
import styles from "./layout.module.scss";
import { Menu } from "./menu/menu";

export function Layout(props: { children: ReactNode }) {
  return (
    <div className={styles.main}>
      <div className={styles.menuSection}>
        <Menu />
      </div>
      <main className={styles.content}>{props.children}</main>
    </div>
  );
}
