import { Card } from 'primereact/card';
import styles from "./connect-bank-account.module.scss"
import Markdown from 'react-markdown';
import { SupportedLanguage } from '../../../../language/supported-languages';
import { HEADINGS_CLASS_NAMES } from '../../../../ui/headings';
import { useCurrentLanguage } from '../../../../language/current-language';
import login from "./images/login.png"
import bank_connection from "./images/bank-connection.png"
import account_connection from "./images/account-connection.png"
import { CREATE_AN_ACCOUNT } from '../create-an-account/create-an-account.route';

const GERMAN_TUTORIAL = `
# Tutorial zur Verbindung eines Bankkontos mit FinAPI

Dieses Tutorial erklärt Ihnen als Mandant, wie Sie ein Bankkonto mit FinAPI verbinden. Voraussetzung ist, dass Sie bereits ein aktives Kanzlei21-Konto besitzen. Falls Sie noch kein Konto erstellt haben, ***folgen Sie diesem Tutorial*** **(placeholder, add URL)**, um eines zu erstellen.

1. **Login:** Melden Sie sich in Ihrem Kanzlei21-Mandantenkonto an. Nachdem Sie sich eingeloggt haben, sehen Sie Ihr Dashboard.   
2. **Bankverbindung initiieren:** Klicken Sie im Bereich **Bankkonten FinAPI** ihres Dashboards auf **Anfrage zum Hinzufügen einer neuen Ban**   ![](${login})  
3. **FinAPI starten:** Nachdem die Verbindung initiiert wurde, klicken Sie auf **Anfrage abgeschlossen. Klicken Sie hier, um fortzufahren**. ![](${bank_connection})  
4. **Kontoanbindung mit FinAPI:** Ein neues Tab öffnet sich und führt Sie zur FinAPI-Plattform. Beginnen Sie hier mit der Auswahl der Bank, die Sie verbinden möchten. Melden Sie sich über FinAPI bei Ihrer Bank an, um die Verbindung zu zertifizieren. **Wichtig:** Falls während des Verbindungsvorgangs bei FinAPI die Option **PIN speichern** angezeigt wird, klicken Sie das entsprechende Kästchen an. Dies verhindert, dass nach sechs Monaten ein Ablaufdatum festgelegt wird. ![](${account_connection})   
5. **Abschließen:** Sobald Ihr Bankkonto verbunden ist, bestätigen Sie die Daten. Falls alles korrekt ist, schließen Sie den Vorgang auf FinAPI ab. **Wichtig:** Bitte schließen Sie das FinAPI-Tab, nachdem Sie diesen Schritt abgeschlossen haben. 

Herzlichen Glückwunsch! Ihr neu verbundenes Bankkonto wird jetzt in Ihrem Kanzlei21-Profil angezeigt. Ihr Steuerberater wird benachrichtigt und übernimmt von hier an. Sie müssen nichts weiter tun!

Relevante Links:  
***[Tutorial zur Erstellung eines Mandantenkontos](${CREATE_AN_ACCOUNT.getHref()})***

`;

const ENGLISH_TUTORIAL = `
# Tutorial for a client to connect a bank account with FinAPI

This tutorial will guide you as a client through the process of connecting a bank account with FinAPI. It assumes you already have an active Kanzlei21 client account. If you haven’t created an account yet, ***follow this other tutorial*** **(placeholder, add URL)** to create one.

1. **Login:** Log into your Kanzlei21 client account. Once logged in, you will see your dashboard. 
2. **Initiate bank connection:** From your dashboard, click on the **Initiate account connection** button in the **Bank accounts from FinAP**I section.

![](${login})

3. **Start FinAPI:** Once the account connection has been initiated, click on **Continue on FinAPI** to proceed.

![](${bank_connection})

4. **Account connection with FinAPI:** A new tab will open, taking you to the FinAPI platform. Here, begin the process by selecting the bank you want to connect. Log in to your bank through FinAPI to certify the connection. **Important:** If, during the connection process on FinAPI, you see an option to **Save PIN**, click the checkbox. This will prevent an expiration date from being applied after six months.

![](${account_connection})

5. **Finish:** Once your bank account is connected, confirm the data. If everything is correct, complete the process on FinAPI. **Important:** After finishing this step, please close the FinAPI tab.

   

Congratulations! Your newly connected bank account will now appear in your Kanzlei21 profile. Your accountant will be notified and will handle the rest. There’s nothing more you need to do!

Relevant links:  
***[Tutorial for a client to create an account](${CREATE_AN_ACCOUNT.getHref()})***

`;

function ConnectBankAccount() {
    const language = useCurrentLanguage();

    return (
        <Card className={styles.tutorialCard}>
            <Markdown
                components={{
                    h1: (o) => (
                        <h1 className={`${HEADINGS_CLASS_NAMES.h1} mb-4`}>{o.children}</h1>
                    ),
                    h2: (o) => (
                        <h2 className={`${HEADINGS_CLASS_NAMES.h2} mb-4 mt-4`}>
                            {o.children}
                        </h2>
                    ),
                    h3: (o) => (
                        <h3 className={`${HEADINGS_CLASS_NAMES.h3} mb-4 mt-4`}>
                            {o.children}
                        </h3>
                    ),
                    h4: (o) => (
                        <h4 className={`${HEADINGS_CLASS_NAMES.h4} mb-4 mt-4`}>
                            {o.children}
                        </h4>
                    ),
                    ul: (o) => <ul className="list-disc list-inside">{o.children}</ul>,
                    ol: (o) => <ol className="list-decimal list-inside">{o.children}</ol>,
                    li: (o) => <li className="list-decimal list-inside mb-2">{o.children}</li>,
                    a: (o) => (
                        <a href={o.href} target="_blank" rel="noopener noreferrer" >
                            {o.children}
                        </a>
                    ),
                    img: (o) => <img src={o.src} alt="" className="mb-10 mt-2" />,
                    p: (o) => <p className="mb-6">{o.children}</p>,
                }}
            >
                {language == SupportedLanguage.German
                    ? GERMAN_TUTORIAL
                    : ENGLISH_TUTORIAL}
            </Markdown>
        </Card>
    );
}

export default ConnectBankAccount