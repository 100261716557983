import { Button } from "primereact/button";
import { useCallback, useContext, useState } from "react";
import { BankConnectionSchema, ResultsContext } from "../results-context";
import { z } from "zod";
import { Dialog } from "primereact/dialog";
import { ProgressSpinner } from "primereact/progressspinner";
import {
  getCommunicationErrorTitle,
  getCommunicationErrorMessage,
} from "../../../../../../communication-errors/communication-error-messages";
import { useCurrentLanguage } from "../../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../../language/supported-languages";
import { useMainApi } from "../../../../../../main-api";
import { throwError } from "../../../../../../throw-error";

const GERMAN_TRANSLATIONS = {
  accountsConnected: "Konten erfolgreich verbunden",
  credentialsNeedToBeUpdated: "Erneut verbinden",
  requestCompleted: "Anfrage abgeschlossen, klicken Sie hier, um fortzufahren",
  bankAlreadyConnectedSummary: "Bank ist bereits verbunden",
  doNotCloseTabWarning: "Schließen Sie diese Registerkarte nicht",
};

const ENGLISH_TRANSLATIONS = {
  accountsConnected: "Accounts successfully connected",
  credentialsNeedToBeUpdated: "Reconnect",
  requestCompleted: "Request completed, click here to continue",
  bankAlreadyConnectedSummary: "Bank is already connected",
  doNotCloseTabWarning: "Do not close this tab while connecting your bank.",
};

export function UpdateBankConnection({
  connection_id,
}: {
  connection_id: string;
}) {
  const mainApi = useMainApi();
  const { setResults, toastRef } = useContext(ResultsContext) || throwError();
  const [dialogVisible, setDialogVisible] = useState(false);

  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const [state, setState] = useState<{
    loading?: boolean;
    data?: {
      webformId?: string;
      webformUrl?: string;
    };
  }>({});

  const pollWebFormStatus = useCallback(() => {
    if (!state.data) {
      return;
    }

    setDialogVisible(true);

    const intervalId = setInterval(async () => {
      const res = await mainApi.fetchJSON({
        path: `/bank-accounts/finapi/web_forms/${state.data?.webformId}`,
        method: "GET",
        schema: z.union([
          z.object({
            status: z.literal(200),
            body: z.object({
              status: z.literal("COMPLETED"),
              bank_connection: BankConnectionSchema,
            }),
          }),
          z.object({
            status: z.literal(200),
            body: z.object({
              status: z.literal("IN_PROGRESS"),
            }),
          }),
        ]),
      });

      if (res.error) {
        toastRef.current?.show({
          severity: "error",
          summary: getCommunicationErrorTitle(res.error),
          detail: getCommunicationErrorMessage(res.error),
        });
        clearInterval(intervalId);
        setDialogVisible(false);
        setState({});
        return;
      }

      const responseBody = res.response.body;
      if (responseBody.status === "IN_PROGRESS") {
        return; // Continue polling
      }

      clearInterval(intervalId);
      setDialogVisible(false);
      setState({});

      if (responseBody.status === "COMPLETED") {
        setResults((prev) => ({
          ...prev,
          data: prev.data?.map((connection) =>
            connection.id === responseBody.bank_connection.id
              ? responseBody.bank_connection
              : connection
          ),
        }));

        toastRef.current?.show({
          severity: "success",
          summary: translations.accountsConnected,
        });
        return;
      }
    }, 3000);
  }, [mainApi, setResults, state.data, toastRef, translations]);

  return (
    <>
      <div className={"flex items-center justify-center "}>
        {(() => {
          if (state.data) {
            return (
              <a
                className={"p-button"}
                onClick={pollWebFormStatus}
                href={state.data.webformUrl}
                target="_blank"
                rel="noreferrer"
              >
                <span className={"pi pi-arrow-up-right mr-3"}></span>

                {translations.requestCompleted}
              </a>
            );
          } else {
            return (
              <Button
                label={`${translations.credentialsNeedToBeUpdated}`}
                disabled={state.loading}
                loading={state.loading}
                severity="warning"
                onClick={async () => {
                  setState({ loading: true });

                  const res = await mainApi.fetchJSON({
                    method: "PUT",
                    path: `/bank-accounts/finapi/${connection_id}/update-bank-connection`,
                    schema: z.object({
                      status: z.literal(200),
                      body: z.object({
                        web_form_url: z.string(),
                        web_form_id: z.string(),
                      }),
                    }),
                  });

                  setState({ loading: false });

                  if (res.error) {
                    toastRef.current?.show({
                      severity: "error",
                      summary: getCommunicationErrorTitle(res.error),
                      detail: getCommunicationErrorMessage(res.error),
                    });
                    return;
                  }

                  setState({
                    data: {
                      webformId: res.response.body.web_form_id,
                      webformUrl: res.response.body.web_form_url,
                    },
                  });
                }}
              />
            );
          }
        })()}
      </div>

      <Dialog modal visible={dialogVisible} onHide={() => {}} closable={false}>
        <div className={`flex items-center justify-center w-100`}>
          <ProgressSpinner />
          <b>{translations.doNotCloseTabWarning}</b>
        </div>
      </Dialog>
    </>
  );
}
