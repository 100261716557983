import { Button } from "primereact/button";
import { useContext, useState } from "react";
import { z } from "zod";
import { useMainApi } from "../../../../../../main-api";
import { ResultsContext } from "../results-context";
import { throwError } from "../../../../../../throw-error";
import {
  getCommunicationErrorTitle,
  getCommunicationErrorMessage,
} from "../../../../../../communication-errors/communication-error-messages";
import { useCurrentLanguage } from "../../../../../../language/current-language"; // Assuming this hook exists
import { SupportedLanguage } from "../../../../../../language/supported-languages"; // Assuming this enum exists

const GERMAN_TRANSLATIONS = {
  disconnect: "Trennen",
  failedToDisconnect: "Trennung fehlgeschlagen",
  failedToDisconnectDetail:
    "Die Bankverbindung konnte nicht getrennt werden. Bitte versuchen Sie es später erneut.",
  success: "Erfolg",
  successDetail: "Bankverbindung erfolgreich getrennt",
};

const ENGLISH_TRANSLATIONS = {
  disconnect: "Disconnect",
  failedToDisconnect: "Failed to Disconnect",
  failedToDisconnectDetail:
    "Unable to disconnect bank account, please try again later.",
  success: "Success",
  successDetail: "Bank Account disconnected successfully",
};

export default function DisconnectBankConnectionButton({
  connection_id,
}: {
  connection_id: string;
}) {
  const mainApi = useMainApi();
  const { setResults, toastRef } = useContext(ResultsContext) || throwError();
  const [loading, setLoading] = useState(false);
  const currentLanguage = useCurrentLanguage(); // Get the current language
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  return (
    <>
      <Button
        label={translations.disconnect}
        severity="danger"
        onClick={() => {
          (async () => {
            setLoading(true);

            const res = await mainApi.fetchJSON({
              method: "DELETE",
              path: `/bank-accounts/finapi/disconnect-bank-connection/${connection_id}`,
              schema: z.union([
                z.object({
                  status: z.literal(409),
                  body: z.object({
                    detail: z.literal("failed-to-disconnect-bank"),
                  }),
                }),
                z.object({
                  status: z.literal(200),
                  body: z.object({ id: z.string() }),
                }),
              ]),
            });

            setLoading(false);

            if (res.error) {
              toastRef.current?.show({
                severity: "error",
                summary: getCommunicationErrorTitle(res.error),
                detail: getCommunicationErrorMessage(res.error),
              });
              return;
            }

            if (res.response.status === 409) {
              toastRef.current?.show({
                severity: "error",
                summary: translations.failedToDisconnect,
                detail: translations.failedToDisconnectDetail,
              });
              return;
            }

            toastRef.current?.show({
              severity: "success",
              summary: translations.success,
              detail: translations.successDetail,
            });

            const responseBody = res.response.body;

            setResults((results) => ({
              ...results,
              data: results.data?.filter(
                (connection) => connection.id !== responseBody.id
              ),
            }));
          })();
        }}
        loading={loading}
      />
    </>
  );
}
