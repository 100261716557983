import { ReactNode, createContext, useMemo, useState } from "react";

export type GdpduPreview = {
  file: File;
  data: {
    client_number: string;
    name: string;
    system_of_accounts: string;
    vat_id: string | null;
  };
};

function useProvider() {
  const [gdpduPreviewState, setGdpduPreviewState] = useState<
    GdpduPreview | undefined
  >();

  return useMemo(() => {
    return {
      gdpduPreviewState,
      setGdpduPreviewState,
    };
  }, [gdpduPreviewState, setGdpduPreviewState]);
}

export const GdpduContext = createContext<
  ReturnType<typeof useProvider> | undefined
>(undefined);

export function GdpduPreviewContextProvider(props: { children: ReactNode }) {
  const value = useProvider();

  return (
    <GdpduContext.Provider value={value}>
      {props.children}
    </GdpduContext.Provider>
  );
}
