import z from "zod";
import { useState, useRef, useEffect } from "react";
import { CommunicationError } from "../../../../../../communication-errors/communication-errors";
import { useMainApi } from "../../../../../../main-api";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import { Message } from "primereact/message";
import { ConnectedBankAccountCards } from "./connected-bank-account-cards/connected-bank-account-cards";
import { SendEmailToAccountingClient } from "./send-email-to-accounting-client/send-email-to-accounting-client";
import { ConnectBankAccountsToNominalAccounts } from "./connect-bank-accounts-to-nominal-accounts/connect-bank-accounts-to-nominal-accounts";
import styles from "./bank-information.module.scss";
import { useCurrentLanguage } from "../../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../../language/supported-languages";
import { AccountingClient } from "../accountingClientLoader";
import { AddBankAccountWithIBAN } from "./add-new-bank-account-with-iban/add-new-bank-account-with-iban";
import { LINK_INHERIT_CLASSNAME } from "../../../../../../ui/links";
import { HEADINGS_CLASS_NAMES } from "../../../../../../ui/headings";
import { Title } from "../../../title";

const GERMAN_TRANSLATIONS = {
  bankAccounts: "Bankkonten",
  error: "Fehler",
  failedToFetchBankInformation: "Fehler beim Laden der Bankinformationen",
  loadingError: "Beim Laden der Bankinformationen ist ein Fehler aufgetreten.",
  connectedBankAccounts: "Verbundene Bankkonten",
  newBankAccountWithFinApi: "Neues Bankkonto mit FinAPI",
  newBankAccountWithIban: "Neues Bankkonto mit IBAN",
  assignNominalAccounts: "Sachkonten den Bankkonten zuweisen",
  limitedNominalAccountsMessage:
    "Begrenzte Sachkonten zur Auswahl. Bitte sehen Sie sich weitere Konten an",
  uploadGDPDU: "Laden Sie die GDPDU-Datei hoch.",
  onboardingSuccessMessageTitle:
    "E-Mail erfolgreich gesendet! Jetzt warten wir.",
  onboardingSuccessMessageDetail: (lastBankConnectionEmailSentAt: string) =>
    `Eine E-Mail wurde am ${lastBankConnectionEmailSentAt} an den Mandanten gesendet. Sie werden benachrichtigt, sobald der Mandant sein Bankkonto verbunden hat.`,
};

const ENGLISH_TRANSLATIONS = {
  bankAccounts: "Bank Accounts",
  error: "Error",
  failedToFetchBankInformation: "Failed to fetch bank information",
  loadingError: "There was an error loading the bank information.",
  connectedBankAccounts: "Connected bank accounts",
  newBankAccountWithFinApi: "New bank account with FinAPI",
  newBankAccountWithIban: "New bank account with IBAN",
  assignNominalAccounts: "Assign nominal accounts to bank accounts",
  limitedNominalAccountsMessage:
    "Limited nominal accounts to choose from. To see more accounts, please",
  uploadGDPDU: "upload GDPDU File.",
  onboardingSuccessMessageTitle: "Email sent successfully! Now we wait.",
  onboardingSuccessMessageDetail: (lastBankConnectionEmailSentAt: string) =>
    `An email has been sent to the client at ${lastBankConnectionEmailSentAt}. You will be notified once the client has connected their bank account.`,
};

const dateFormatter = new Intl.DateTimeFormat(undefined, {
  dateStyle: "medium",
  timeStyle: "short",
});

export const NominalAccountSchema = z.object({
  id: z.string().uuid(),
  account_number: z.number(),
  account_name: z.string(),
});

export type NominalAccountToConnect = z.infer<typeof NominalAccountSchema>;

const BankAccountWithNominalAccountSchema = z.object({
  id: z.string().uuid(),
  iban: z.string(),
  nominal_account: NominalAccountSchema,
});

export type BankAccountWithNominalAccount = z.infer<
  typeof BankAccountWithNominalAccountSchema
>;

const BankAccountWithoutNominalSchema = z.object({
  id: z.string().uuid(),
  iban: z.string(),
});

export type BankAccountWithoutNominalAccount = z.infer<
  typeof BankAccountWithoutNominalSchema
>;

export const AccountingClientBankAccountsSchema = z.object({
  bank_accounts_with_nominal_accounts: z.array(
    BankAccountWithNominalAccountSchema
  ),
  bank_accounts_without_nominal_accounts: z.array(
    BankAccountWithoutNominalSchema
  ),
  nominal_accounts_to_connect: z.array(NominalAccountSchema),
});

export type AccountingClientBankAccounts = z.infer<
  typeof AccountingClientBankAccountsSchema
>;

export default function BankInformation(props: {
  accountingClient: AccountingClient;
  setAccountingClient: (accountingClient: AccountingClient) => void;
  switchToDocumentsTab: () => void;
}) {
  const mainApi = useMainApi();
  const toastRef = useRef<Toast>(null);

  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const [
    accountingClientBankInformationState,
    setAccountingClientBankInformationState,
  ] = useState<{
    loading?: boolean;
    data?: AccountingClientBankAccounts | null;
    error?: CommunicationError | undefined;
  }>({});

  useEffect(() => {
    const fetchAccountingClientBankAccounts = async () => {
      setAccountingClientBankInformationState({ loading: true });
      const res = await mainApi.fetchJSON({
        method: "GET",
        path: `/accounting_clients/${props.accountingClient.id}/bank_accounts`,
        schema: z.object({
          status: z.literal(200),
          body: AccountingClientBankAccountsSchema,
        }),
      });

      if (res.error) {
        setAccountingClientBankInformationState({ error: res.error });
        toastRef.current?.show({
          severity: "error",
          summary: translations.error,
          detail: translations.failedToFetchBankInformation,
        });
        return;
      }

      setAccountingClientBankInformationState({ data: res.response.body });
    };

    fetchAccountingClientBankAccounts();
  }, [
    mainApi,
    props.accountingClient.id,
    translations.error,
    translations.failedToFetchBankInformation,
  ]);

  return (
    <>
      <Title
        accountingClientName={props.accountingClient.name}
        page={translations.bankAccounts}
      />
      <div className={styles.page}>
        <Toast ref={toastRef} />
        {(() => {
          if (accountingClientBankInformationState.loading) {
            return (
              <div className={styles.loadingContainer}>
                <ProgressSpinner />
              </div>
            );
          }

          if (accountingClientBankInformationState.error) {
            return (
              <div className={styles.errorContainer}>
                <Message severity="error" text={translations.loadingError} />
              </div>
            );
          }

          if (accountingClientBankInformationState.data) {
            return (
              <>
                <div className={styles.maxWidth}>
                  <h2
                    className={`${styles.marginBottom2} ${HEADINGS_CLASS_NAMES.h2}`}
                  >
                    {translations.connectedBankAccounts}
                  </h2>

                  <div className={styles.marginBottom3}>
                    <div className={styles.cardsContainer}>
                      <ConnectedBankAccountCards
                        bankAccountsWithNominalAccounts={
                          accountingClientBankInformationState.data
                            .bank_accounts_with_nominal_accounts
                        }
                      />
                    </div>
                  </div>
                  <div className={styles.marginBottom3}>
                    <h3
                      className={`${styles.marginBottom2} ${HEADINGS_CLASS_NAMES.h3}`}
                    >
                      {translations.newBankAccountWithFinApi}
                    </h3>
                    <SendEmailToAccountingClient
                      accountingClient={props.accountingClient}
                      setAccountingClient={props.setAccountingClient}
                    />
                    {accountingClientBankInformationState.data
                      .nominal_accounts_to_connect.length !== 0 &&
                      props.accountingClient
                        .last_bank_connection_email_sent_at && (
                        <div
                          className={`${styles.marginBottom3} ${styles.maxWidth}`}
                        >
                          <Message
                            severity="success"
                            className={styles.message}
                            content={
                              <>
                                <b>
                                  {translations.onboardingSuccessMessageTitle}
                                </b>
                                <br />
                                {translations.onboardingSuccessMessageDetail(
                                  dateFormatter.format(
                                    props.accountingClient
                                      .last_bank_connection_email_sent_at
                                  )
                                )}
                              </>
                            }
                          />
                        </div>
                      )}
                  </div>

                  <div className={`${styles.marginBottom3}`}>
                    <h3
                      className={`${styles.marginBottom2} ${HEADINGS_CLASS_NAMES.h3}`}
                    >
                      {translations.newBankAccountWithIban}
                    </h3>
                    <AddBankAccountWithIBAN
                      accountingClientId={props.accountingClient.id}
                      setResults={(newResults) =>
                        setAccountingClientBankInformationState(
                          (prevState) => ({
                            ...prevState,
                            data: newResults,
                          })
                        )
                      }
                    />
                  </div>

                  <div className={`${styles.marginBottom3}`}>
                    <h3
                      className={`${styles.marginBottom2} ${HEADINGS_CLASS_NAMES.h3}`}
                    >
                      {translations.assignNominalAccounts}
                    </h3>
                    <div className={styles.displayFlex}>
                      {props.accountingClient.has_gdpdu_upload === false && (
                        <Message
                          className={`${styles.marginBottom2} ${styles.width100}`}
                          severity="info"
                          text={
                            <>
                              {translations.limitedNominalAccountsMessage}{" "}
                              <span
                                className={LINK_INHERIT_CLASSNAME}
                                onClick={props.switchToDocumentsTab}
                              >
                                {translations.uploadGDPDU}
                              </span>
                            </>
                          }
                        />
                      )}
                    </div>

                    {accountingClientBankInformationState.data
                      ?.bank_accounts_without_nominal_accounts.length > 0 && (
                      <>
                        <ConnectBankAccountsToNominalAccounts
                          results={
                            accountingClientBankInformationState.data || {
                              bank_accounts_with_nominal_accounts: [],
                              bank_accounts_without_nominal_accounts: [],
                              nominal_accounts_to_connect: [],
                            }
                          }
                          setResults={(newResults) =>
                            setAccountingClientBankInformationState(
                              (prevState) => ({
                                ...prevState,
                                data: newResults,
                              })
                            )
                          }
                          toastRef={toastRef}
                        />
                      </>
                    )}
                  </div>
                </div>
              </>
            );
          }

          return null;
        })()}
      </div>
    </>
  );
}
