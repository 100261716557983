import { useState } from "react";
import { Accounts } from "./accounts/accounts";
import styles from "./accounts-overview.module.scss";
import { useCurrentLanguage } from "../../../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../../../language/supported-languages";
import OnboardingHeader from "../../onboarding-header";
import { HEADINGS_CLASS_NAMES } from "../../../../../../../ui/headings";
import { FallbackAccount } from "../../../edit/accounts-overview/fallback-account/fallback-account";

const GERMAN_TRANSLATIONS = {
  accountsOverview: "Kontenübersicht",
  previous: "Zurück",
  next: "Weiter",
  noPersonalAccounts: "Es gibt noch keine persönlichen Konten.",
  gdpduUploadInfo:
    "Sobald eine GDPdU-Datei hochgeladen wurde, werden hier persönliche Konten angezeigt.",
  uploadInstructions:
    "Klicken Sie auf die Schaltfläche, um eine GDPdU-Datei hochzuladen",
  setCounterAccountForIncompleteBookings:
    "Gegenkonto für unvollständige Buchungen festlegen",
};

const ENGLISH_TRANSLATIONS = {
  accountsOverview: "Accounts Overview",
  previous: "Previous",
  next: "Next",
  noPersonalAccounts: "There are no personal accounts yet.",
  gdpduUploadInfo:
    "Once a GDPDU file has been uploaded, personal account will appear here.",
  uploadInstructions: "Click on the button to upload a GDPDU file",
  setCounterAccountForIncompleteBookings:
    "Set counter account for incomplete bookings",
};

function _AccountsOverview({
  activeIndex,
  setActiveIndex,
}: {
  activeIndex: number;
  setActiveIndex: (index: number) => void;
}) {
  return <Accounts activeIndex={activeIndex} setActiveIndex={setActiveIndex} />;
}

export default function AccountsOverview(props: {
  onPrevStep: () => void;
  onNextStep: () => void;
}) {
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div className={styles.page}>
      <OnboardingHeader
        titleProps={{
          text: translations.setCounterAccountForIncompleteBookings,
        }}
        previousButtonProps={{
          onClick: () => props.onPrevStep(),
        }}
        finishButtonProps={{
          onClick: () => props.onNextStep(),
        }}
      />
      <div className="px-4 flex flex-col gap-8">
        <FallbackAccount />

        <h2 className={HEADINGS_CLASS_NAMES.h2}>
          {translations.accountsOverview}
        </h2>
      </div>
      {(() => {
        return (
          <div className={styles.body}>
            <_AccountsOverview
              activeIndex={activeIndex}
              setActiveIndex={setActiveIndex}
            />
          </div>
        );
      })()}
    </div>
  );
}
