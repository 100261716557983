export function getSplitBookingStatus(
  legs: Array<{
    status:
      | "ACTION_NEEDED"
      | "MANUALLY_APPROVED"
      | "CORRECTED_BY_ACCOUNTANT"
      | "PREDICTED_WITHOUT_ISSUES"
      | "HISTORICAL";
  }>
) {
  const priorities = {
    HISTORICAL: 1,
    MANUALLY_APPROVED: 2,
    PREDICTED_WITHOUT_ISSUES: 3,
    CORRECTED_BY_ACCOUNTANT: 4,
    ACTION_NEEDED: 5,
  };

  let status:
    | undefined
    | "ACTION_NEEDED"
    | "MANUALLY_APPROVED"
    | "CORRECTED_BY_ACCOUNTANT"
    | "PREDICTED_WITHOUT_ISSUES"
    | "HISTORICAL" = undefined;

  for (const leg of legs) {
    const statusPriority = status ? priorities[status] : 0;

    const legPriority = priorities[leg.status];

    if (legPriority > statusPriority) {
      status = leg.status;
    }
  }

  if (!status) {
    throw new Error();
  }

  return status;
}
