import { Tooltip } from "primereact/tooltip";
import { useCallback, useContext, useMemo, useState } from "react";
import { throwError } from "../../../../../../throw-error";
import { Booking, ResultsContext } from "./results-context";
import styles from "./results.module.scss";
import { Column } from "react-data-grid";
import { SortColumn as ReactDataGridSortColumn } from "react-data-grid";
import { SortColumn, SortingContext } from "../sorting/sorting-context";
import { useCurrentLanguage } from "../../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../../language/supported-languages";
import { ReactDataGrid } from "../../../../../../react-data-grid";
import { ProgressSpinner } from "primereact/progressspinner";
import { isSplitBookingADebit } from "../../../../../../bookings/split-bookings/direction";
import { classNames } from "primereact/utils";
import { getSplitBookingStatus } from "../../../../../../bookings/split-bookings/status";

export type BookingRow = {
  rowId: `booking-${string}`;
  type: "booking";
  booking: Booking;
};

export type SplitBookingLegRow = {
  rowId: `split-booking-leg-${string}`;
  type: "split-booking-leg";
  booking: Booking;
};

export type SplitBookingRow = {
  rowId: `split-booking-${string}`;
  type: "split-booking";
  financial_transaction_id: string;
  children: SplitBookingLegRow[];
  isExpanded: boolean;
};

export type Row = BookingRow | SplitBookingRow | SplitBookingLegRow;

const dateFormatter = new Intl.DateTimeFormat(undefined, {
  dateStyle: "medium",
});

const GERMAN_TRANSLATIONS = {
  transactionDate: "Transaktionsdatum",
  amount: "Betrag",
  splitBooking: "Splittbuchung",
  counterAccount: "Gegenkonto",
  applicantName: "Name",
  applicantIban: "IBAN",
  purpose: "Zweck",
  description: "Beschreibung",
  issues: "Probleme",
  status: "Status",
  actionNeeded: "Handlungsbedarf",
  correctedByAccountant: "Manuell korrigiert",
  manuallyApproved: "Manuell genehmigt",
  predictedWithoutIssues: "Vollautomatisiert",
  historical: "Historisch",
  unableToPredict: "Keine verlässliche Vorhersage möglich",
  unreliablePredictionTitle: "Keine zuverlässige Vorhersage möglich",
  couldNotPredictTaxCodeTitle: "Kann Steuercodes nicht vorhersagen",
  multipleTaxCodesTitle: "Mehrere Steuercodes",
  multipleTaxCodesDescription:
    "Es gibt viele moegliche Steuercodes für diese Buchung:",
  transactionHasNoPurposeOrApplicantName:
    "Transaktion hat keinen Zweck oder Antragsteller",
  invoiceToTransactionMismatch: "Rechnung zu Transaktion passt nicht",
  splitBookingRequired: "Splittbuchung erforderlich",
  noResultsFound: "Keine Ergebnisse gefunden.",
  loadingMore: "Lade weitere Zeilen...",
  debitCredit: "S/H",
  debit: "S",
  credit: "H",
};

const ENGLISH_TRANSLATIONS = {
  transactionDate: "Transaction Date",
  amount: "Amount",
  splitBooking: "Split Booking",
  counterAccount: "Counter Account",
  applicantName: "Applicant Name",
  applicantIban: "Applicant IBAN",
  purpose: "Purpose",
  description: "Description",
  issues: "Issues",
  status: "Status",
  actionNeeded: "Action Needed",
  correctedByAccountant: "Corrected by Accountant",
  manuallyApproved: "Manually Approved",
  predictedWithoutIssues: "Processed",
  historical: "Historical",
  unableToPredict: "Unable to make a reliable prediction",
  unreliablePredictionTitle: "Unable to make a reliable prediction",
  couldNotPredictTaxCodeTitle: "Could not predict tax code",
  multipleTaxCodesTitle: "Multiple Tax Codes",
  multipleTaxCodesDescription:
    "There are many possible tax codes for this booking:",
  transactionHasNoPurposeOrApplicantName:
    "Transaction has no purpose or applicant name",
  invoiceToTransactionMismatch: "Invoice to transaction mismatch",
  splitBookingRequired: "Split booking required",
  noResultsFound: "No results found.",
  loadingMore: "Loading more rows...",
  debitCredit: "D/C",
  debit: "D",
  credit: "C",
};

export function Results(props: { className: string }) {
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const [expandedFinancialTransactionsIds, setExpandedFinancialTransactionIds] =
    useState<Set<string>>(new Set());

  const { results, setSelected, setOffset } =
    useContext(ResultsContext) || throwError();

  const sorting = useContext(SortingContext) || throwError();

  const getRowKey = useCallback(
    (r: Partial<Row>) => r.rowId || throwError(),
    []
  );

  const onSortColumnsChange = useCallback(
    (sortColumns: ReactDataGridSortColumn[]) => {
      const sortColumn = sortColumns[0];
      sorting.setSortColumn(sortColumn as SortColumn | undefined);
    },
    [sorting]
  );

  const sortColumns = useMemo(() => {
    return sorting.sortColumn ? [sorting.sortColumn] : [];
  }, [sorting]);

  const columns: Column<Row>[] = useMemo(() => {
    const _columns: Column<Row>[] = [
      {
        key: "expanded",
        name: "",
        frozen: true,
        width: 35,
        cellClass: () => "ps-0 pe-0",
        renderCell: ({ row }) => {
          if (row.type !== "split-booking") {
            return null;
          }

          const financial_transaction_id = row.financial_transaction_id!;

          return (
            <div
              onClick={(e) => {
                e.stopPropagation();
                setExpandedFinancialTransactionIds((prev) => {
                  const newRows = new Set(prev);

                  if (newRows.has(financial_transaction_id)) {
                    newRows.delete(financial_transaction_id);
                  } else {
                    newRows.add(financial_transaction_id);
                  }
                  return newRows;
                });
              }}
              className={"w-full h-full flex justify-center items-center"}
            >
              {expandedFinancialTransactionsIds.has(
                financial_transaction_id
              ) ? (
                <i className="pi pi-angle-down" />
              ) : (
                <i className="pi pi-angle-right" />
              )}
            </div>
          );
        },
      },
      {
        key: "transaction_date",
        name: translations.transactionDate,
        width: 200,
        resizable: true,
        sortable: true,
        renderCell: (args) => {
          if (args.row.type === "split-booking") {
            const firstChild = args.row.children[0] || throwError();
            const transaction =
              results.data?.financial_transactions[
                firstChild.booking.financial_transaction_id || throwError()
              ];

            return transaction?.date ? (
              dateFormatter.format(transaction.date)
            ) : (
              <></>
            );
          }

          return args.row.booking.date ? (
            dateFormatter.format(args.row.booking.date)
          ) : (
            <></>
          );
        },
      },
      {
        key: "amount",
        name: translations.amount,
        resizable: true,
        sortable: true,
        cellClass: "text-end",
        renderCell: (args) => {
          if (args.row.type === "split-booking") {
            const transaction =
              results.data?.financial_transactions[
                args.row.financial_transaction_id
              ] || throwError();
            const value = Math.abs(transaction.amount);

            return `${value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} €`;
          } else {
            const value = args.row.booking.amount;

            if (value == null) {
              return <></>;
            }

            return `${value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} €`;
          }
        },
      },
      {
        key: "is_debit",
        name: translations.debitCredit,
        resizable: true,
        width: 35,
        renderCell: (args) => {
          if (args.row.type === "split-booking") {
            const transaction =
              results.data?.financial_transactions[
                args.row.financial_transaction_id
              ] || throwError();

            const isDebit = isSplitBookingADebit(transaction.amount);

            if (isDebit) {
              return translations.debit;
            } else {
              return translations.credit;
            }
          } else {
            if (args.row.booking.is_debit) {
              return translations.debit;
            } else {
              return translations.credit;
            }
          }
        },
        cellClass: "text-center",
      },
      {
        key: "counter_account",
        name: translations.counterAccount,
        width: 150,
        resizable: true,
        sortable: true,
        cellClass: (row) => {
          const uniqueClass = `counter-account-cell-${row.rowId}`;
          return `${uniqueClass}`;
        },
        renderCell: (args) => {
          const uniqueClass = `counter-account-cell-${args.row.rowId}`;

          if (args.row.type === "split-booking") {
            return <span>{translations.splitBooking}</span>;
          }

          if (!args.row.booking.counter_account) {
            return <></>;
          }

          return (
            <>
              <Tooltip
                position={"top"}
                target={`.${uniqueClass}`}
                content={`${args.row.booking.counter_account.name}`}
              />
              <span>{args.row.booking.counter_account.number}</span>
            </>
          );
        },
      },

      {
        key: "applicant_name",
        name: translations.applicantName,
        resizable: true,
        renderCell: (args) => {
          if (args.row.type === "split-booking-leg") {
            return <></>;
          }

          const transactionId =
            args.row.type === "split-booking"
              ? args.row.children[0]?.booking.financial_transaction_id
              : args.row.booking.financial_transaction_id;

          if (!transactionId) {
            return <></>;
          }

          const transaction =
            results.data?.financial_transactions[transactionId];

          if (!transaction?.applicant_name) {
            return <></>;
          }

          return <span>{transaction.applicant_name}</span>;
        },
      },
      {
        key: "applicant_iban",
        name: translations.applicantIban,
        resizable: true,
        renderCell: (args) => {
          if (args.row.type === "split-booking-leg") {
            return <></>;
          }

          const transactionId =
            args.row.type === "split-booking"
              ? args.row.children[0]?.booking.financial_transaction_id
              : args.row.booking.financial_transaction_id;

          if (!transactionId) {
            return <></>;
          }

          const transaction =
            results.data?.financial_transactions[transactionId];

          if (!transaction?.applicant_iban) {
            return <></>;
          }

          return <span>{transaction.applicant_iban}</span>;
        },
      },
      {
        key: "description",
        name: translations.description,
        resizable: true,
        cellClass: (row) => {
          const uniqueClass = `description-cell-${row.rowId}`;
          return `${uniqueClass} ${styles.descriptionCell}`;
        },
        renderCell: (args) => {
          const uniqueClass = `description-cell-${args.row.rowId}`;

          if (args.row.type === "split-booking") {
            const transaction =
              results.data?.financial_transactions[
                args.row.financial_transaction_id
              ] || throwError();

            if (!transaction.purpose) {
              return <></>;
            }

            return (
              <>
                <Tooltip
                  position={"top"}
                  target={`.${uniqueClass}`}
                  content={`${transaction.purpose}`}
                />
                <span>{transaction.purpose}</span>
              </>
            );
          }

          if (!args.row.booking.booking_text) {
            return <></>;
          } else {
            return (
              <>
                <Tooltip
                  position={"top"}
                  target={`.${uniqueClass}`}
                  content={`${args.row.booking.booking_text}`}
                />
                <span>{args.row.booking.booking_text}</span>
              </>
            );
          }
        },
      },
      {
        key: "status",
        name: translations.status,
        frozen: true,
        width: 20,
        renderHeaderCell: () => {
          return <></>;
        },
        cellClass: (row) => {
          const uniqueClass = `status-cell-${row.rowId}`;
          return `${uniqueClass} ${styles.statusCell}`;
        },
        renderCell: (args) => {
          const uniqueClass = `status-cell-${args.row.rowId}`;
          if (args.row.type === "split-booking") {
            const status = getSplitBookingStatus(
              args.row.children.map((child) => child.booking)
            );

            return (
              <>
                <Tooltip
                  position={"left"}
                  target={`.${uniqueClass}`}
                  content={(() => {
                    if (status === "ACTION_NEEDED") {
                      return translations.actionNeeded;
                    } else if (status === "CORRECTED_BY_ACCOUNTANT") {
                      return translations.correctedByAccountant;
                    } else if (status === "MANUALLY_APPROVED") {
                      return translations.manuallyApproved;
                    } else if (status === "PREDICTED_WITHOUT_ISSUES") {
                      return translations.predictedWithoutIssues;
                    } else if (status === "HISTORICAL") {
                      return translations.historical;
                    } else {
                      throw new Error();
                    }
                  })()}
                />
                <div className={styles.statusCell}>
                  <div
                    className={classNames(
                      styles.statusDot,
                      status === "ACTION_NEEDED" && styles.actionNeededDot,
                      status === "CORRECTED_BY_ACCOUNTANT" &&
                        styles.correctedByAccountantDot,
                      status === "PREDICTED_WITHOUT_ISSUES" &&
                        styles.predictedWithoutIssuesDot,
                      status === "HISTORICAL" && styles.historicalStatusDot,
                      status === "MANUALLY_APPROVED" &&
                        "bg-textManuallyApproved"
                    )}
                  />
                </div>
              </>
            );
          }

          return (
            <>
              <Tooltip position={"left"} target={`.${uniqueClass}`}>
                {(() => {
                  if (args.row.booking.status === "ACTION_NEEDED") {
                    const openIssues = args.row.booking.issues;

                    return (
                      <>
                        <span>{translations.actionNeeded}</span>
                        <ul className="list-disc list-inside">
                          {openIssues.map((i) => (
                            <li key={i.id}>
                              {(() => {
                                if (i.type === "COULD_NOT_PREDICT_TAX_CODE") {
                                  return translations.couldNotPredictTaxCodeTitle;
                                } else if (i.type === "MULTIPLE_TAX_CODES") {
                                  return translations.multipleTaxCodesTitle;
                                } else if (i.type === "UNRELIABLE_PREDICTION") {
                                  return translations.unreliablePredictionTitle;
                                } else if (
                                  i.type === "NOT_ENOUGH_INFORMATION"
                                ) {
                                  return translations.transactionHasNoPurposeOrApplicantName;
                                } else if (
                                  i.type === "SPLIT_BOOKING_REQUIRED"
                                ) {
                                  return translations.splitBookingRequired;
                                } else if (
                                  i.type === "INVOICE_TO_TRANSACTION_MISMATCH"
                                ) {
                                  return translations.invoiceToTransactionMismatch;
                                } else {
                                  throw new Error();
                                }
                              })()}
                            </li>
                          ))}
                        </ul>
                      </>
                    );
                  } else if (
                    args.row.booking.status === "CORRECTED_BY_ACCOUNTANT"
                  ) {
                    return translations.correctedByAccountant;
                  } else if (args.row.booking.status === "MANUALLY_APPROVED") {
                    return translations.manuallyApproved;
                  } else if (
                    args.row.booking.status === "PREDICTED_WITHOUT_ISSUES"
                  ) {
                    return translations.predictedWithoutIssues;
                  } else if (args.row.booking.status === "HISTORICAL") {
                    return translations.historical;
                  } else {
                    throw new Error();
                  }
                })()}
              </Tooltip>
              <div
                className={classNames(
                  styles.statusDot,
                  args.row.booking.status === "ACTION_NEEDED" &&
                    styles.actionNeededDot,
                  args.row.booking.status === "CORRECTED_BY_ACCOUNTANT" &&
                    styles.correctedByAccountantDot,
                  args.row.booking.status === "PREDICTED_WITHOUT_ISSUES" &&
                    styles.predictedWithoutIssuesDot,
                  args.row.booking.status === "HISTORICAL" &&
                    styles.historicalStatusDot,
                  args.row.booking.status === "MANUALLY_APPROVED" &&
                    "bg-textManuallyApproved"
                )}
              />
            </>
          );
        },
      },
    ];

    return _columns;
  }, [
    translations,
    expandedFinancialTransactionsIds,
    results.data?.financial_transactions,
  ]);

  const data = results.data;

  if (!data) {
    if (results.loading) {
      return <ProgressSpinner className={"flex justify-center align-center"} />;
    }

    return <></>;
  }

  if (data.bookings.length == 0) {
    return (
      <div className={"flex justify-center align-center"}>
        <span className={styles.noBookings}>{translations.noResultsFound}</span>
      </div>
    );
  }

  const transformedRows: (BookingRow | SplitBookingRow | SplitBookingLegRow)[] =
    (() => {
      const split_bookings_ids = (() => {
        const bookings_by_financial_transaction_id = data.bookings.reduce(
          (acc, booking) => {
            const financial_transaction_id = booking.financial_transaction_id;

            if (!financial_transaction_id) {
              return acc;
            }

            if (!acc[financial_transaction_id]) {
              acc[financial_transaction_id] = [booking];
            } else {
              const _ = acc[financial_transaction_id] || throwError();
              _.push(booking);
            }

            return acc;
          },
          {} as { [key: string]: Booking[] }
        );

        return Object.entries(bookings_by_financial_transaction_id)
          .filter(([, bookings]) => {
            return bookings.length > 1;
          })
          .reduce((acc, [, bookings]) => {
            return [...acc, ...bookings.map((booking) => booking.id)];
          }, [] as string[]);
      })();

      const rows: (BookingRow | SplitBookingRow)[] = [];

      for (const booking of data.bookings) {
        if (split_bookings_ids.includes(booking.id)) {
          const existingRow = rows.find(
            (row): row is SplitBookingRow =>
              row.type === "split-booking" &&
              row.financial_transaction_id === booking.financial_transaction_id
          );

          if (existingRow) {
            const existingChild = existingRow.children.find(
              (child) => child.booking.id === booking.id
            );

            if (!existingChild) {
              existingRow.children.push({
                rowId: `split-booking-leg-${booking.id}`,
                type: "split-booking-leg",
                booking: booking,
              });
            }
          } else {
            const financial_transaction_id =
              booking.financial_transaction_id || throwError();

            rows.push({
              rowId: `split-booking-${booking.id}`,
              type: "split-booking",
              financial_transaction_id: financial_transaction_id,
              children: [
                {
                  rowId: `split-booking-leg-${booking.id}`,
                  type: "split-booking-leg",
                  booking: booking,
                },
              ],
              isExpanded: expandedFinancialTransactionsIds.has(
                financial_transaction_id
              ),
            });
          }
        } else {
          rows.push({
            rowId: `booking-${booking.id}`,
            type: "booking",
            booking: booking,
          });
        }
      }

      const rows_: (BookingRow | SplitBookingRow | SplitBookingLegRow)[] = [];

      for (const row of rows) {
        if (row.type === "split-booking") {
          rows_.push(row);

          if (row.isExpanded) {
            rows_.push(...row.children);
          }
        } else {
          rows_.push(row);
        }
      }

      return rows_;
    })();

  return (
    <>
      <ReactDataGrid
        className={props.className}
        rows={transformedRows}
        columns={columns}
        rowKeyGetter={getRowKey}
        rowClass={(row: Row) => {
          if (row.type === "split-booking") {
            return styles.splitBookingRow;
          }
          if (row.type === "split-booking-leg") {
            return styles.splitBookingLegRow;
          }
          return undefined;
        }}
        onSelectedCellChange={(args) => {
          if (!args.row) {
            setSelected(undefined);
            return;
          }

          const row = args.row;

          if (row.type === "split-booking") {
            setSelected({
              type: "transaction",
              id: row.financial_transaction_id,
            });
          } else if (row.type === "booking") {
            setSelected({
              type: "booking",
              id: row.booking.id,
            });
          } else if (row.type === "split-booking-leg") {
            setSelected({
              type: "split-booking-leg",
              id: row.booking.id,
            });
          } else {
            setSelected(undefined);
          }
        }}
        onWheelAndBottom={() => {
          setOffset(data.traversed_booking_ids.length);
        }}
        sortColumns={sortColumns}
        onSortColumnsChange={onSortColumnsChange}
      />

      {results.loading && (
        <div className={styles.loadingMore}>{translations.loadingMore}</div>
      )}
    </>
  );
}
