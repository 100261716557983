import { Calendar } from "primereact/calendar";
import { useContext, useEffect, useState } from "react";
import { QuickFiltersContext } from "./quick-filters-context";
import { throwError } from "../../../../../../../../throw-error";
import { useCurrentLanguage } from "../../../../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../../../../language/supported-languages";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { BankAccountsDropdown } from "./bank-accounts-dropdown";
import { ResetFiltersButton } from "./reset-filters-button";

const GERMAN_TRANSLATIONS = {
  year: "Jahr",
  from: "Von",
  to: "Bis",
  search: "Suche",
};

const ENGLISH_TRANSLATIONS = {
  year: "Year",
  from: "From",
  to: "To",
  search: "Search",
};

export function QuickFilters() {
  const { quickFilters, setQuickFilters } =
    useContext(QuickFiltersContext) || throwError();
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const [searchValue, setSearchValue] = useState<string>("");

  const handleSearch = () => {
    setQuickFilters({
      ...quickFilters,
      search: searchValue || null,
    });
  };

  useEffect(() => {
    setSearchValue(quickFilters.search || "");
  }, [quickFilters]);

  return (
    <>
      <BankAccountsDropdown className={"flex-1"} />
      <Calendar
        view="year"
        dateFormat="yy"
        value={quickFilters.time_frame_from ?? quickFilters.time_frame_to}
        onChange={(e) => {
          if (e.value) {
            const selectedYear = e.value.getFullYear();
            setQuickFilters((prev) => ({
              ...prev,
              time_frame_from: new Date(selectedYear, 0, 1),
              time_frame_to: new Date(selectedYear, 11, 31),
            }));
          }
        }}
        placeholder={translations.year}
        showIcon
        className="flex-1"
      />

      <Calendar
        value={quickFilters.time_frame_from}
        onChange={(e) => {
          setQuickFilters((prev) => {
            if (!e.value) {
              return {
                ...prev,
                time_frame_from: null,
              };
            }

            const fromDate = new Date(e.value);
            const toDate = new Date(fromDate);
            toDate.setMonth(toDate.getMonth() + 2);

            return {
              ...prev,
              time_frame_from: fromDate,
              time_frame_to: toDate,
            };
          });
        }}
        minDate={
          quickFilters.time_frame_from
            ? new Date(quickFilters.time_frame_from.getFullYear(), 0, 1)
            : undefined
        }
        maxDate={
          quickFilters.time_frame_from
            ? new Date(quickFilters.time_frame_from.getFullYear(), 11, 31)
            : undefined
        }
        dateFormat="dd MM"
        placeholder={translations.from}
        showIcon
        showButtonBar
        className="flex-1"
      />
      <Calendar
        value={quickFilters.time_frame_to}
        onChange={(e) => {
          setQuickFilters((prev) => {
            return {
              ...prev,
              time_frame_to: e.value ?? null,
            };
          });
        }}
        minDate={quickFilters.time_frame_from || undefined}
        maxDate={
          quickFilters.time_frame_from
            ? new Date(quickFilters.time_frame_from.getFullYear(), 11, 31)
            : undefined
        }
        dateFormat="dd MM"
        placeholder={translations.to}
        showIcon
        showButtonBar
        className="flex-1"
      />
      <div className="p-inputgroup flex flex-1">
        <InputText
          value={searchValue || ""}
          onChange={(e) => setSearchValue(e.target.value)}
          placeholder={translations.search}
          onKeyDown={(event: React.KeyboardEvent) => {
            if (event.key === "Enter") {
              handleSearch();
            }
          }}
        />
        <Button icon="pi pi-search" onClick={handleSearch} />
      </div>
      <ResetFiltersButton />
    </>
  );
}
