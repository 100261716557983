import { useState } from "react";
import { Accountant, AccountantsLoader } from "./accountants-loader";
import { AccountingFirm } from "../accounting-firm-loader";
import { DetailsPanel } from "./accountants-details-panel";
import styles from "./accountants.module.scss";
import { AccountantsTable } from "./accountants-table";
import { Button } from "primereact/button";
import { useCurrentLanguage } from "../../../../language/current-language";
import { SupportedLanguage } from "../../../../language/supported-languages";
import { HEADINGS_CLASS_NAMES } from "../../../../ui/headings";
import { Helmet } from "react-helmet-async";

const ENGLISH_TRANSLATIONS = {
  accountingFirmAdministrationAccountants:
    "Kanzlei-Einstellungen | Buchhaltungsteam",
  accountants: "Accountants",
  newAccountant: "New accountant",
};

const GERMAN_TRANSLATIONS = {
  accountingFirmAdministrationAccountants:
    "Accounting Firm Administration | Accountants",
  accountants: "Buchhaltungsteam",
  newAccountant: "Teammitglied hinzufügen",
};

function _Accountants(props: {
  accountingFirm: AccountingFirm;
  onAccountingFirmDataUpdated: (accountingFirm: AccountingFirm) => void;
  accountants: Accountant[];
  onAccountantCreated: (accountant: Accountant) => void;
  onAccountantUpdated: (accountant: Accountant) => void;
}) {
  const currentLanguage = useCurrentLanguage();

  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const [selectedAccountantId, setSelectedAccountantId] = useState<
    string | undefined
  >(undefined);

  const [isCreatingNewAccountant, setIsCreatingNewAccountant] = useState(false);

  const selectedAccountant = props.accountants.find(
    (accountant) => accountant.id === selectedAccountantId
  );

  return (
    <>
      <Helmet>
        <title>{`${translations.accountingFirmAdministrationAccountants} | Kanzlei21`}</title>
      </Helmet>

      <div className={styles.page}>
        <div className={`${styles.header} ${styles.marginBottom} `}>
          <h2 className={HEADINGS_CLASS_NAMES.h2}>
            {translations.accountants}
          </h2>
          <div className={styles.actions}>
            <Button
              onClick={() => {
                setSelectedAccountantId(undefined);
                setIsCreatingNewAccountant(true);
              }}
              icon="pi pi-user-plus"
              label={translations.newAccountant}
            />
          </div>
        </div>
        <div className={styles.body}>
          <AccountantsTable
            accountants={props.accountants}
            selectedAccountant={selectedAccountant}
            onAccountantSelect={(accountantId) => {
              setSelectedAccountantId(accountantId);
              setIsCreatingNewAccountant(false);
            }}
          />
        </div>
        {(() => {
          if (isCreatingNewAccountant) {
            return (
              <div className={styles.detailsPanel}>
                <DetailsPanel
                  accountingFirm={props.accountingFirm}
                  onAccountingFirmDataUpdated={
                    props.onAccountingFirmDataUpdated
                  }
                  selectedAccountant={undefined}
                  onCancel={() => {
                    setSelectedAccountantId(undefined);
                    setIsCreatingNewAccountant(false);
                  }}
                  onAccountantCreated={(accountant) => {
                    setSelectedAccountantId(accountant.id);
                    setIsCreatingNewAccountant(false);
                    props.onAccountantCreated(accountant);
                  }}
                  onAccountantUpdated={props.onAccountantUpdated}
                />
              </div>
            );
          } else if (selectedAccountant) {
            return (
              <div className={styles.detailsPanel}>
                <DetailsPanel
                  accountingFirm={props.accountingFirm}
                  onAccountingFirmDataUpdated={
                    props.onAccountingFirmDataUpdated
                  }
                  selectedAccountant={selectedAccountant}
                  onCancel={() => {
                    setSelectedAccountantId(undefined);
                    setIsCreatingNewAccountant(false);
                  }}
                  onAccountantCreated={(accountant) => {
                    setSelectedAccountantId(accountant.id);
                    setIsCreatingNewAccountant(false);
                    props.onAccountantCreated(accountant);
                  }}
                  onAccountantUpdated={props.onAccountantUpdated}
                />
              </div>
            );
          } else {
            return <></>;
          }
        })()}
      </div>
    </>
  );
}

export function Accountants({
  accountingFirm,
  onAccountingFirmDataUpdated,
}: {
  accountingFirm: AccountingFirm;
  onAccountingFirmDataUpdated: (accountingFirm: AccountingFirm) => void;
}) {
  return (
    <AccountantsLoader>
      {({ accountants, onAccountantCreated, onAccountantUpdated }) => {
        return (
          <_Accountants
            accountingFirm={accountingFirm}
            onAccountingFirmDataUpdated={onAccountingFirmDataUpdated}
            accountants={accountants}
            onAccountantCreated={onAccountantCreated}
            onAccountantUpdated={onAccountantUpdated}
          />
        );
      }}
    </AccountantsLoader>
  );
}
