import { Button } from "primereact/button";
import { Badge } from "primereact/badge";
import { useState, useEffect } from "react";
import { useMainApi } from "../../main-api";
import z from "zod";
import { CommunicationError } from "../../communication-errors/communication-errors";
import { useNavigate } from "react-router-dom";
import { NOTIFICATIONS_ROUTE } from "./notifications/notifications-routes";

const UnreadNotificationsCountSchema = z.object({
  total: z.number(),
});

type UnreadNotificationsCount = z.TypeOf<typeof UnreadNotificationsCountSchema>;

export function NotificationsButton() {
  const mainApi = useMainApi();

  const navigate = useNavigate();

  const [state, setState] = useState<
    Readonly<{
      data?: UnreadNotificationsCount;
      loading?: boolean;
      error?: CommunicationError;
    }>
  >({});

  useEffect(() => {
    (async () => {
      setState({ loading: true });

      const res = await mainApi.fetchJSON({
        method: "GET",
        path: "/accounting_clients/notifications/unread_notifications_count",
        schema: z.object({
          status: z.literal(200),
          body: UnreadNotificationsCountSchema,
        }),
      });

      setState({ loading: false });

      if (res.error) {
        setState({ error: res.error });
        return;
      }

      setState({ data: res.response.body });
    })();
  }, [mainApi]);

  return (
    <>
      <Button
        severity="secondary"
        onClick={() => navigate(NOTIFICATIONS_ROUTE.getHref())}
      >
        <i
          className="pi pi-bell p-overlay-badge"
          style={{ fontSize: "1.25rem" }}
        >
          {!state.loading && state.data && state.data.total > 0 && (
            <Badge value={state.data.total} severity="danger" />
          )}
        </i>
      </Button>
    </>
  );
}
