import { z } from "zod";
import { HEADINGS_CLASS_NAMES } from "../../../../../ui/headings";
import { useMainApi } from "../../../../../main-api";
import { Toast } from "primereact/toast";
import { useRef, useState, useEffect } from "react";
import {
  getCommunicationErrorTitle,
  getCommunicationErrorMessage,
} from "../../../../../communication-errors/communication-error-messages";
import { CommunicationError } from "../../../../../communication-errors/communication-errors";
import { ProgressSpinner } from "primereact/progressspinner";
import { useCurrentLanguage } from "../../../../../language/current-language"; // Assuming this hook exists
import { SupportedLanguage } from "../../../../../language/supported-languages"; // Assuming this enum exists

const GERMAN_TRANSLATIONS = {
  goodMorning: "Guten Morgen",
  goodDay: "Guten Tag",
  goodEvening: "Guten Abend",
  commercialRegister: "Handelsregister",
  email: "E-Mail",
  contact: "Kontakt",
  website: "Website",
};

const ENGLISH_TRANSLATIONS = {
  goodMorning: "Good Morning",
  goodDay: "Good Day",
  goodEvening: "Good Evening",
  commercialRegister: "Commercial Register",
  email: "Email",
  contact: "Contact",
  website: "Website",
};

const getTranslatedGreetingMessage = (
  currentLanguage: SupportedLanguage,
  clientName: string,
  accountingFirmName: string
) => {
  if (currentLanguage === SupportedLanguage.German) {
    return (
      <>
        Die Buchhaltung Ihres Unternehmens <b>{clientName}</b> wird von{" "}
        <b>{accountingFirmName}</b> betreut.
      </>
    );
  } else {
    return (
      <>
        <b>{accountingFirmName}</b> is managing the accounting for{" "}
        <b>{clientName}</b>.
      </>
    );
  }
};

const greeting = (currentLanguage: SupportedLanguage) => {
  const hour = new Date().getHours();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  if (hour > 3 && hour < 9) return translations.goodMorning;
  if (hour < 18) return translations.goodDay;
  return translations.goodEvening;
};

export const AccountingClientSchema = z.object({
  accounting_firm: z.object({
    name: z.string(),
    address: z.string().nullable(),
    official_id: z.string().nullable(),
    email: z.string().nullable(),
    homepage_url: z.string().nullable(),
    phone: z.string().nullable(),
  }),
  name: z.string(),
  phone: z.string().nullable(),
  user: z.object({
    name: z.string().nullable(),
    email: z.string(),
  }),
});

export type AccountingClient = z.infer<typeof AccountingClientSchema>;

export default function Greeting() {
  const mainApi = useMainApi();
  const toast = useRef<Toast | null>(null);
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const [results, setResults] = useState<{
    loading?: boolean;
    error?: CommunicationError;
    data?: AccountingClient;
  }>({});

  useEffect(() => {
    (async () => {
      const res = await mainApi.fetchJSON({
        path: `/accounting_client`,
        method: "GET",
        schema: z.object({
          status: z.literal(200),
          body: AccountingClientSchema,
        }),
      });

      if (res.error) {
        toast.current!.show({
          severity: "error",
          summary: getCommunicationErrorTitle(res.error),
          detail: getCommunicationErrorMessage(res.error),
        });

        setResults({ error: res.error });
        return;
      }

      setResults({ data: res.response.body });
    })();
  }, [mainApi]);

  if (results.loading) {
    return <ProgressSpinner />;
  }

  if (!results.data) {
    return null;
  }

  return (
    <>
      <Toast ref={toast} />
      <h1 className={`${HEADINGS_CLASS_NAMES.h1} mb-3`}>
        {`${greeting(currentLanguage)}${results.data.user.name ? `, ${results.data.user.name}` : ""}!`}
      </h1>

      <span className="text-[1.1rem]">
        {getTranslatedGreetingMessage(
          currentLanguage,
          results.data.name,
          results.data.accounting_firm.name
        )}
      </span>
      <ul className="text-[1.1rem] mt-3">
        <li>{results.data.accounting_firm.name}</li>
        <li>
          {`${translations.commercialRegister} ${results.data.accounting_firm.official_id}`}
        </li>
        <li>
          {`${translations.email}: ${results.data.accounting_firm.email}`}
        </li>
        <li>
          {`${translations.contact}: ${results.data.accounting_firm.phone}`}
        </li>
        <li>
          {`${translations.website}: `}
          <a href={results.data.accounting_firm.homepage_url ?? ""}>
            {results.data.accounting_firm.homepage_url}
          </a>
        </li>
      </ul>
    </>
  );
}
