import { useContext } from "react";
import { BankConnection, ResultsContext } from "./results-context";
import { Card } from "primereact/card";
import { Avatar } from "primereact/avatar";
import { ProgressSpinner } from "primereact/progressspinner";
import DisconnectBankConnectionButton from "./delete-connection/delete-connection";
import { HEADINGS_CLASS_NAMES } from "../../../../ui/headings";
import { throwError } from "../../../../throw-error";
import { UpdateBankConnection } from "./update-connection/update-connection";
import { Divider } from "primereact/divider";
import { useCurrentLanguage } from "../../../../language/current-language";
import { SupportedLanguage } from "../../../../language/supported-languages";

const dateFormatter = new Intl.DateTimeFormat(undefined, {
  dateStyle: "medium",
});

const GERMAN_TRANSLATIONS = {
  noBankAccount: "Kein Bankkonto verbunden",
  banksWithActionNeeded: "Handlung erforderlich",
  otherBanks: "Andere Banken",
  connectedOn: "Verbunden am",
};

const ENGLISH_TRANSLATIONS = {
  noBankAccount: "No Bank Account Connected",
  banksWithActionNeeded: "Action Needed",
  otherBanks: "Other Banks",
  connectedOn: "Connected on",
};

function BankConnectionItem({ connection }: { connection: BankConnection }) {
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  return (
    <Card>
      <div className="flex items-center mb-4">
        <Avatar
          className="mr-5"
          image={`${connection.bank_logo_url}`}
          size="xlarge"
          shape="circle"
        />
        <h3 className={HEADINGS_CLASS_NAMES.h3}>{connection.bank_name}</h3>
      </div>

      <ul className="list-disc list-inside text-sm">
        {connection.accounts.map((account, idx) => (
          <li key={idx}>
            {account.account_name} - {account.account_iban}
          </li>
        ))}
      </ul>
      <p className="mt-5 font-bold text-sm">
        {translations.connectedOn}{" "}
        {connection.created_at && dateFormatter.format(connection.created_at)}
      </p>

      <div className="flex justify-between mt-4">
        <DisconnectBankConnectionButton connection_id={connection.id} />
        {connection.user_action_required && (
          <UpdateBankConnection connection_id={connection.id} />
        )}
      </div>
    </Card>
  );
}

export function Results() {
  const { results, bank_connections, bank_connections_with_update } =
    useContext(ResultsContext) || throwError();
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  if (results.loading) {
    return <ProgressSpinner />;
  }
  if (results.data && results.data.length === 0) {
    return (
      <h3 className={`${HEADINGS_CLASS_NAMES.h3} text-center my-10`}>
        {translations.noBankAccount}
      </h3>
    );
  }

  return (
    <div className="py-5 mx-4">
      {(() => {
        if (bank_connections_with_update?.length !== 0) {
          return (
            <>
              <h3 className={`${HEADINGS_CLASS_NAMES.h3}`}>
                {translations.banksWithActionNeeded}
              </h3>
              <Divider />
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {bank_connections_with_update?.map((connection) => (
                  <BankConnectionItem
                    key={connection.id}
                    connection={connection}
                  />
                ))}
              </div>
            </>
          );
        }
      })()}

      <h3 className={`${HEADINGS_CLASS_NAMES.h3} mt-20`}>
        {translations.otherBanks}
      </h3>
      <Divider />
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {bank_connections?.map((connection) => (
          <BankConnectionItem key={connection.id} connection={connection} />
        ))}
      </div>
    </div>
  );
}
