import { useState, useEffect, useRef } from "react";
import { z } from "zod";
import { useMainApi } from "../../../main-api";
import { CommunicationError } from "../../../communication-errors/communication-errors";
import { Fieldset } from "primereact/fieldset";
import { DataView } from "primereact/dataview";
import styles from "./notifications-page.module.scss";
import { ProgressSpinner } from "primereact/progressspinner";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom";
import {
  getCommunicationErrorMessage,
  getCommunicationErrorTitle,
} from "../../../communication-errors/communication-error-messages";
import { useCurrentLanguage } from "../../../language/current-language";
import { SupportedLanguage } from "../../../language/supported-languages";
import { CREATE_ACCOUNTING_CLIENT_ROUTE } from "../accounting-clients/accounting-client-id/create/create-accounting-client-routes";
import { HEADINGS_CLASS_NAMES } from "../../../ui/headings";
import { Helmet } from "react-helmet-async";

const GERMAN_TRANSLATIONS = {
  notifications: "Benachrichtigungen",
  noNotifications: "Keine Benachrichtigungen gefunden",
  acceptedInvite: "hat Ihre Einladung zu Kanzlei21 angenommen",
  connectedNewBank: "hat ein neues Bankkonto verbunden",
  setUpBankAccount: "Bankkonto einrichten",
  continueClientInitialization: "Mandanteninitialisierung fortfahren",
};

const ENGLISH_TRANSLATIONS = {
  notifications: "Notifications",
  noNotifications: "No notifications found",
  acceptedInvite: "accepted your invitation to Kanzlei21",
  connectedNewBank: "connected a new bank account",
  setUpBankAccount: "Set up Bank Account",
  continueClientInitialization: "Continue client initialization",
};

const dateFormatter = new Intl.DateTimeFormat(undefined, {
  dateStyle: "medium",
  timeStyle: "short",
});

const NotificationSchema = z.object({
  id: z.string().uuid(),
  accounting_client_id: z.string().uuid(),
  accounting_client_name: z.string(),
  type: z.enum(["ACCEPTED_INVITE", "NEW_BANK_ACCOUNT_CONNECTED"]),
  created_at: z.coerce.date(),
  is_read: z.boolean(),
});

export type Notification = z.TypeOf<typeof NotificationSchema>;

export function Notifications() {
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const mainApi = useMainApi();

  const navigate = useNavigate();

  const [results, setResults] = useState<
    Readonly<{
      data?: {
        rows: Notification[];
      };
      loading?: boolean;
      error?: CommunicationError;
    }>
  >({});

  const [isSubmitting, setIsSubmitting] = useState(false);

  const toastRef = useRef<Toast>(null);

  useEffect(() => {
    (async () => {
      setResults((results) => {
        return {
          ...results,
          loading: true,
          error: undefined,
        };
      });

      const res = await mainApi.fetchJSON({
        method: "GET",
        path: `/accounting_clients/notifications`,
        schema: z.object({
          status: z.literal(200),
          body: z.array(NotificationSchema),
        }),
      });

      if (res.error) {
        setResults((results) => {
          return {
            ...results,
            loading: false,
            error: res.error,
          };
        });

        return;
      }

      setResults((results) => {
        return {
          ...results,
          loading: false,
          error: res.error,
          data: {
            rows: res.response.body,
          },
        };
      });
    })();
  }, [mainApi]);

  const markAsRead = async (notificationId: string) => {
    setIsSubmitting(true);

    const res = await mainApi.fetchJSON({
      method: "PUT",
      path: `/accounting_clients/notifications/${notificationId}/mark_as_read`,
      schema: z.object({
        status: z.literal(200),
      }),
    });

    setIsSubmitting(false);

    if (res.error) {
      toastRef.current?.show({
        severity: "error",
        summary: getCommunicationErrorTitle(res.error),
        detail: getCommunicationErrorMessage(res.error),
      });
      return;
    }
  };

  const data = results.data;

  if (!data) {
    if (results.loading) {
      return <ProgressSpinner />;
    }
    return;
  }

  return (
    <>
      <Helmet>
        <title>{`${translations.notifications} | Kanzlei21`}</title>
      </Helmet>

      <Toast ref={toastRef} />
      <div className={styles.page}>
        <div className={`${styles.marginBottom2} ${styles.maxWidth}`}>
          <h2 className={HEADINGS_CLASS_NAMES.h2}>
            {translations.notifications}
          </h2>
        </div>
        <Fieldset className={`${styles.fieldset} ${styles.maxWidth}`}>
          <DataView
            value={data?.rows}
            itemTemplate={(row: Notification) => (
              <div
                key={row.id}
                className={`${styles.rowItem} ${!row.is_read ? styles.unreadRowItemClass : ""}`}
              >
                <div className={styles.rowItemContent}>
                  {(() => {
                    if (row.type === "ACCEPTED_INVITE") {
                      return (
                        <div className={styles.rowItemMessage}>
                          <span>
                            <b>{row.accounting_client_name}</b>{" "}
                            {translations.acceptedInvite}
                          </span>

                          <Button
                            label={translations.continueClientInitialization}
                            link
                            onClick={async () => {
                              await markAsRead(row.id);
                              navigate(
                                CREATE_ACCOUNTING_CLIENT_ROUTE.getHref(
                                  row.accounting_client_id
                                )
                              );
                            }}
                            disabled={isSubmitting}
                          />
                        </div>
                      );
                    }

                    if (row.type === "NEW_BANK_ACCOUNT_CONNECTED") {
                      return (
                        <div className={styles.rowItemMessage}>
                          <span>
                            <b>{row.accounting_client_name}</b>{" "}
                            {translations.connectedNewBank}
                          </span>

                          <Button
                            label={translations.setUpBankAccount}
                            link
                            onClick={async () => {
                              await markAsRead(row.id);
                              navigate(
                                CREATE_ACCOUNTING_CLIENT_ROUTE.getHref(
                                  row.accounting_client_id
                                )
                              );
                            }}
                            disabled={isSubmitting}
                          />
                        </div>
                      );
                    }

                    throw new Error();
                  })()}
                  <div className={styles.justifyContentFlexEnd}>
                    {row.created_at ? (
                      <small>{dateFormatter.format(row.created_at)}</small>
                    ) : null}
                  </div>
                </div>
                <div className={styles.rowItemContent}></div>
              </div>
            )}
            className={styles.dataView}
            emptyMessage={translations.noNotifications}
          />
        </Fieldset>
      </div>
    </>
  );
}
