import { Menu as PrimeReactMenu } from "primereact/menu";
import styles from "./menu.module.scss";
import { BANK_CONNECTION_ROUTE } from "../bank-connections/bank-connection-route";
import { useNavigate } from "react-router-dom";
import { useCurrentLanguage } from "../../../language/current-language";
import { SupportedLanguage } from "../../../language/supported-languages";


const GERMAN_TRANSLATIONS = {
  bankConnections: "Bankverbindungen",
  documents: "Dokumente",
};

const ENGLISH_TRANSLATIONS = {
  bankConnections: "Bank Connections",
  documents: "Documents",
};

export function AccountingClientMenu() {
  const navigate = useNavigate();
  const currentLanguage = useCurrentLanguage();
  const translations = currentLanguage === SupportedLanguage.German ? GERMAN_TRANSLATIONS : ENGLISH_TRANSLATIONS;

  return (
    <PrimeReactMenu
      className={styles.primeReactMenu}
      model={[
        {
          label: translations.bankConnections,
          className: `${window.location.pathname.startsWith("/client/bank-connections") ? "p-menuitem-active" : ""}`,
          command: () => {
            navigate(BANK_CONNECTION_ROUTE.getHref());
          },
        },
        // {
        //   label: translations.documents,
        //   disabled: true,
        //   className: `${window.location.pathname.startsWith("/client/documents") ? "p-menuitem-active" : ""}`,
        //   command: () => {
        //     navigate(DOCUMENTS_ROUTE.getHref());
        //   },
        // },
      ]}
    />
  );
}