import { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useMainApi } from "../../../main-api";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { Card } from "primereact/card";
import { Message } from "primereact/message";
import styles from "./reset-password.module.scss";
import { CommunicationError } from "../../../communication-errors/communication-errors";
import { getCommunicationErrorMessage } from "../../../communication-errors/communication-error-messages";
import { useNavigate } from "react-router-dom";
import { ACCOUNTANT_LOGIN_ROUTE } from "../login/routes";
import { useCurrentLanguage } from "../../../language/current-language";
import { SupportedLanguage } from "../../../language/supported-languages";
import { Helmet } from "react-helmet-async";

const GERMAN_TRANSLATIONS = {
  accountantResetPassword: "Buchhalter | Passwort zurücksetzen",
  resetYourPassword: "Passwort zurücksetzen",
  enterNewPassword: "Geben Sie ein neues Passwort ein",
  enterNewPasswordAgain: "Geben Sie das neue Passwort erneut ein",
  passwordResetSuccess: "Ihr Passwort wurde erfolgreich zurückgesetzt.",
  minLengthRequirement: "Muss mindestens 8 Zeichen enthalten",
  maxLengthRequirement: "Darf höchstens 32 Zeichen enthalten",
  passwordsDontMatch: "Passwörter stimmen nicht überein",
  back: "Zurück",
  updateYourPassword: "Passwort aktualisieren",
  goToLoginPage: "Zur Anmeldeseite",
};

const ENGLISH_TRANSLATIONS = {
  accountantResetPassword: "Accountant | Reset Password",
  resetYourPassword: "Reset your password",
  enterNewPassword: "Enter your new password",
  enterNewPasswordAgain: "Enter your new password again",
  passwordResetSuccess: "Your password has been successfully reset.",
  minLengthRequirement: "Must contain at least 8 character(s)",
  maxLengthRequirement: "Must contain at most 32 character(s)",
  passwordsDontMatch: "Passwords don't match",
  back: "Back",
  updateYourPassword: "Update your password",
  goToLoginPage: "Go to Login Page",
};

export function AccountantUserResetPassword() {
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const navigate = useNavigate();

  useEffect(() => {
    const token = new URLSearchParams(window.location.search).get("token");
    if (!token) {
      navigate("/accountant/");
    }
  }, [navigate]);

  const mainApi = useMainApi();

  const [state, setState] = useState<{
    loading?: boolean;
    error?: undefined | CommunicationError;
    success?: boolean;
  }>({});

  const form = useForm<{
    newPassword: string;
    newPasswordConfirmation: string;
  }>({
    resolver: zodResolver(
      z
        .object({
          newPassword: z
            .string()
            .min(8, translations.minLengthRequirement)
            .max(32, translations.maxLengthRequirement),
          newPasswordConfirmation: z
            .string()
            .min(8, translations.minLengthRequirement)
            .max(32, translations.maxLengthRequirement),
        })
        .refine((data) => data.newPassword === data.newPasswordConfirmation, {
          message: translations.passwordsDontMatch,
          path: ["newPasswordConfirmation"],
        })
    ),
    defaultValues: {
      newPassword: "",
      newPasswordConfirmation: "",
    },
  });

  const onSubmit = async (data: {
    newPassword: string;
    newPasswordConfirmation: string;
  }) => {
    setState({ loading: true });

    const token = new URLSearchParams(window.location.search).get("token");

    if (!token) {
      setState({ error: CommunicationError.UnexpectedResponse });
      return;
    }

    const res = await mainApi.fetchJSON({
      path: `/accountant_users/reset-password`,
      method: "POST",
      schema: z.object({
        status: z.literal(200),
      }),
      body: {
        new_password: data.newPassword,
        token,
      },
    });

    if (res.error) {
      setState({ error: res.error });
      return;
    }

    setState({ success: true });
  };

  return (
    <>
      <Helmet>
        <title>{`${translations.accountantResetPassword} | Kanzlei21`}</title>
      </Helmet>

      <main className={styles.page}>
        <Card
          className={`${styles.maxWidth480} ${styles.width100Percent}`}
          title={translations.resetYourPassword}
        >
          {state.error && (
            <div className={styles.marginBottomSpacer}>
              <Message
                severity="error"
                text={getCommunicationErrorMessage(state.error)}
                className={styles.width100Percent}
              />
            </div>
          )}
          {state.success ? (
            <>
              <div className={styles.marginBottomSpacer}>
                <Message
                  severity="success"
                  text={translations.passwordResetSuccess}
                  className={styles.width100Percent}
                />
              </div>
              <Button
                label={translations.goToLoginPage}
                onClick={() => navigate(ACCOUNTANT_LOGIN_ROUTE.getHref())}
                className={styles.width100Percent}
                outlined
              />
            </>
          ) : (
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <div className={styles.formField}>
                <label htmlFor="newPassword" className={styles.label}>
                  {translations.enterNewPassword}
                </label>
                <Controller
                  name="newPassword"
                  control={form.control}
                  render={({ field, fieldState }) => (
                    <Password
                      id={field.name}
                      value={field.value}
                      onChange={field.onChange}
                      toggleMask
                      className={styles.formFieldInput}
                      inputClassName={classNames(styles.width100Percent, {
                        "p-invalid": fieldState.invalid,
                      })}
                    />
                  )}
                />
                {form.formState.errors.newPassword && (
                  <small className={styles.errorMessage}>
                    {form.formState.errors.newPassword.message}
                  </small>
                )}
              </div>
              <div className={styles.formField}>
                <label
                  htmlFor="newPasswordConfirmation"
                  className={styles.label}
                >
                  {translations.enterNewPasswordAgain}
                </label>
                <Controller
                  name="newPasswordConfirmation"
                  control={form.control}
                  render={({ field, fieldState }) => (
                    <Password
                      id={field.name}
                      value={field.value}
                      onChange={field.onChange}
                      toggleMask
                      feedback={false}
                      className={styles.formFieldInput}
                      inputClassName={classNames(styles.width100Percent, {
                        "p-invalid": fieldState.invalid,
                      })}
                    />
                  )}
                />
                {form.formState.errors.newPasswordConfirmation && (
                  <small className={styles.errorMessage}>
                    {form.formState.errors.newPasswordConfirmation.message}
                  </small>
                )}
              </div>
              <div className={styles.buttonsWrapper}>
                <Button
                  label={translations.updateYourPassword}
                  onClick={form.handleSubmit(onSubmit)}
                  disabled={state.loading}
                  loading={state.loading}
                />
              </div>
            </form>
          )}
        </Card>
      </main>
    </>
  );
}
