import { ReactNode } from "react";
import styles from "./page.module.scss"
import { ResultsContextProvider } from "./results/results-context";
import { Results } from "./results/results";
import { TopSection } from "./top-section/top-section";
import { QuickFiltersContextProvider } from "./top-section/nav-bar/quick-filters/quick-filters-context";

function Page(props: { children: ReactNode }) {
    return <div className={styles.page}>{props.children}</div>;
}

export function BankConnectionPage() {
    return (
        <>
            <QuickFiltersContextProvider>
                <ResultsContextProvider>
                    <Page>
                        <TopSection />
                        <Results />
                    </Page>
                </ResultsContextProvider>
            </QuickFiltersContextProvider>

        </>
    )
}
